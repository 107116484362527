<template>
  <div class="bg2">
    <div class="form page-padd pt-100 content-container">
      <div class="header mb-30 mt-10">
        <div class="go-back">
          <router-link :to="{ name: 'Dashboard' }">
            <svg viewBox="0 0 24 24">
              <path fill="currentColor" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
            </svg>
          </router-link>
        </div>
        <div class="title self-center d-flex h-center">
          <h3>HRA Form</h3>
        </div>
      </div>
      <FormConfig v-if="form_config_JSON.state" :config="form_config_JSON" :state_updated="state"
        @input="(state_val, input_name) => hraSocketUpdate(state_val, input_name)" @submit="(v$) => onSubmit(v$)" />
      <div v-if="isSocketReady && !meetStore.isMeetInited" :class="{ 'active': isHelpModalVisible }" id="need_help">
        <div @click="isHelpModalVisible = !isHelpModalVisible" v-touch:swipe.left="HelpModalShow"
          v-touch:swipe.right="HelpModalHide" class="hide-control"></div>
        <template v-if="isSearchingForNurse">
          <div style="text-align: center">
            <h2>{{ searchingText }}</h2>
            <Transition>
              <base-loader class="loader-relative"></base-loader>
            </Transition>
          </div>
        </template>
        <template v-else>
          <div v-if="hraSocketInfo?.free > 0">
            <div class="mb-10">Difficult? <strong class="color-primary">{{ hraSocketInfo?.free }} nurses online</strong>
              <br>
              You can call them for help
            </div>
            <div @click="needHelp()" class="btn btn-primary">I need help</div>
          </div>
          <div v-else>
            <div class="color-lightgray">Sorry, there are currently no nurses available to assist you</div>
          </div>
        </template>
      </div>
      <div v-if="isSocketReady && meetStore.isMeetInited" :class="{ 'active': isCallModalVisible }" id="call_modal">
        <div @click="isCallModalVisible = !isCallModalVisible" v-touch:swipe.right="CallModalHide" class="hide-control">
        </div>
        <div id="audio_call">
          <VJitsi :room="meetStore.roomName" :nickname="meetStore.nickname" :avatar="meetStore.avatar"
            :init="meetStore.isMeetInited"
            @connected="alertMessage.show('Connected, say hello to the nurse', 'success', 'small')"
            style="width: 320px; height: 200px" />
          <base-button class="danger mt-15" @click="callClose()">End Call</base-button>
        </div>
      </div>
    </div>
  </div>
  <Transition>

    <base-loader v-if="loader_active" class="loader-on-block bg-white"></base-loader>
  </Transition>
</template>

<script>
import { defineComponent, inject, onMounted, ref, provide, watchEffect, onBeforeMount, onUnmounted } from "vue";
import FormConfig from '@/plugins/form_config/components/FormConfig'
import { API_hra_confirm, API_hra_init } from '@/API/API-hra'
import VJitsi from '@/modules/jitsi_meet/components/VJitsi'
// import {useHraStore} from "@/modules/hra/store/hra";
import { useMeetStore } from "@/modules/hra/store/meet";
import { useSockets } from "@/modules/hra/utils/use-sockets";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { FormConfig, VJitsi },

  setup() {
    const router = useRouter()
    const loader_active = ref(true)
    const isSearchingForNurse = ref(false)
    const searchingText = ref('We are looking for a nurse for you')
    const alertMessage = inject('alertMessage');
    const meetStore = useMeetStore();
    const patientProfileStore = usePatientProfilesStore()
    const state = ref({
      // 'first-name': '',
      // 'last-name': '',
      //  ...
      //  ...
    })

    const form_config_JSON = ref({})

    onBeforeMount(() => {
      API_hra_init(patientProfileStore.getCurrentPatientProfileId).then((res) => {
        form_config_JSON.value = res.data.response
        state.value = res.data.response.state
        loader_active.value = false
      })

    })

    // SOCKETS
    const Socket = ref(null);
    const isSocketReady = ref(false);


    onMounted(() => {
      const callbacks = {
        onclose: () => {
          isSocketReady.value = false
        },
        onmessage: (e) => {
          let data = JSON.parse(e.data).data;
          let action = JSON.parse(e.data).action;

          switch (action) {
            case "inited":
              isSocketReady.value = true
              break;
            case "call_close":
              callClose()
              break;
            case 'info':
              isSearchingForNurse.value = false
              if (!!data.status && data.status === 'error') {
                alertMessage.value.show(`${data.message}`, 'danger', 'small')
                if (data.status === 'error') Socket.value.isClosed = true
              } else {
                hraSocketInfo.value = data
              }
              break;
            case 'call_info':
              isSearchingForNurse.value = false;
              meetStore.setRoomSettings(data.chat.roomName)
              break;
            case 'call_update':
              state.value = data;
              break;
          }
        }
      }

      Socket.value = useSockets(callbacks);

      Socket.value.connect()
    });

    onUnmounted(() => {
      callClose()
      Socket.value.disconnect()
    })

    const hraSocketInfo = ref(null)

    function needHelp() {
      isSearchingForNurse.value = true;
      Socket.value.callRequest()
      alertMessage.value.show('Looking for a nurse for you', 'success', 'small')
    }

    function callClose() {
      localStorage.setItem('callStatus', "disActive")

      isSearchingForNurse.value = false;
      meetStore.$reset()

      Socket.value.callClose();
    }

    function hraSocketUpdate(state_val) {
      state.value = state_val
      Socket.value.callUpdate(state_val)
    }

    // VIDEO CHAT
    const isHelpModalVisible = ref(false)

    function HelpModalHide() {
      isHelpModalVisible.value = false
    }

    function HelpModalShow() {
      isHelpModalVisible.value = true
    }

    // VIDEO CHAT
    const isCallModalVisible = ref(false)

    function CallModalHide() {
      isCallModalVisible.value = false
    }

    function CallModalShow() {
      isCallModalVisible.value = true
    }

    watchEffect(() => {
      if (meetStore.getIsMeetInited) {
        isCallModalVisible.value = true
      } else {
        isCallModalVisible.value = false
      }
    })

    onMounted(() => {

      setTimeout(() => {
        if (hraSocketInfo.value && hraSocketInfo.value.free > 0)
          isHelpModalVisible.value = true
      }, 60000)

    })

    watchEffect(() => {
      if (hraSocketInfo.value && hraSocketInfo.value.free > 0) {
        isHelpModalVisible.value = true
      } else {
        isHelpModalVisible.value = false
      }
    })

    provide('Socket', Socket)
    provide('isSocketReady', isSocketReady)

    function onSubmit(v$) {
      loader_active.value = true

      if (!v$.value.$error) {
        API_hra_confirm(patientProfileStore.getCurrentPatientProfileId, state.value).then((res) => {
          patientProfileStore.updatePatientProfilesData(res.data.patients)

          if (res.data.status === 'success') {
            Socket.value.callClose();
            Socket.value.disconnect();
            router.push({ name: 'HRAWelcome', params: { test: 1 } })
          } else {
            alertMessage.value.show('Validation failed. Some of fields are invalid', 'danger', 'small')
          }

          loader_active.value = false
        })
      } else {
        loader_active.value = false;

        alertMessage.value.show('Validation failed. Some of fields are invalid', 'danger', 'small')
      }


    }

    return {
      // hraStore,
      isSearchingForNurse,
      searchingText,
      isSocketReady,
      meetStore,
      alertMessage,
      state,
      form_config_JSON,
      hraSocketInfo,
      callClose,
      hraSocketUpdate,
      needHelp,
      isHelpModalVisible,
      isCallModalVisible,
      CallModalHide,
      CallModalShow,
      HelpModalHide,
      HelpModalShow,
      loader_active,
      onSubmit
    }
  }
})

</script>

<style scoped>
.pt-100 {
  padding-top: 100px;
}

.goback- {
  top: 10px !important;
  left: 10px !important;
}

#need_help {
  position: fixed;
  top: 50px;
  right: 0;
  width: 30%;
  background: #fff;
  padding: 15px;
  padding-left: 40px;
  border-radius: 15px 0 0 15px;
  -webkit-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
  transform: translate(calc(100% - 40px), 0);
  transition: transform .3s ease-in;
}

#need_help .hide-control,
#call_modal .hide-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  bottom: 0;
}

#need_help .hide-control:before,
#call_modal .hide-control:before {
  content: '';
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  background: var(--color-primary);
  height: 35px;
  width: 3px;
  border-radius: 5px;
}

#need_help.active .hide-control,
#call_modal.active .hide-control {
  width: 30px;
}

#need_help.active .hide-control:before,
#call_modal.active .hide-control:before {
  left: 15px;
}

#need_help.active,
#call_modal.active {
  padding-left: 30px;
  transform: translate(0, 0);
}

#call_modal {
  position: fixed;
  z-index: 19;
  top: 50px;
  right: 0;
  min-width: 300px;
  max-width: 88%;
  width: fit-content;
  width: -moz-fit-content;
  background: #fff;
  padding: 15px;
  padding-left: 40px;
  border-radius: 15px 0 0 15px;
  -webkit-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
  transform: translate(calc(100% - 40px), 0);
  transition: transform .3s ease-in;
}

.div.btn {
  margin: 0 auto
}
</style>