<template>
  <signup-form/>
</template>

<script>
import { defineComponent} from "vue";
import SignupForm from "@/modules/user/components/SignupForm";

export default defineComponent({
  components: {
    SignupForm
  },
})

</script>


