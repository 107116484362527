import { defineStore } from "pinia";

export const useStationsStore = defineStore("stations", {
    state: () => ({
        stations: [],
        station_code: '',
        station_name: '',
        station_id: '',
    }),
    persist: {
        storage: sessionStorage
    },
    getters: {
        // get computed properties from state
        getStationId: (state) => state.station_id,
        getStationCode: (state) => state.station_code,
        getStationName: (state) => state.station_name
    },
    // methods from change state
    actions: {
        setStationsData(data) {
            this.stations = data;
        },
        setStationCode(code) {
            this.station_code = code
        },
        setStationName(name) {
            this.station_name = name
        },
        setStationId(id) {
            this.station_id = id
        },

    },
});
