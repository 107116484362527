<template>
  <div class="input-group">
    <input :value="inputValue" :placeholder="placeholder" :name="name" :class="{'danger': isError}" type="text" @input="$emit('input', $event.target.value)">
  </div>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({
  props: {
    inputValue: String,
    name: String,
    placeholder: String,
    isError: Boolean
  },
  emits: ['input']
})
</script>

<style scoped>
input {
  padding: 20px 18px;
  background: #F1F1F1;
  border-radius: 12px;
  border: 1px solid transparent;
  margin-bottom: 20px;
  width: 100%;
}

input::placeholder {
  color: #A1A1A1;
}

input:last-child {
  margin-bottom: 0;
}

input.danger {
  border-color: #ff3f3f;
}

</style>