import axios from "axios";
import qs from "qs";
import {API_user_logout} from "./API-user";
import {useUserStore} from "@/modules/user/store/user";

const url = process.env.VUE_APP_API_URL

/**
 *  set headers to axios interceptor
 */
axios.interceptors.request.use(config => {
    const userStore = useUserStore()
    config.headers['Authorization'] = "Bearer " + userStore.token
    config.headers["content-type"] = "application/x-www-form-urlencoded;charset=utf-8"
    return config
})


/**
 * Get current patient applications list
 * @param patient_id
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_get_applications_list(patient_id) {
    return axios
        .post(url + "application", qs.stringify({
            patient_id
        }))
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

/**
 * Get current application details
 * @param patient_id
 * @param application_id
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_get_application_details(patient_id, application_id = '') {
    return axios
        .post(url + "application", qs.stringify({
            patient_id, application_id
        }))
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

/**
 *  Get all supplies to station if exist
 *  Calculate payment status for patient
 * @param patient_id
 * @param station_code
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_payment_get_supplies(patient_id, station_code) {
    return axios
        .post(url + "application/prepare", qs.stringify({
            patient_id, station_code
        }))
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

/**
 *  Confirm application payment
 * @param patient_id
 * @param station_code
 * @param laboratory_id
 * @param reason
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_payment_confirm(patient_id, station_code, laboratory_id, reason) {
    return axios
        .post(url + "application/confirm", qs.stringify({
            patient_id, station_code, laboratory_id, reason
        }))
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

export function API_application_pay(patient_id, application_id) {
    return axios
        .post(url + "application/pay", qs.stringify({
            patient_id, application_id
        }))
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}



