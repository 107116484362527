import { defineStore } from "pinia"
import { API_get_profiles_list } from "@/API/API-profiles";

const getCurrentPatientProfile = (state) => state.patientProfiles.find((i) => i.id === state.currentPatientProfileId)
const getPatientProfileById = (state, id) => {
  if (state.patientProfiles.find((i) => i.id === id)) {
    return state.patientProfiles.find((i) => i.id === id)
  } else {
    state.setCurrentPatientProfileId(state.patientProfiles[0].id);
    return getPatientProfileById()
  }
}
const getSelectedPatientProfile = (state) => {
  if (state.patientProfiles.find((i) => i.id === state.selectedPatientId)) {
    return state.patientProfiles.find((i) => i.id === state.selectedPatientId)
  } else {
    state.setSelectedPatientProfileId(state.patientProfiles[0].id);
    return getSelectedPatientProfile()
  }
}

export const usePatientProfilesStore = defineStore("patientProfile", {
  state: () => ({
    isInshured: false,
    patientProfiles: [
      // EXAMPLE OF PATIENT PROFILE
      // {
      //     name: "Zena",
      //     mid: 'the',
      //     lastName: 'Warrior',
      //     email: "qqq@qqq.qqq",
      //     dob_day: "21",
      //     dob_month: "03",
      //     dob_year: "1990",
      //     id: "10",
      //     phone: "+380939148007",
      //     photo: "",
      //     sex: "Female",
      //     status_id: "",
      //     type_id: "",
      //     type_name: "",
      //     token: "asdasdas",
      //     uri: "",
      // },
      // ...
      // ...
    ],
    currentPatientProfileId: null,
    selectedPatientId: null,
    stations_city_id: null,
    stations_city_name: null,

  }),
  persist: true,
  getters: {
    getIsInshured: (state) => {
      return state.isInshured;
    },
    // get computed properties from state
    getCurrentPatientProfile: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      return currentPatientProfile
    },
    getCurrentPatientIsValid: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      if (currentPatientProfile)
        return currentPatientProfile.is_valid
      else
        return false
    },
    getCurrentPatientProfileDOB: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      return (
        currentPatientProfile.dob_day + "/" + currentPatientProfile.dob_month + "/" + currentPatientProfile.dob_year
      )
    },
    getCurrentPatientProfileInsuranceCompanyName: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      return (
        currentPatientProfile.insurance_company_name
      )
    },
    getIsInsuranceRequested: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      return (
        currentPatientProfile.is_insurance_requested
      )
    },
    getSelectedPatientProfileDOB: (state) => {
      const selectedPatientProfile = getSelectedPatientProfile(state)
      return (
        selectedPatientProfile.dob_day + "/" + selectedPatientProfile.dob_month + "/" + selectedPatientProfile.dob_year
      )
    },
    getCurrentPatientProfileId: (state) => state.currentPatientProfileId,
    getSelectedPatientId: (state) => state.selectedPatientId,
    getCurrentPatientProfileFullName: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      currentPatientProfile.mid_name = currentPatientProfile.mid_name ? currentPatientProfile.mid_name : ""
      return (
        currentPatientProfile.name
      )
    },
    getSelectedPatientProfileFullName: (state) => {
      const selectedPatientProfile = getSelectedPatientProfile(state)
      console.log(state.selectedPatientId);
      console.log(state.patientProfiles.find((i) => i.id === state.selectedPatientId));
      return (
        selectedPatientProfile.name
      )
    },
    getCurrentPatientProfileName: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      return currentPatientProfile.first_name
    },
    getCurrentPatientProfilePhoto: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      return currentPatientProfile.photo
    },
    getCurrentPatientProfileSex: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      return currentPatientProfile.sex
    },
    getSelectedPatientProfileSex: (state) => {
      const selectedPatientProfile = getSelectedPatientProfile(state)
      return selectedPatientProfile.sex
    },
    getCurrentPatientProfileEmail: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      return currentPatientProfile.email
    },
    getCurrentPatientProfilePhone: (state) => {
      const currentPatientProfile = getCurrentPatientProfile(state)
      return currentPatientProfile.phone
    },
    getPatientProfilesList: (state) => state.patientProfiles,
    getCurrentPatientAddress: (state) => {
      const data = getCurrentPatientProfile(state)
      return (
        data["address_street"] +
        "," +
        data["address_apt"] +
        "," +
        data["address_city"] +
        "," +
        data["address_state"] +
        "," +
        data["address_country"] +
        "," +
        data["address_zip"]
      )
    },
    getSelectedPatientAddress: (state) => {
      const data = getSelectedPatientProfile(state)
      return (
        data["address_street"] +
        "," +
        data["address_apt"] +
        "," +
        data["address_city"] +
        "," +
        data["address_state"] +
        "," +
        data["address_country"] +
        "," +
        data["address_zip"]
      )
    },
    getHasCurrentPatientHRA: (state) => {
      const data = getCurrentPatientProfile(state)
      return data["is_hra_valid"]
    },
    getHasCurrentPatientInsured: (state) => {
      const data = getCurrentPatientProfile(state)
      return data["is_insured"]
    },
    getHasSelectedPatientInsured: (state) => {
      const data = getSelectedPatientProfile(state)
      return data["is_insured"]
    },
    getHasCurrentPatientDocs: (state) => {
      const data = getCurrentPatientProfile(state)
      return data["is_usa_documents"]
    },
    getHasInsured: (state) => (id) => {
      const data = getPatientProfileById(state, id)
      return data["is_insured"]
    },
    getHasDocs: (state) => (id) => {
      const data = getPatientProfileById(state, id)
      return data["is_usa_documents"]
    },
    getOtherPatientsProfiles: (state) => {
      return state.patientProfiles.filter((i) => i.id !== state.currentPatientProfileId)
    },
    getStationsCityId: (state) => {
      return state.stations_city_id
    },
    getStationsCityName: (state) => {
      return state.stations_city_name
    },
  },
  actions: {
    // methods for change state
    setIsInshured() {
      this.isInshured = false;
    },
    setInshured() {
      this.patientProfiles.map((item) => {
        if (item.id === this.currentPatientProfileId) {
          item.is_insurance_requested = true;
        }
      })
    },

    setStationsCityId(data) {
      this.stations_city_id = data
    },
    setStationsCityName(data) {
      this.stations_city_name = data
    },
    updatePatientProfilesData(data) {
      if (!data) {
        return API_get_profiles_list().then((res) => {
          console.log('profile list', res);
          this.isInshured = !res.data.patients.find((patient) => 
            patient.id === JSON.parse(localStorage.getItem("patientProfile")).currentPatientProfileId).is_insurance_requested &&
            !res.data.patients.find((patient) => patient.id === JSON.parse(localStorage.getItem("patientProfile")).currentPatientProfileId).is_insured
        //   console.log('is_insurance_requested ', res.data.patients.find((patient) => patient.id === JSON.parse(localStorage.getItem("patientProfile")).currentPatientProfileId));
        //   console.log('is_insured ', res.data.patients.find((patient) => patient.id === JSON.parse(localStorage.getItem("patientProfile")).currentPatientProfileId));
          // console.log('storage', JSON.parse(localStorage.getItem("patientProfile")).currentPatientProfileId);
          this.patientProfiles = res.data.patients
        })
      } else {
        this.patientProfiles = data
      }
    },
    setCurrentPatientProfileId(id) {
      this.currentPatientProfileId = id
    },
    setSelectedPatientProfileId(id) {
      this.selectedPatientId = id
    },
  },

})
