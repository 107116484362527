<template>
  <div class="welcome-hra-container">
    <h1 class="welcome-hra-title">HRA</h1>
    <div class="mb-20">
      Healeze is here to help you take control of your health.
      We are giving you the power to manage your healthcare.
      It all starts by completing a Health Risk Assessment.
      This data will allow our AI models to provide you personalized feedback and get you on track to a way of life free of chronic
      medical concerns.
    </div>
<!--    <p>you will fill form for patient <span-->
<!--        class="patient-name bold">{{ patientProfileStore.getCurrentPatientProfileFullName }}</span></p>-->
    <div class="btns-container">
      <router-link :to="{ path: '/fill-hra'}">
        <base-button class="padding btn primary">
          <p>Fill form</p>
        </base-button>
      </router-link>
      <!-- <router-link :to="{ name: 'Dashboard' }">
        <base-button class="padding btn secondary">
          <p>Back</p>
        </base-button>
      </router-link> -->
    </div>
  </div>
</template>
<script>
import {defineComponent} from "vue";
import {useRouter} from "vue-router";
// import {usePatientProfilesStore} from "@/modules/patient-profiles/store/patient-profiles";

export default defineComponent({
  setup() {
    const thxText = 'You are now set to access our suite of healthcare services. We look forward to serving you.'
    // const patientProfileStore = usePatientProfilesStore()
    const router = useRouter()

    /**
     * Go back to fill HRA form
     */
    const onBackToHRAFormBtnClick = () => {
      router.push({path: '/fill-hra'})
    }

    /**
     * Go to dashboard view
     */
    const onBackToDashboardBtnClick = () => {
      router.push({name: 'Dashboard'})
    }
    return {onBackToDashboardBtnClick, onBackToHRAFormBtnClick, /*patientProfileStore,*/ thxText}
  }
})
</script>
<style scoped>

.padding{
  padding: 10px 45px;
}

.btns-container {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.btns-container a {
  width: 100%;
}
.welcome-hra-container {
  text-align: center;
  margin: 0 auto;
  padding: 30px;
}

.welcome-hra-title {
  font-size: 42px;
}

.mr-10 {
  margin-right: 10px;
}

.patient-name {
  display: block;
}

.welcome-hra-container button {
  width: 50%;
  margin: 0 auto;
}

.mb-20 {
  display: block;
  max-width: 70%;
  margin: 0 auto;
}
</style>