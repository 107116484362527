import { helpers, minLength, required, email } from "@vuelidate/validators";

export default function parseValidationRules(JSON_validators) {
  let newRules = {};

  for (const [key, rule] of Object.entries(JSON_validators)) {

    for (let i = 0; i < Object.keys(rule).length; i++) {

      if (rule.required) {
        const target = (() => {
          if (rule.required !== '')
            return {
              required: helpers.withMessage(rule.required, required),
            };
          else return { required: required };
        })();

        newRules[key] = Object.assign(target, newRules[key]);
      }
      if (rule.minLength) {
        const target = (() => {
          return { minLength: minLength(rule.minLength) };
        })();

        newRules[key] = Object.assign(target, newRules[key]);
      }
      if (rule['email']) {
        const target = (() => {
          return { email: email() };
        })();
        newRules[key] = Object.assign(target, newRules[key]);
      }
    }
  }

  return newRules;
}
