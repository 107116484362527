<template >
  <div class=" bg2">
    <div
      v-if="patientProfileStore.patientProfiles"
      class="view-container  content-container"
    >
      <account-preview />
      <ul
        ref="scrollContainer"
        class="list"
      >
        <li class="list-item mb-10">
          <img
            :src="dobSvg"
            alt="icon"
          >
          <p class="list-item-text">{{ patientProfileStore.getCurrentPatientProfileDOB }}</p>
        </li>
        <li class="list-item mb-10">
          <img
            :src="insuranceSvg"
            alt="icon"
          >
          <p class="list-item-text">
            {{ patientProfileStore.getHasCurrentPatientInsured ? 'insured by' : 'not insured' }}</p>
          <router-link
            :to="{ name: 'Insurance' }"
            v-if="patientProfileStore.getHasCurrentPatientInsured"
            class="list-item-company-text"
          >{{
            patientProfileStore.getCurrentPatientProfile.insurance_company_id ?
            patientProfileStore.getCurrentPatientProfile.insurance_company_name :
            patientProfileStore.getCurrentPatientProfile.insurance_company_manually
          }}
          </router-link>
        </li>
        <li class="list-item mb-10">
          <img
            :src="addressSvg"
            alt="icon"
          >
          <p class="list-item-text">{{ patientProfileStore.getCurrentPatientAddress }}</p>
        </li>
        <li class="list-item">
          <img
            :src="sexSvg"
            alt="icon"
          >
          <p class="list-item-text">{{ patientProfileStore.getCurrentPatientProfileSex }}</p>
        </li>
        <div class="get-insurance-container">
          <div v-if="!patientProfileStore.getIsInsuranceRequested">
            <p>{{
              !patientProfileStore.getHasCurrentPatientInsured
              ? 'Your patient profile is without Insurance. We can help you issue an Insurance Policy'
              : 'We can help you issue an Insurance Policy'
            }}</p>
            <button
              class="get-insurance-btn"
              @click="isInsuranceModalOpen = true"
            >Get Insurance</button>
          </div>
          <div
            v-else
            style="opacity: 0.6"
          >
            You have already requested insurance consultation. Wait for a call
          </div>
        </div>
      </ul>
      <PatientProfilesList />
      <ul class="list">
        <li
          v-if="userStore.getUserPhone"
          class="list-row mb-20"
        >
          <div class="list-item">
            <img
              :src="phoneSvg"
              alt="icon"
            >
            <p class="list-item-text">{{ userStore.getUserPhone }}</p>
          </div>
          <!--        <button @click="phone_confirm_modal = true">-->
          <!--        <img :src="editSvg" alt="icon">-->
          <!--        </button>-->
        </li>
        <li
          v-if="userStore.getUserEmail"
          class="list-row"
        >
          <div class="list-item">
            <img
              :src="mailSvg"
              alt="icon"
            >
            <p class="list-item-text">{{ userStore.getUserEmail ? userStore.getUserEmail : 'no data' }}</p>
          </div>
          <!--        <button @click="email_confirm_modal = true">-->
          <!--        <img :src="editSvg" alt="icon">-->
          <!--        </button>-->
        </li>
      </ul>
      <div class="btns-container mt-10">
        <button
          class="button"
          @click="checkAlertDelete()"
        >Delete Account</button>
        <div class="stick"></div>
        <button
          class="button"
          @click="askLogout()"
        ><img :src="logoutSvg"><span class="logout-text">Log Out</span>
        </button>
      </div>
      <div class="our-contacts-list">
        <p>Version 1.2.04</p>
        <a
          class="our-contacts-text"
          href="mailto:support@labwork.com"
        >support@labworq.com</a>
        <p
          class="our-contacts-text"
          @click="showModal('Privacy policy')"
        >Privacy Policy</p>
        <a
          class="our-contacts-text"
          @click="showModal('Terms of use')"
        >Terms of Use</a>
        <p>© 2021-2023
          <a
            href="https://labworq.com"
            target="_blank"
            class="our-contacts-text"
          >Labworq LCC company.
        </a>
          All Rights Reserved
        </p>
      </div>
    </div>
    <DeleteAccountModal
      v-if="delete_account_modal"
      @close="delete_account_modal = false"
    />
    <ModalConfirmContacts
      v-if="phone_confirm_modal"
      :redirect="false"
      :active="phone_confirm_modal"
      :mode="'phone'"
      @close="phone_confirm_modal = false"
    />
    <div
      class="insurance-modal-overlay"
      v-if="isInsuranceModalOpen"
    >
      <div class="insurance-modal">
        <img
          class="close-modal-btn"
          @click="isInsuranceModalOpen = false"
          :src="closeModalSvg"
          alt="close"
        >
        <p class="insurance-modal-title">Free Insurance Consultation</p>
        <p class="insurance-modal-text">It looks like you didn’t give us any insurance information!</p>
        <p class="insurance-modal-text">Our experts can show you the best plans on the market and help you pick the
          right
          one for you.</p>
        <div class="checkbox-container">
          <input
            required
            v-model="isInterested"
            type="checkbox"
            id="accept-insurance"
            name="accept-insurance"
            checked
          >
          <label
            for="accept-insurance"
            class="accept-insurance-label"
          >
            <img
              :src="isInterested ? checkboxCheckedSvg : checkboxUncheckedSvg"
              alt=""
              class="checkbox-img"
            >
            <span class="insurance-modal-checkbox-text">I’m interested in being contacted by an insurance
              expert.</span></label>
        </div>
        <p class="insurance-modal-disclaimer">*Your name and contact information will be shared with a broker.</p>
        <button
          @click="requestConsultation"
          class="contact-broker-btn"
        >Contact Broker</button>
      </div>
    </div>
    <Transition>
      <base-loader
        v-if="loader_active"
        class="loader-on-block bg-dark"
      ></base-loader>
    </Transition>
    <TermsModal
      :btn="false"
      @close="showModal"
      v-if="termsModalStatus"
      :title="modalTitle"
    />
  </div>
</template>

<script>
import { defineComponent, inject, ref, computed, onBeforeMount, onMounted } from "vue";
import DeleteAccountModal from "../components/DeleteAccountModal";
import ModalConfirmContacts from "../components/ModalConfirmContacts";
import { API_user_logout } from "@/API/API-user";
import { API_insurance_consultation_request } from "@/API/API-insurance";
import AccountPreview from "@/modules/patient-profiles/components/AccountPreview";
import PatientProfilesList from "@/modules/patient-profiles/components/PatientProfilesList";
import { useUserStore } from "@/modules/user/store/user";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import { version } from '../../package';
import editSvg from '@/assets/img/icons/my-acc-icons/edit.svg';
import addressSvg from '@/assets/img/icons/my-acc-icons/address.svg';
import dobSvg from '@/assets/img/icons/my-acc-icons/dob.svg';
import insuranceSvg from '@/assets/img/icons/my-acc-icons/insurance.svg';
import logoutSvg from '@/assets/img/icons/my-acc-icons/logout.svg';
import sexSvg from '@/assets/img/icons/my-acc-icons/sex.svg';
import mailSvg from '@/assets/img/icons/my-acc-icons/mail.svg';
import phoneSvg from '@/assets/img/icons/my-acc-icons/phone.svg';
import checkboxCheckedSvg from '@/assets/img/icons/checkbox-checked.svg';
import checkboxUncheckedSvg from '@/assets/img/icons/checkbox-unchecked.svg';
import closeModalSvg from '@/assets/img/icons/close-modal.svg';
import TermsModal from '@/components/TermsModal.vue';


export default defineComponent({
  components: { DeleteAccountModal, ModalConfirmContacts, AccountPreview, PatientProfilesList, TermsModal },
  setup() {
    const isOtherPatientDataOpen = ref(false);
    const APP_VERSION = computed(() => version);
    const isDeletePatientProfileModalOpen = ref(false);
    const isDeletePatientProfileConfirm = ref(false);
    let loader_active = ref(true);
    const alertMessage = inject("alertMessage");
    const userStore = useUserStore();
    const patientProfileStore = usePatientProfilesStore();
    const delete_account_modal = ref(false);
    const phone_confirm_modal = ref(false);
    const email_confirm_modal = ref(false);
    const isInsuranceModalOpen = ref(false);
    const isInterested = ref(false);
    const scrollContainer = ref(null);
    const modalTitle = ref('');
    const termsModalStatus = ref(false);

    function showModal(params) {
      console.log('params:', params)
      modalTitle.value = params;

      termsModalStatus.value = !termsModalStatus.value;
    }


    /**
     * Set current patient
     * @param id
     */

    const accountInfo = computed(() => ({
      accountPhone: {
        value: userStore.getUserPhone,
        imagePath: 'img/icons/phone.svg',
        key: 'phone',
      },
      // accountEmail: {
      //   value: userStore.getUserEmail,
      //   imagePath: "img/icons/mail.svg"
      //   key: 'email'
      // },

    })
    );

    const patientInfo = computed(() => ({
      patientName: {
        value: patientProfileStore.getCurrentPatientProfileFullName,
        imagePath: "img/icons/user-icon.svg",
        key: 'dob'
      },
      patientDOB: {
        value: patientProfileStore.getCurrentPatientProfileDOB,
        imagePath: "img/icons/calendar-icon.svg",
        key: 'dob'
      },
      patientSex: {
        value: patientProfileStore.getCurrentPatientProfileSex,
        imagePath: "img/icons/user-icon.svg",
        key: 'sex'
      },
      // patientInsurance: {
      //   value: patientProfileStore.getCurrentPatientProfileSex,
      //   imagePath: "img/icons/user-icon.svg",
      //   key: 'sex'
      // },
    }));

    onBeforeMount(() => {
      patientProfileStore.updatePatientProfilesData().then(() => {
        loader_active.value = false;
      });
    });

    onMounted(() => {
      document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
      console.log('STORE');
    })

    /**
     * Confirm user logout
     */
    function askLogout() {
      if (confirm("You want to log out?")) {
        API_user_logout();
      }
    }

    function requestConsultation() {
      loader_active.value = true;
      isInsuranceModalOpen.value = false;
      patientProfileStore.setInshured();
      API_insurance_consultation_request(patientProfileStore.getCurrentPatientProfileId).then((res) => {
        if (res === undefined) {
          alertMessage.value.show("Ooops Try again later", "danger");
          loader_active.value = false;

          return;
        }

        if (res.data.status === 'success') {
          alertMessage.value.show(res.data.text, "success");
        } else {
          alertMessage.value.show(res.data.text, "danger");
        }
        loader_active.value = false;
      });
    }

    function checkAlertDelete() {
      delete_account_modal.value = true;
    }

    return {
      showModal,
      modalTitle,
      APP_VERSION,
      accountInfo,
      patientInfo,
      userStore,
      patientProfileStore,
      delete_account_modal,
      askLogout,
      alertMessage,
      checkAlertDelete,
      phone_confirm_modal,
      email_confirm_modal,
      isDeletePatientProfileConfirm,
      isDeletePatientProfileModalOpen,
      loader_active,
      requestConsultation,
      isOtherPatientDataOpen,
      dobSvg,
      addressSvg,
      editSvg,
      insuranceSvg,
      logoutSvg,
      sexSvg,
      phoneSvg,
      mailSvg,
      isInsuranceModalOpen,
      isInterested,
      checkboxUncheckedSvg,
      checkboxCheckedSvg,
      closeModalSvg,
      scrollContainer,
      termsModalStatus
    };
  },
});
</script>


<style scoped>
.accept-insurance-label {
  position: relative;
}

.checkbox-img {
  position: absolute;
  top: 0;
  left: -7%;
}

#accept-insurance {
  display: none;
}

.contact-broker-btn {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: var(--color-primary);
  color: #fff;
}

.insurance-modal-checkbox-text {
  margin-left: 10px;
}

.checkbox-container {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #999;
}

.insurance-modal-title {
  display: inline-block;
  margin-bottom: 11px;
  font-weight: 600;
  font-size: 20px;
}

.insurance-modal-text {
  font-weight: 400;
  font-size: 14px;
}

.insurance-modal-disclaimer {
  color: #999;
  font-weight: 400;
  font-size: 14px;
}

.insurance-modal-overlay {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  padding: 150px 15px;
  height: 100vh;
  width: 100%;
  z-index: 99999;
}

.close-modal-btn {
  position: absolute;
  top: 4%;
  right: 4%;
}

.insurance-modal {
  position: relative;
  padding: 35px 25px;
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.get-insurance-container {
  border-top: 1px solid #999;
  text-align: center;
  padding-top: 25px;
  margin-top: 25px;
  font-size: 14px;
}

.get-insurance-btn {
  margin-top: 20px;
  border-radius: 8px;
  padding: 12px 30px;
  background-color: var(--color-primary);
  color: #fff;

}

.list-item-company-text {
  color: var(--color-primary);
  margin-left: 10px;
}

.our-contacts-text {
  color: #00A5E9;
  text-decoration: underline;
  cursor: pointer;
}

.our-contacts-list {
  margin-top: 30px;
  text-align: center;
}


.logout-text {
  color: #FF5151;
  margin-left: 10px;
}

.stick {
  height: 35px;
  width: 2px;
  background: #999;
}

.list-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 16px 16px;
  background: #FFFFFF;
}

.button {
  width: calc(50% - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-container {
  position: relative;
  padding: 50px 12px 100px;
}

.list {
  background: #fff;
  padding: 20px;
  border-radius: 16px 16px 8px 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.list-item {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.list-item-text {
  margin-left: 20px;
}</style>