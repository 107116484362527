import mapStyles from "@/services/mapStyles"

export default {
  centerIsSet: false,
  styles: mapStyles,
  backgroundColor: "#1C1C1C",
  center: {
    lat: 40.72,
    lng: -73.91,
  },
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  minZoom: 10,
  restriction: {
    latLngBounds: { north: 80, south: -65, west: -180, east: 180 },
    strictBounds: true,
  },
}
