<template>
  <div class="mb-20">
    <div
      class="wrapper user-info p-relative"
      @click="isSelectCurrentPatientModeOn = !isSelectCurrentPatientModeOn"
    >
      <div class="select-button">
        <svg viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M7,10L12,15L17,10H7Z"
          />
        </svg>
      </div>
      <div class="img-wrapper">
        <img
          :src="patientImg"
          alt=""
        >
      </div>
      <div class="text">
        <div>

          <p>Patient,</p>
          <h3 class="patient-name">
            <span class="patient-profiles-item-name">
              {{ patientProfilesStore.getCurrentPatientProfileFullName }}
            </span>
            <small>{{ patientProfilesStore.getCurrentPatientProfileDOB }}</small>
          </h3>
        </div>

      </div>
      <button
        @click="onEditPatientProfileBtnClick()"
        class="edit-user-btn"
      >
        <img :src="editSvg">
      </button>
    </div>
    <ul
      class="patient-profiles-list"
      v-if="isSelectCurrentPatientModeOn"
    >
      <template
        v-for="(patient, index) in patientProfilesList"
        :key="index"
      >
        <li
          v-if="patient.id != 'no data'"
          class="patient-profiles-list-item"
        >
          <button
            type="button"
            class="patient-name"
            @click="onSelectCurrentPatientBtnClick(patient.id)"
          >

            <img
              class="patient-list-img"
              :src="patientImg"
              alt="img"
            >
            <span class="patient-profiles-item-name">
              {{ patient.first_name + ' ' + patient.last_name }}
            </span>
            <small>{{ patient.dob_day + '/' + patient.dob_month + '/' + patient.dob_year }}</small>
            <small
              class="icon-wrapper"
              v-if="patientProfilesStore.getHasInsured(patient.id)"
            >
              <img
                :src="greenInsurance"
                alt="icon"
              >
              <span style="color: #85B744">insured</span>
            </small>
            <small
              class="icon-wrapper"
              v-else-if="patientProfilesStore.getHasDocs(patient.id)"
            >
              <img
                :src="blueInsurance"
                alt="icon"
              >
              <span style="color: #00A5E9">docs</span>
            </small>
            <small
              class="icon-wrapper"
              v-else
            >
              <img
                :src="redInsurance"
                alt="icon"
              >
              <span style="color: #EE7C7C">not insured</span>
            </small>
          </button>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import greenInsurance from '@/assets/img/icons/patient-profile-list-svg/insurance-green.svg'
import blueInsurance from '@/assets/img/icons/patient-profile-list-svg/insurance-blue.svg'
import redInsurance from '@/assets/img/icons/patient-profile-list-svg/insurance-red.svg'
import greenBlankSvg from '@/assets/img/icons/patient-profile-list-svg/green-blank.svg'
import redBlankSvg from '@/assets/img/icons/patient-profile-list-svg/red-blank.svg'
// import editSvg from '@/assets/img/icons/my-acc-icons/edit.svg'
import editSvg from '../../../assets/img/icons/my-acc-icons/edit.svg';
import { useRouter } from "vue-router";
export default defineComponent({
  props: {
    profilePage: Boolean,
  },

  setup() {
    const router = useRouter()
    const patientProfilesStore = usePatientProfilesStore()
    const patientProfilesList = patientProfilesStore.getPatientProfilesList
    let isSelectCurrentPatientModeOn = ref(false)
    let patientImg = ref('')
    patientImg.value ='img/avatar-1.png?v=0.01';

    /**
     * Set current patient profile
     * @param id
     */
    const onSelectCurrentPatientBtnClick = (id) => {
      patientProfilesStore.setCurrentPatientProfileId(id)
      isSelectCurrentPatientModeOn.value = false
    }
    const onEditPatientProfileBtnClick = () => {
      router.push({ name: 'Signup', params: { id: patientProfilesStore.getCurrentPatientProfileId, edit: 'edit' } })
    }

    return {
      patientProfilesStore,
      patientImg,
      patientProfilesList,
      onSelectCurrentPatientBtnClick,
      isSelectCurrentPatientModeOn,
      greenInsurance,
      blueInsurance,
      redInsurance,
      greenBlankSvg,
      redBlankSvg,
      editSvg,
      onEditPatientProfileBtnClick
    }
  }
})
</script>

<style scoped>
.edit-user-btn {
  margin-left: auto;
  margin-right: 8px;
}

.wrapper {
  position: relative;
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;

}

.icon-wrapper img {
  margin-left: 15px;
  width: 20px;
  height: 20px;
}

.patient-profiles-list {
  z-index: 9999;
  background-color: var(--color-white);
  position: absolute;
  top: 75px;
  left: 0;
  border-radius: 12px;
  padding: 10px;
  max-height: 60vh;
  overflow: auto;
  -webkit-box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.3);
}

.patient-profiles-list-item {
  padding: 15px 5px;
  position: relative;
}

.patient-profiles-list-item:not(:last-child):after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 1px;
  width: calc(100% - 10px);
  background: #555555;
  opacity: 0.5;
}

.select-button {
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 36px;
  top: 36px;
  color: var(--color-lightgray);
  padding: 0;
  line-height: 0;
}

.select-button svg {
  width: 20px;
  height: 20px;
}

.ta-c {
  text-align: center;
}

.mb-20 {
  position: relative;
}

.patient-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  color: var(--color-dark);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.patient-name small {
  opacity: 0.6;
  font-size: 10px;
}

.patient-list-img {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  padding: 5px;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
}

.patient-profiles-item-name {
  margin-right: 5px;
  font-size: 16px;
  line-height: 1.3em;
  max-width: 186px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 380px) {
  .patient-profiles-item-name {
    max-width: 150px;
    font-size: 15px;
  }
}

@media (max-width: 350px) {
  .patient-profiles-item-name {
    max-width: 130px;
    font-size: 12px;
  }
}

.patient-profiles-list .patient-profiles-item-name {
  font-size: 12px;
  margin-right: 5px;
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.user-info p {
  margin-bottom: 3px;
  font-size: 10px;
}

.blank-img {
  width: 24px;
  height: 24px;
}</style>
