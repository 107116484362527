<template>
    <div class="bg2">
        <div class="page-padd pt-25 content-container">
            <div class="p-relative">
                <div class="form">
                    <div class="header mb-30">
                        <router-link
                            :to="{ name: 'Insurance' }"
                            class="go-back"
                        >
                            <svg viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                                />
                            </svg>
                        </router-link>
                        <div class="self-center d-flex h-center">
                            <h4 @click="showState">Update Insurance</h4>
                        </div>
                    </div>
                    <div class="mb-10">
                        <div
                            @click="changeMenual"
                            class="btn btn-xs btn-secondary text-sm"
                            style="width: 180px; margin-left: auto;"
                        >
                            {{ enterManually ? "Show list of companies" : "Enter manually" }}
                        </div>
                    </div>
                    <div class="row-container mb-30">
                        <div class="input-group w100">
                            <div
                                id="insuranceCompanySelect"
                                class="insuranceCompanySelect"
                            >
                                <template v-if="enterManually === false">
                                    <Select2
                                        class="select2"
                                        :class="v$['insurance_company_id'].$error ? 'danger' : ''"
                                        v-model="state['insurance_company_id']"
                                        :options="companiesData"
                                        :settings="{
                                            minimumResultsForSearch: 20,
                                            width: '100%',
                                            placeholder: 'Select company',
                                        }"
                                    />
                                </template>

                                <template v-if="enterManually === true">
                                    <input
                                        type="text"
                                        v-model="state['insurance_company_manually']"
                                        :class="v$['insurance_company_id'].$error ? 'danger' : ''"
                                        class="input"
                                        placeholder="Insurance Company"
                                    />
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="row-container mb-30">
                        <div class="input-group w100">
                            <input
                                v-model="state['insurance_member_number']"
                                :class="v$['insurance_member_number'].$error ? 'danger' : ''"
                                required
                                class="input"
                                type="text"
                                placeholder="Member Number"
                            />
                        </div>
                    </div>
                    <h5>Please provide Insurance card photo</h5>
                    <div class="row-container gx-2 mb-30">
                        <div
                            class="input-group photo w50 m-r-10"
                            :class="[
                                state['insurance_front'].length ? 'success' : '',
                                v$['insurance_front'].$error ? 'danger' : '',
                            ]"
                        >
                            <input
                                type="file"
                                accept="image/*"
                                @change="uploadPhoto($event, 'insurance_front')"
                                id="insurance_front"
                                style="display: none"
                            />
                            <label
                                class="mb-8 centred"
                                for="insurance_front"
                            >
                                <span class="add-photo-text">
                                    Front photo
                                </span>
                                <img
                                    :src="state['insurance_front']"
                                    alt=""
                                />
                            </label>
                        </div>

                        <div
                            class="input-group photo w50"
                            :class="[
                                state['insurance_back'].length ? 'success' : '',
                                v$['insurance_back'].$error ? 'danger' : '',
                            ]"
                        >
                            <input
                                type="file"
                                accept="image/*"
                                @change="uploadPhoto($event, 'insurance_back')"
                                id="insurance_back"
                                style="display: none"
                            />
                            <label
                                class="mb-8"
                                for="insurance_back"
                            >
                                <span class="add-photo-text">
                                    Back photo
                                </span>
                                <img
                                    :src="state['insurance_back']"
                                    alt=""
                                />
                            </label>
                        </div>
                    </div>

                    <base-button
                        @click="submitForm()"
                        class="btn btn-primary mb-30 w100"
                        :class="v$.$error ? 'disabled' : ''"
                    >Save
                    </base-button>

                </div>
            </div>
        </div>

        <Transition>
            <base-loader
                v-if="loader_active"
                class="fixed loader-on-block bg-white"
            ></base-loader>
        </Transition>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    ref,
    onMounted,
    inject,
    watchEffect
} from "vue";
import "vue3-tel-input/dist/vue3-tel-input.css";
import "@/assets/reset.css";
import useValidate from "@vuelidate/core";
import {
    required,
    minLength,
} from "@vuelidate/validators";
import Select2 from "vue3-select2-component";
import {
    API_get_patient_insurance,
    API_set_patient_insurance
} from "@/API/API-profiles";
import { API_get_insurance_company_info } from "@/API/API-insurance-company";
import { useRouter } from "vue-router";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";

export default defineComponent({
    components: { Select2 },

    props: {
        profile_id: String,
    },

    setup(props) {
        const loader_active = ref(true);
        const alertMessage = inject("alertMessage");
        const patientProfilesStore = usePatientProfilesStore()
        const router = useRouter();
        const enterManually = ref(false);
        const companiesData = ref([
            {
                id: String,
                text: String,
            },
        ]);
        const state = ref({
            insurance_company_id: "",
            insurance_company_manually: "",
            insurance_company_name: "",
            insurance_member_number: "",
            insurance_front: "",
            insurance_back: "",
        });
        const rules = computed(() => {
            return {
                insurance_company_id: { required, minLength: minLength(3) },
                insurance_company_manually: { minLength: minLength(2) },
                insurance_member_number: { required, minLength: minLength(3) },
                insurance_front: { required, minLength: minLength(3) },
                insurance_back: { required, minLength: minLength(3) },
            };
        });

        // watchEffect(() => {
        //     if (state.value.insurance_company_manually !== '') {
        //         state.value.insurance_company_name = state.value.insurance_company_manually
        //     } else if (state.value.insurance_company_id !== '') {
        //         console.log('wratch', companiesData.value.find((company) => company.id === state.value.insurance_company_id));
        //         state.value.insurance_company_name = (companiesData.value.find((company) => company.id === state.value.insurance_company_id))?.text;
        //     }
        // })

        const v$ = useValidate(rules, state);


        watchEffect(() => {
            if (state.value.insurance_company_id !== '') {
                state.value.insurance_company_name = companiesData.value.find(item => item.id == state.value.insurance_company_id)?.text
            }
        })


        function sendState() {

            API_set_patient_insurance(props.profile_id, state.value).then((res) => {
                if (res.data.status === "success") {
                    router.push({ name: "Insurance" });
                    loader_active.value = false;

                } else {
                    alertMessage.value.show(res.data.title, "danger");
                    loader_active.value = false;

                }
            });
        }

        function showState() {
            console.log(state.value);
        }

        function changeMenual() {

            enterManually.value = !enterManually.value;
            if (enterManually.value) {
                state.value.insurance_company_id = '';
            }
        }

        function submitForm() {
            // checks all inputs
            v$.value.$validate().then(() => {
                if ((!v$.value['insurance_company_id'].$error || !v$.value['insurance_company_manually'].$error) && !v$.value['insurance_member_number'].$error && !v$.value['insurance_front'].$error && !v$.value['insurance_back'].$error) {
                    loader_active.value = true;
                    sendState();
                } else {

                    document.getElementById('app').scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    console.log(v$.value.$errors[0]);
                    alertMessage.value.show(`Some fields are not valid`, "danger");
                }
            });
        }

        function getCompanyInfo() {
            API_get_insurance_company_info()
                .then((res) => {
                    companiesData.value = res.data.map((item) => {

                        return {
                            id: item.id,
                            text: item.name,
                        };
                    });
                })
                .catch((err) => {
                    console.log("err", err);
                });
        }


        onMounted(() => {
            console.log('onMounted');
            getCompanyInfo();
            API_get_patient_insurance(patientProfilesStore.getCurrentPatientProfileId)
                .then((res) => {
                    loader_active.value = false;
                    console.log('response', res.data.response);

                    state.value = res.data.response;

                    if (res.data.response.insurance_company_id == "") {
                        enterManually.value = !enterManually.value;
                    }
                })
        });

        function uploadPhoto(input, state_key) {
            const file = input.target.files[0];
            const reader = new FileReader();

            let rawImg;
            reader.onloadend = () => {
                rawImg = reader.result;

                state.value[state_key] = rawImg;
            };
            reader.readAsDataURL(file);
        }

        return {
            state,
            v$,
            companiesData,
            submitForm,
            uploadPhoto,
            enterManually,
            loader_active,
            patientProfilesStore,
            changeMenual,
            showState,
        };
    },
});
</script>


<style scoped>
.code-input {
    margin-top: 10px;
}

.with-icon {
    display: flex;
    margin-top: 15px;
}

.svg-icon {
    margin-right: 10px;
}

.red {
    color: #FF5151;
    display: block;
    margin-top: 30px;
    line-height: 28px;
}

.mr-10 {
    margin-right: 10px;
}

.btns-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mt-20 {
    margin-top: 20px;
}

.confirm-delete-patient-profile {
    margin: 80px 20px;
    padding: 20px;
    background-color: #fff;
    height: 65vh;
    border-radius: 12px;
}

.delete-patient-title {
    font-size: 20px;
    font-weight: 500;
}

.delete-patient-text {
    font-size: 14px;
}

.add-photo-text {
    display: inline-block;
}

.centred {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-container {
    display: flex;
    align-items: center;
}

.m-r-10 {
    margin-right: 10px !important;
}


.fixed {
    position: fixed !important;
}

.createText {
    font-size: 24px;
    font-weight: 500;
    margin-top: -10px;
}

.page-padd {
    padding-bottom: 100px;
    min-height: 100%;
}

.btn-primary {
    width: 50% ;
    margin: 0 auto;
}

.form input {
    background: #fff;
    border: none;
    padding: 0 10px;
    font-size: 13px;
}


.form input:focus,
.form select:focus {
    outline: none !important;
    border-color: #87c8ee;
    box-shadow: 0 0 10px #87c8ee;
}

.form .input-group:not(.checkbox):not(.radio):not(.photo) label {
    position: absolute;
    top: 16px;
    left: 10px;
    font-size: 14px;
    color: var(--color-lightgray);
    font-weight: 400;
    transition: all 0.3s;
}

.form.container {
    padding-bottom: 15px;
}

.form input,
.form select {
    background: #fff;
    border: none;
    padding: 0 10px;
    font-size: 13px;
}

.form input:focus,
.form select:focus {
    outline: none !important;
    border-color: #87c8ee;
    box-shadow: 0 0 10px #87c8ee;
}

.form .input-group:not(.checkbox):not(.radio):not(.photo) label {
    position: absolute;
    top: 16px;
    left: 10px;
    font-size: 14px;
    color: var(--color-lightgray);
    font-weight: 400;
    transition: all 0.3s;
}

.form input[type="text"].danger,
.form input[type="number"].danger,
.form input[type="tel"].danger,
.form input[type="email"].danger,
.form input[type="date"].danger,
.form input[type="datetime-local"].danger,
input.danger,
.tabs-container input.danger {
    border: 1px solid;
    border-color: #d41e1e;
    animation: danger-border 2.5s forwards;
}

.alert.alert-info {
    background: #555555;
    color: #fff;
}

.alert {
    border-radius: var(--radius);
    padding: 15px;
    margin-bottom: 15px;
    color: #fff;
}

.alert * {
    color: #fff;
    vertical-align: middle;
}

.alert p {
    font-size: 12px;
}

.alert h4 {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1em;
}

.alert h4 img {
    height: 18px;
    width: auto;
    margin-right: 10px;
    vertical-align: middle;
}

.patientText {
    font-size: 20px;
    font-weight: 500;
}

.submitForm {
    width: 180px;
    font-size: 14px;
}

.btn {
    cursor: pointer;
}

.btn.btn-warning {
    background-color: #f59c1a;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 5px 15px;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 180px;
}

.btn.btn-warning:hover {
    background-color: #e29018;
}

.header {
    padding-top: 20px;
}

.content-container {
    min-height: 100vh;
}
</style>