<template>
  <section class="other-patients-container">
    <div class="add-new-patient-section">
      <p class="other-patients-title">Other patient profiles</p>
      <button @click="onCreateNewPatientBtnClick">
        <img :src="newPatientSvg">
      </button>

    </div>
    <div
      v-for="(profile, index) in patientProfileStore.getOtherPatientsProfiles"
      :key="profile.id"
      class="other-patients-list-container"
    >
      <div
        class="other-patients-list"
        :class="[index === 0 ? 'first-item-border' : '', index === patientProfileStore.patientProfiles.length - 1 ? 'last-item-border nb' : '']"
      >
        <div
          class="other-patients-item"
          @click="patientProfileStore.getSelectedPatientId === profile.id ? patientProfileStore.setSelectedPatientProfileId(null) : patientProfileStore.setSelectedPatientProfileId(profile.id)"
        >
          <p class="other-patients-name">{{ profile.name }} <span class="other-patients-dob">
              {{ profile.dob_day + '/' + profile.dob_month + '/' + profile.dob_year }}
            </span>
          </p>
        </div>
      </div>
      <Transition>
        <div
          class="list other-patients-data-list"
          v-if="patientProfileStore.getSelectedPatientId && patientProfileStore.getSelectedPatientId === profile.id"
        >
          <p class="list-item mb-10"><img
              :src="insuranceSvg"
              alt="icon"
            >
            <span class="list-item-text">
              {{ patientProfileStore.getHasInsured(profile.id) || patientProfileStore.getHasDocs(profile.id) ? 'Insured' :
                'No insured' }}
              {{}}
            </span>
          </p>
          <p class="list-item mb-10"><img
              :src="addressSvg"
              alt="icon"
            ><span class="list-item-text">{{ patientProfileStore.getSelectedPatientAddress }}</span></p>
          <p class="list-item"><img
              :src="sexSvg"
              alt="icon"
            ><span class="list-item-text">{{ patientProfileStore.getSelectedPatientProfileSex }}</span></p>
          <base-button
            class="btn primary mt-20"
            @click="[patientProfileStore.setCurrentPatientProfileId(profile.id), patientProfileStore.setSelectedPatientProfileId(null)]"
          >
            Choose patient
          </base-button>
        </div>
      </Transition>
    </div>
    <div v-if="!patientProfileStore.getOtherPatientsProfiles.length">
      <base-button
        @click="onCreateNewPatientBtnClick"
        class="btn primary mt-20 mb-20"
      >
        Add other patient
      </base-button>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from "vue";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import newPatientSvg from '@/assets/img/icons/my-acc-icons/new-patient.svg';
import addressSvg from '@/assets/img/icons/my-acc-icons/address.svg';
import dobSvg from '@/assets/img/icons/my-acc-icons/dob.svg';
import insuranceSvg from '@/assets/img/icons/my-acc-icons/insurance.svg';
import sexSvg from '@/assets/img/icons/my-acc-icons/sex.svg';
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const patientProfileStore = usePatientProfilesStore();
    const modal_confirm = ref(false);
    const selected_user_id = ref(null);
    const selectedPatientData = ref({});

    const onSelectPatientBtnClick = (id) => {
      modal_confirm.value = true;
      patientProfileStore.setSelectedPatientProfileId(id);
      selected_user_id.value = id;
      selectedPatientData.value = patientProfileStore.getPatientProfilesList.find(i => i.id === id);
    };

    const onCreateNewPatientBtnClick = () => {
      router.push({ name: 'Signup', params: { id: 0, edit: ' ' } });
    };

    return {
      onCreateNewPatientBtnClick,
      onSelectPatientBtnClick,
      patientProfileStore,
      modal_confirm,
      selected_user_id,
      selectedPatientData,
      newPatientSvg,
      addressSvg,
      dobSvg,
      insuranceSvg,
      sexSvg
    };
  }
});
</script>

<style scoped>
.other-patients-data-list {
  border-radius: 0 !important;
}

.other-patients-list {
  background: #CCEDFB;
  padding: 20px;
  border-bottom: 2px solid #fff;
}

.nb {
  border-bottom: none !important;
}

.last-item-border {
  border-radius: 0px 0px 8px 8px;

}

.first-item-border {
  border-radius: 8px 8px 0px 0px;

}

.other-patients-dob {
  margin-left: 7px;
  color: #333;
  font-weight: 400;
  font-size: 14px;
}

.other-patients-name {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: start;
}

.other-patients-title {
  color: #999;
  font-size: 14px;
}

.other-patients-container {
  margin-top: 25px;
  margin-bottom: 10px;
}

.add-new-patient-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

/*animation styles*/
.v-enter-active,
.v-leave-active {
  transition: all 0.25s ease-in;
}

/*animation styles*/

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.button {
  width: calc(50% - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-container {

  padding: 50px 12px 100px;
}

.list {
  background: #fff;
  padding: 20px;
  border-radius: 16px 16px 8px 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.list-item {
  font-size: 14px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.list-item-text {
  margin-left: 20px;
}
</style>
