<template>
    <div class="bg2">
        <div
            id="registration-success"
            class="page-padd pt-25 content-container"
            style="padding-bottom: 120px"
        >
            <div class="header mb-50">
                <div class="go-back">
                    <router-link
                        :to="{ name: 'MyApplications', params: { id: patientProfileStore.getCurrentPatientProfileId } }"
                    ></router-link>
                    <svg viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                        />
                    </svg>
                </div>
                <div class="title">
                    <div class="color-main bold text-xl">
                        {{ data.sample_id }}
                    </div>
                </div>
            </div>
            <div>
                <div class="application-status-subtitle">Application is</div>
                <div
                    class="application-status-title"
                    :style="data.status_style"
                >{{ data.status_name }}</div>
            </div>
            <div v-if="data.status_id === '1'">
                <h3
                    class="text-md"
                    style="text-align: center; margin-bottom: 30px;"
                >
                    Please write your name, date of birth and sample ID: <strong>{{ data.sample_id }}</strong> on the tube
                </h3>
                <div id="qrcode">
                    <QRCodeVue3
                        :value="data.application_qr_value"
                        :width="270"
                        :height="270"
                        :cornersSquareOptions="{ color: 'black' }"
                        :dotsOptions="{}"
                    />
                </div>
                <div
                    id="application-code"
                    class="text-s text-center"
                    style="margin-bottom: 30px;"
                >
                    {{ data.application_qr_value }}
                </div>
            </div>
            <div
                v-if="data.status_id === '10'"
                class="mt-30"
            >
                <a
                    v-if="pay_url"
                    :href="pay_url"
                    target="_blank"
                    class="btn btn-primary mb-20"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        style="height: 18px; margin-right: 5px"
                    >
                        <path
                            fill="currentColor"
                            d="M512 32C547.3 32 576 60.65 576 96V128H0V96C0 60.65 28.65 32 64 32H512zM576 416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V224H576V416zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H176C184.8 384 192 376.8 192 368C192 359.2 184.8 352 176 352H112zM240 384H368C376.8 384 384 376.8 384 368C384 359.2 376.8 352 368 352H240C231.2 352 224 359.2 224 368C224 376.8 231.2 384 240 384z"
                        />
                    </svg>
                    Pay
                </a>
            </div>
            <!--APPLICATIONS DETAILS-->
            <template
                v-for="panel in data.panels"
                :key="panel"
            >
                <div class="container radius-bottom-2 mb-8">
                    <div v-html="panel.html"></div>
                    <ul
                        v-if="panel.list_items"
                        class="type-2"
                    >
                        <template
                            v-for="item in panel.list_items"
                            :key="item"
                        >
                            <li>
                                <span
                                    :style="item.title_style"
                                    v-if="item.title"
                                >{{ item.title }}: </span>
                                <span :style="item.value_style">{{ item.value }}</span>
                            </li>
                        </template>
                    </ul>
                    <div v-html="panel.html_bottom"></div>
                </div>
            </template>
            <div
                v-if="data.status_id === '3'"
                class="if-status-3 mt-30 text-center"
            >
                <a
                    v-if="data.result_link"
                    :href="data.result_link"
                    class="btn btn-primary mb-20 m-auto"
                    style="max-width: 400px"
                    target="_blank"
                >Result detail page</a>
                <div id="result-detail-qr">
                    <QRCodeVue3
                        :value="data?.result_link"
                        :width="270"
                        :height="270"
                        :cornersSquareOptions="{ color: 'black' }"
                        :dotsOptions="{}"
                    />
                </div>
            </div>
            <InsuranceConsultation />
            <Transition>
                <base-loader
                    v-if="loader_active"
                    class="loader-on-block bg-white"
                />
            </Transition>
        </div>
    </div>
</template>

<script>
import InsuranceConsultation from '@/modules/insurance/components/InsuranceConsultation'
import { defineComponent, inject, onBeforeMount, ref, watchEffect } from "vue";
import { API_application_pay, API_get_application_details } from "@/API/API-applications";
import QRCodeVue3 from "qrcode-vue3";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import { useUserStore } from "@/modules/user/store/user";

export default defineComponent({
    props: {
        application_id: String,
    },
    components: {
        QRCodeVue3,
        InsuranceConsultation,
    },
    setup(props) {
        const patientProfileStore = usePatientProfilesStore()
        const userStore = useUserStore();
        const pay_url = ref('');
        let data = ref({});
        let loader_active = ref(true);
        const alertMessage = inject("alertMessage");
        const application_id = ref('')
        const resultLink = ref('');

        /**
         * Get application details before view is mounted
         */

        onBeforeMount(() => {
            API_get_application_details(patientProfileStore.getCurrentPatientProfileId, props.application_id)
                .then((res) => {
                    if (res.data.status === "success") {
                        application_id.value = res.data.application.id
                        data.value = res.data.application;
                        resultLink.value = res.data.application.result_link;
                        loader_active.value = false;
                        console.log(res.data.application);
                    }
                })
                .catch((err) => {
                    alertMessage.value.show(err.response.data.title, "danger", "small");
                });
        });

        /**
         * Watch and set pay url if exist
         */
        watchEffect(() => {
            API_application_pay(patientProfileStore.getCurrentPatientProfileId, application_id.value)
                .then(res => {
                    if (res.data.status === 'success') {
                        pay_url.value = res.data.pay_url
                    }

                })
        })

        return { data, loader_active, patientProfileStore, userStore, pay_url, resultLink };
    },
});
</script>

<style scoped>
.color-main,
.color-blue {
    color: var(--color-primary);
}

.color-green {
    color: var(--color-green);
}

.color-orange {
    color: var(--color-orange);
}

.color-white {
    color: var(--color-white);
}

.color-red {
    color: var(--color-red);
    opacity: 0.6;
}

.bold {
    font-weight: 700;
}

.if-status-1 h3 {
    font-size: 1.3em;
    font-weight: 500;
    line-height: 1em;
}

.qr-code-block {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
}

.if-status-1 {
    text-align: center;
}

.text-s {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.4em;
}

.container {
    padding: 15px;
    background: #ffffff;
    box-shadow: var(--shadow);
    border-radius: var(--radius);
}

#qrcode,
#result-detail-qr {
    width: max-content;
    margin: 30px auto 0;
}

.green {
    color: #85b744;
}

.blue {
    color: #87c8ee;
}

.orange {
    color: #ef7c45;
}

.header .title {
    width: 100%;
    text-align: center;
    padding-top: 10px;
}

h2.title {
    font-size: 1.6em;
    font-weight: 500;
    line-height: 1em;
    margin-bottom: 50px;
}

.page-padd {
    padding-bottom: 60px;
}

.application-status-subtitle {
    text-align: center;
    font-size: 14px;
    color: var(--color-lightgray);
    margin-bottom: 5px;
    line-height: 1em;
}

.application-status-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 1em;
}

.btn.btn-primary.mb-20 {
    margin: 0 auto 16px;
}

.bg2 {
    min-height: 100vh;
}
</style>