import { defineStore } from "pinia";

export const useMeetStore = defineStore("meet", {
  state: () => ({
    isRinging: false,
    isMeetInited: false,
    roomName: "",
    nickname: "",
    avatar: "",
    patient: {
      name: "",
      sex: "",
      yo: null,
    },
  }),
  persist: {
    storage: localStorage,
    paths: ['patient'],
  },
  getters: {
    // get computed properties from state
    getIsMeetInited: (state) => state.isMeetInited,
    getRoomName: (state) => state.roomName,
  },
  // methods from change state
  actions: {
    setMeetInited(value) {
      this.isMeetInited = value
    },
    setRoomSettings(name) {
      this.roomName = name;
      this.isMeetInited = true;
    },
  },
});
