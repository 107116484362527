<template>
  <div
    id="registration"
    class="h-100 bg2 pt-25"
  >
    <div class="p-relative container">
      <div class="validation-block">
        <div class="header mb-30 mt-10">
          <div class="go-back">
            <router-link to="/dashboard"></router-link>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
              />
            </svg>
          </div>
          <div class="title self-center d-flex h-center">
            <h2>Payment</h2>
          </div>
        </div>

        <account-preview />

        <div>
          <div v-if="insured_status">
            <div class="mt-30 mb-50 text-center">
              <span class="color-green bold text-xl by-insurance">
                <img
                  src="img/icons/file-document-check-outline.svg"
                  alt="img"
                  style="width: 36px; height: 36px; margin-bottom: -8px"
                />
                By Insurance
              </span>
            </div>
          </div>

          <div v-else-if="all_data.patient?.is_usa_documents">
            <div class="mt-30 mb-50 text-center">
              <span class="color-green bold text-xl by-insurance">
                <img
                  src="img/icons/file-document-check-outline.svg"
                  alt=""
                  style="width: 36px; height: 36px; margin-bottom: -8px"
                />
                Document
              </span>
            </div>
          </div>

          <div class="mb-50">
            <h5>Choose test:</h5>
            <div class="mb-50 mt-30">
              <div
                :key="item.id"
                v-for="item in all_data.station?.supplies"
                class="input-group radio"
              >
                <input
                  @change="to_pay = item.payment_sum"
                  name="laboratory_id"
                  :id="'laboratory_id-' + item?.laboratory_id"
                  :value="item?.laboratory_id"
                  :data-price="item.payment_sum"
                  type="radio"
                  class="test_type-3"
                  v-model="state.laboratory_id"
                />
                <label
                  :for="'laboratory_id-' + item?.laboratory_id"
                  style="display: block"
                >
                  {{ item.name }}

                  <span
                    v-if="all_data.patient.need_pay == true"
                    class="color-green text-md bold m-5px price hide"
                  >{{ item.payment_sum }}$</span>
                </label>
              </div>
              <div
                v-if="v$['laboratory_id'].$error"
                class="mt-5 color-red"
              >
                {{ v$["laboratory_id"].$errors[0].$message }}
              </div>
            </div>
            <div class="mb-50 mt-30">
              <div class="input-group radio"></div>

              <!--                                <div class="input-group radio">-->
              <!--                                    <div class="hide-for-international">-->
              <!--                                        <input required name="laboratory_id" id="laboratory_id-1-0" type="radio" value="1"-->
              <!--                                               data-price="0" checked class="test_type-1" data-disabled=disabled>-->
              <!--                                        <label for="laboratory_id-1-0" style="display: block">PCR Nasal swab Testing <span-->
              <!--                                                class="color-green text-md bold m-5px price hide">(fill documents)</span></label>-->
              <!--                                    </div>-->
              <!--                                </div>-->
            </div>

            <h5>Why are you taking the test?</h5>
            <div class="mb-50">
              <div
                class="input-group text-left"
                style="max-width: 520px"
              >
                <select
                  v-model="state.reason"
                  class="input"
                >
                  <option
                    selected
                    hidden
                    disabled
                    value=""
                  >Select</option>
                  <option value="For work mandate">For work mandate</option>
                  <option value="Traveling">Traveling</option>
                  <option value="Feeling symptoms">Feeling symptoms</option>
                  <option value="Possible exposure">
                    Possible exposure
                  </option>
                  <option value="Other">Other</option>
                </select>
                <div
                  v-if="v$.reason.$error"
                  class="mt-5 color-red"
                >
                  {{ v$.reason.$errors[0].$message }}
                </div>
              </div>
            </div>

            <div class="input-group mb-15">
              <button
                id="payment_confirm"
                class="submit btn btn-primary w100"
                :class="!btn_status() && 'disabled'"
                @click="submitForm"
              >
                {{
                  !all_data.patient?.need_pay
                  ? "Confirm"
                  : "Pay $" + to_pay
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Transition>
    <base-loader
      v-if="loader_active"
      class="loader-on-block bg-white"
    ></base-loader>
  </Transition>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  // inject,
  ref, watchEffect,
} from "vue";
import "vue3-tel-input/dist/vue3-tel-input.css";
import useValidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import {
  API_payment_get_supplies,
  API_payment_confirm
} from "@/API/API-applications";
import { useRouter } from "vue-router";
import { useStationsStore } from "@/store/stations";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import AccountPreview from "@/modules/patient-profiles/components/AccountPreview";

export default defineComponent({
  components: { AccountPreview },
  setup() {
    const stationsStore = useStationsStore();
    const patientProfileStore = usePatientProfilesStore();
    const loader_active = ref(false);
    // const alertMessage = inject("alertMessage");
    const router = useRouter();
    const all_data = ref([]);
    const to_pay = ref("");
    const state = ref({
      reason: "",
      station_id: stationsStore.getStationId,
      profile_id: patientProfileStore.getCurrentPatientProfileId,
      laboratory_id: null,
    });
    const insured_status = ref(false);
    const rules = computed(() => {
      return {
        laboratory_id: {
          required: helpers.withMessage("Please choose a test", required),
        },
        reason: {
          required: helpers.withMessage("Please select your reason", required),
        },
      };
    });
    const v$ = useValidate(rules, state);


    function btn_status() {
      if (state.value.laboratory_id !== null && state.value.reason !== "") {
        return true;
      } else {
        return false;
      }
    }

    function submitForm() {
      loader_active.value = true;
      v$.value.$validate();
      if (!v$.value.$error) {
        API_payment_confirm(patientProfileStore.getCurrentPatientProfileId, stationsStore.getStationCode, state.value.laboratory_id, state.value.reason)
          .then((res) => {
            console.log('res.data');
            console.log(res);
            if (res.data.status === "success") {
              if (!res.data.paid_status) {
                router.push({
                  name: "ApplicationDetails",
                  params: {
                    application_id: res.data.application_id,
                    patient_id: patientProfileStore.getCurrentPatientProfileId
                  },
                });
                window.open(res.data.pay_url, "_blank");
                // window.cordova.InAppBrowser.open(res.data.pay_url, '_blank', 'location=yes');
              } else {
                router.push({
                  name: "ApplicationDetails",
                  params: {
                    application_id: res.data.application_id,
                  },
                });
              }
            }
          });
      }
      loader_active.value = false;
    }

    watchEffect(() => {
      loader_active.value = true;
      API_payment_get_supplies(patientProfileStore.getCurrentPatientProfileId, stationsStore.getStationCode)
        .then((res) => {
          console.log('res.data');
          console.log(res.data);
          if (res.data.status === "success") {

            loader_active.value = false;

            // TODO split to different objects
            all_data.value = res.data;
            console.log("response", res.data);
          }
        });
    });

    onMounted(() => {
      const prof = JSON.parse(localStorage.getItem("patientProfile"))['patientProfiles'].find(item => item.id == state.value.profile_id).insured;
      console.log(prof);
      insured_status.value = prof;
    });

    return {
      state,
      v$,
      all_data,
      to_pay,
      submitForm,
      btn_status,
      patientProfileStore,
      loader_active,
      insured_status
    };
  },
});
</script>


<style scoped>.form input {
  background: #fff;
  border: none;
  padding: 0 10px;
  font-size: 13px;
}

.form input:focus,
.form select:focus {
  outline: none !important;
  border-color: #87c8ee;
  box-shadow: 0 0 10px #87c8ee;
}

.form .input-group:not(.checkbox):not(.radio):not(.photo) label {
  position: absolute;
  top: 16px;
  left: 10px;
  font-size: 14px;
  color: var(--color-lightgray);
  font-weight: 400;
  transition: all 0.3s;
}

.text-xl {
  display: flex;
  justify-content: center;
  gap: 12px;
}

#payment_confirm {
    margin: 0 auto;
}
</style>