<template>
	<div class="form-wrapper log-form content-container">
		<div class="form">
			<div class="login-mode-toggle-container">
				<div
					@click="[isModeEmail = true, state.phone = '', codeSent = false, emailError = false], countDown('reset')"
					class="login-mode-toggle-button"
					:class="isModeEmail ? 'active' : ''"
				>
					<p>Login by email</p>
				</div>
				<div
					@click="[isModeEmail = false, state.email = '', codeSent = false, phoneError = false, countDown('reset')]"
					class="login-mode-toggle-button"
					:class="isModeEmail ? '' : 'active'"
				>
					<p>Login by phone</p>
				</div>
			</div>
			<div
				v-if="!isModeEmail"
				class="input-group text-left"
			>
				<label class="mb-8">
					<img
						class="icon-img"
						:src="phoneSvg"
					>
					Phone Number
				</label>
				<vue-tel-input
					:class="v$.phone.$error ? 'danger' : ''"
					@input="onInput"
					:props="'US'"
					class="input"
					:value="state.phone"
				></vue-tel-input>
				<div
					v-if="phoneError"
					class="mt-5 color-red"
				>{{ v$.phone.$errors[0]?.$message }}</div>
			</div>

			<div
				v-if="isModeEmail"
				class="input-group text-left"
			>
				<label class="mb-8">
					<img
						class="icon-img"
						:src="mailSvg"
					>
					Email
				</label>
				<input
					required
					class="input"
					type="email"
					name="email"
					placeholder="Please enter Email"
					v-model="state.email"
				/>
				<div
					v-if="emailError"
					class="mt-5 color-red"
				>{{ v$.email.$errors[0]?.$message }}</div>
			</div>
			<div
				v-if="codeSent"
				class="input-group text-left mt-15"
			>
				<label class="mb-8">
					<svg viewBox="0 0 24 24">
						<path
							fill="currentColor"
							d="M21.03 6.29L12 .64L2.97 6.29C2.39 6.64 2 7.27 2 8V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 7.27 21.61 6.64 21.03 6.29M20 18H4V10L12 15L20 10V18M12 13L4 8L12 3L20 8L12 13Z"
						/>
					</svg>
					Enter code</label>
				<input
					required
					class="input"
					type="number"
					autocomplete="off"
					name="code"
					:placeholder="`Code from ${loginButtonText}`"
					@keydown.enter.prevent
					maxlength="4"
					v-model="state.code"
					@input="checkCode('on-input')"
				/>
			</div>
			<!-- show after SMS is sent END-->
			<div
				class="send_again-wrapper"
				v-if="resend.status !== 0 || resend.counter === 0"
			>
				<div class="send_again">
					<span
						v-if="resend.counter > 0"
						class="text-sm send_again"
					>You will be able to resend code in
						<b>{{ resend.counter }}</b> seconds</span>
					<div
						v-else
						class="button"
						@click="sendCode"
					>Resend code</div>
				</div>
			</div>

			<div class="input-group text-left mt-30">
				<!-- <label class="checkbox">
					<input
						@change="showModal('open')"
						v-model="state.agree"
						:class="v$.agree.$error ? 'danger' : ''"
						type="checkbox"
						class="input"
						required
					/>
					<span class="text-sm bold">I agree to receive SMS/text massages. SMS data rates may apply.I agree with
						<span>
							Privacy Policy
						</span>
					</span>
				</label>
				<div
					v-if="v$.agree.$error"
					class="mt-5 color-red"
				>
					{{ v$.agree.$errors[0].$message }}
				</div> -->

				<div class="checkbox_wrap">
					<div
						@click="showModal('Terms of use')"
						class="check_box"
						:class="{ active: termsStatus }"
					>
						<div></div>
						<span>Agree with terms of use</span>
					</div>
					<div
						:class="{ active: privacyStatus }"
						@click="showModal('Privacy policy')"
						class="check_box"
					>
						<div></div>
						<span>Agree with privacy policy</span>
					</div>
				</div>
			</div>

			<div class="input-group mt-30">
				<base-button
					class="submit primary w100"
					@click="submitForm"
				>
					{{ codeSent ? 'Log In' : "Get " + loginButtonText }}
				</base-button>
			</div>
		</div>
		<Transition>
			<base-loader
				v-if="loader_active"
				class="loader-on-block bg-white"
			></base-loader>
		</Transition>
		<!--        <router-link to="/events" class="btn btn-secondary w100 mt-10">Skip</router-link>-->
	</div>

	<TermsModal
		:btn="true"
		@close="showModal"
		v-if="termsModalStatus"
		:title="modalTitle"
	/>
</template>
<script>
import { defineComponent, inject, ref, watchEffect, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { useUserStore } from "@/modules/user/store/user";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import loginFormRules from "@/modules/user/services/login-form-rules";
import { API_user_checkCode_noToken, API_user_getCode } from "@/API/API-user";
import phoneSvg from '@/assets/img/icons/my-acc-icons/phone.svg'
import mailSvg from '@/assets/img/icons/my-acc-icons/mail.svg'
import accessKeys from "@/modules/user/services/access-keys";
import TermsModal from "@/components/TermsModal.vue";

export default defineComponent({
	components: { TermsModal },

	setup() {
		const router = useRouter();
		const alertMessage = inject("alertMessage");
		const userStore = useUserStore()
		const patientProfilesStore = usePatientProfilesStore()
		const loader_active = ref(false);
		const isModeEmail = ref(true)
		const codeSent = ref(false);
		const modalTitle = ref('');
		const termsStatus = ref(false);
		const privacyStatus = ref(false);

		const termsModalStatus = ref(false);
		function showModal(params) {
			modalTitle.value = params;

			if (params === 'terms') {
				console.log(params);
				termsStatus.value = true;
			}
			if (params === 'policy') {
				console.log(params);
				privacyStatus.value = true;
			}

			if (termsStatus.value && privacyStatus.value) {
				state.value.agree = true;
			}

			termsModalStatus.value = !termsModalStatus.value;


		}

		const resend = ref({
			status: 0,
			counter: 60,
		});
		const loginButtonText = ref('')
		watchEffect(() => {
			isModeEmail.value ? loginButtonText.value = "Email" : loginButtonText.value = "SMS"
		})


		const state = ref({
			phone: "",
			email: "",
			code: "",
			agree: false,
		});
		const v$ = useValidate(loginFormRules, state);
		const emailError = ref(v$.value.email.$error);
		const phoneError = ref(v$.value.phone.$error);

		onBeforeMount(() => {
			console.log(emailError.value);
		})
		function onInput(tel, phoneObject) {
			if (phoneObject?.number) state.value.phone = phoneObject.number;
			codeSent.value = false;
			clearInterval(interval);
			interval = null;
			resend.value.status = 0;
		}

		let interval = null;

		function countDown(action = 'default') {

			if (action === 'reset') {
				interval = null;
				resend.value.status = 0;
				resend.value.counter = 60;

				return;
			}

			resend.value.status = 2;
			resend.value.counter = 30;

			if (interval === null) {
				interval = setInterval(() => {
					if (resend.value.counter <= 0) {
						resend.value.status = 1;
						clearInterval(interval);
						interval = null;
					} else {
						resend.value.counter--;
					}
				}, 1000);
			}
		}


		/**
		 * Submit login form, send code for login or check it if already send
		 */
		function submitForm() {
			loader_active.value = true
			v$.value.$validate().then(() => {
				emailError.value = v$.value.email.$error;
				phoneError.value = v$.value.phone.$error;

				if (!codeSent.value) {
					// send code
					console.log('validation 1');


					if (state.value['agree'] === false) {
						console.log(state.value['agree'])
						console.log(document.querySelectorAll('.check_box'));
						if (!document.querySelectorAll('.check_box')[0].classList.contains('active')) {
							document.querySelectorAll('.check_box')[0].classList.add('error');
							console.log(document.querySelectorAll('.check_box')[0].classList);
						}

						if (!document.querySelectorAll('.check_box')[1].classList.contains('active')) {
							console.log(document.querySelectorAll('.check_box')[1].classList);
							document.querySelectorAll('.check_box')[1].classList.add('error');
						}

					}


					if (!v$.value.$validationGroups["phone"].$error && !isModeEmail.value) {
						// if phone is valid
						sendCode();
						return;
					}

					if (!v$.value.$validationGroups["email"].$error && isModeEmail.value) {
						console.log('validation');
						// if email is valid
						sendCode();
						return;
					}

					loader_active.value = false

				} else {
					// check code
					if (v$.value.$validationGroups["code"].$error) {
						checkCode();
					} else {
						alertMessage.value.show(
							`Enter the code you received in ${loginButtonText.value}`,
							"danger"
						);
					}
				}
				loader_active.value = false
			});

		}

		/**
		 * Send code to user from SMS
		 */
		function sendCode() {
			let userData = {
				email: isModeEmail.value ? state.value.email : '',
				phone: !isModeEmail.value ? state.value.phone : '',
				device_info: function () {
					if (localStorage.getItem('device_info'))
						return JSON.parse(localStorage.getItem('device_info'))
					else
						return { platform: 'unknown' }
				}()
			}

			userData['access_key'] = function () {
				if (userData.device_info.platform.toLowerCase() === "android") {
					return accessKeys.android
				} else if (userData.device_info.platform.toLowerCase() === "ios") {
					return accessKeys.ios
				} else {
					return accessKeys.web
				}
			}()

			if (resend.value.status !== 2) {

				API_user_getCode(userData)
					.then((res) => {
						console.log(state.value.phone);
						if (res.data.status === "success") {
							countDown();
							alertMessage.value.show(res.data.title, "success");
							codeSent.value = true;
						} else {
							let message = res.data.title === undefined ? state.value.phone + ' is incorect number' : res.data.title;
							console.log(res, 'error');
							alertMessage.value.show(message, "success");
						}
						loader_active.value = false
					})
					.catch(() => {
						alertMessage.value.show("Something went wrong", "danger");
						loader_active.value = false
					});
			}
		}

		/**
		 * check user code from SMS
		 * @param mode
		 */
		function checkCode(mode) {
			if (state.value.code && state.value.code.toString().length === 4) {
				alertMessage.value.hideAlert();
				let data = {
					phone: state.value.phone,
					email: state.value.email,
					code: state.value.code,
				};

				API_user_checkCode_noToken(data).then((res) => {
					if (res.data.status === "success") {
						userStore.setUserData(res.data.response.user)
						if (res.data.response.patients && res.data.response.patients.length > 0) {
							patientProfilesStore.updatePatientProfilesData(res.data.response.patients)
							patientProfilesStore.setCurrentPatientProfileId(res.data.response.patients[0].id)

							router.push({ path: "/dashboard" });
						}
						alertMessage.value.show(res.data.title, "success");
						codeSent.value = true;
						loader_active.value = false;
					} else {
						alertMessage.value.show(res.data.title, "danger");
						loader_active.value = false;
					}
				});
			} else {
				if (mode !== "on-input") {
					alertMessage.value.show(
						"Enter the code you received in SMS",
						"danger"
					);
				}
			}
		}

		return {
			termsStatus,
			privacyStatus,
			modalTitle,
			showModal,
			termsModalStatus,
			loader_active,
			onInput,
			submitForm,
			sendCode,
			checkCode,
			codeSent,
			state,
			v$,
			resend,
			isModeEmail,
			phoneSvg,
			mailSvg,
			loginButtonText,
			emailError,
			phoneError,
			countDown
		};
	}
})
</script>
<style scoped>
.login-mode-toggle-button {
	width: 50%;
	padding: 5px;
}

.login-mode-toggle-button.active {
	border-bottom: 1px solid var(--color-primary);
}

.login-mode-toggle-container {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.send_again {
	color: black;
}

.icon-img {
	margin-right: 10px
}

.logo-mini {
	width: 130px;
}

.send_again {
	font-size: 14px;
	color: #666666;
	text-align: center;
}

.send_again.timeout {
	font-size: 0;
}

.send_again .button {
	color: var(--color-primary);
	font-size: 1.2em;
	text-decoration: underline;
}

.send_again.timeout .button {
	display: block;
	font-size: 15px;
	font-weight: 600;
	text-decoration: underline;
	padding: 0;
}

.send_again-wrapper {
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.checkbox a {
	display: inline;
	margin-left: 2px;
	color: var(--color-blue);
}

.log-form {
	max-width: 760px;
	margin: 0 auto;
	min-width: 370px;
}
</style>