<template>
	<div class="bg2">
		<div class="station page-padd pt-25 content-container">
			<div class="top-fixed content-container">
				<div class="go-back">
					<router-link to="/cities"></router-link>
					<svg viewBox="0 0 24 24">
						<path
							fill="currentColor"
							d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
						></path>
					</svg>
				</div>
				<div class="list-title self-center d-flex h-center">
					<p :style="{ width: '75%' }">
						{{ patientProfilesStore.getStationsCityName + ' stations' }}
					</p>
				</div>
				<div class="mode-toggle-btns-container">
					<button
						class="mode-toggle-btn"
						:class="!isMapMode ? 'active' : ''"
						@click="isMapMode = false"
					>List</button>
					<button
						class="mode-toggle-btn"
						:class="{
							active: isMapMode,
							canClick: stations.length == 0,
						}"
						@click="isMapMode = true"
					>
						Map</button>
				</div>
				<button
					style="width: 100%"
					@click="isOnlyActiveStations = !isOnlyActiveStations; isMapMode = false"
					class="btn btn-secondary mt-15"
				>{{ isOnlyActiveStations ? "Show unactive stations" : "Hide unactive stations" }}</button>
			</div>
			<div
				v-if="!isMapMode"
				class="stations"
			>
				<div
					:key="station.id"
					v-for="   station    in    stations   "
					class="location_item item-animation"
				>
					<div class="desc-wrapper">
						<div class="address">{{ station.address }}</div>
						<ul>
							<li class="d-flex h-start">Status: <strong>
									<span :class="station.status === 'Not working' ? 'Not Working' : ''">{{ station.status
									}}</span></strong>
							</li>
							<li class="d-flex h-start">City: <strong>{{ station.city }}</strong></li>
						</ul>
					</div>
					<div class="img-wrapper"><img
							src="img/flask.svg"
							alt=""
						></div>
				</div>
			</div>
			<div
				v-if="isMapMode"
				class="map"
			>
				<GoogleMap
					api-key="AIzaSyBwLJ94dy_JgpTb-uP0QaYfPFFJQmFs4QU"
					style="width: 100%; height: 100%"
					@zoom_changed="markersCluster()"
					:center="{ lat: +stations[0].lat, lng: +stations[0].lng }"
					:styles="mapOptions.styles"
					:backgroundColor="mapOptions.backgroundColor"
					:zoomControl="mapOptions.zoomControl"
					:mapTypeControl="mapOptions.mapTypeControl"
					:scaleControl="mapOptions.scaleControl"
					:streetViewControl="mapOptions.streetViewControl"
					:rotateControl="mapOptions.rotateControl"
					:fullscreenControl="mapOptions.fullscreenControl"
					:restriction="mapOptions.restriction"
					:clickableIcons="false"
					:zoom="10"
					ref="mapRef"
				>
					<!-- <Marker v-for="(station, index) in stations" :key="index" :options="{ position: {lat: +station.lat, lng: +station.lng} }" /> -->
					<GmapCustomMarker
						v-for="   station    in    stations   "
						:key="station.id"
						:marker="{ lat: +station.lat, lng: +station.lng }"
						class="custom-marker"
						:class="{
							'marker-collapsed': station.isCollapsed,
							'active': isMarkerInfoOn && markerCode === station.code
						}
							"
					>
						<img
							@click="onMarkerClick(station.code)"
							:src="station.status === 'Not working' ? stationOffSvg : stationOnSvg"
							alt="icon"
						>
						<div
							class="marker-info-container"
							v-if="isMarkerInfoOn && markerCode === station.code"
						>
							<p class="marker-title">{{ station.title }}</p>
							<p class="marker-text"><img
									:src="redMarkerSvg"
									alt="icon"
								> {{ station.address }}</p>
							<p class="marker-subtext">Status:<span
									:class="station.status === 'Not working' ? 'status-off-text' : 'status-on-text'"
								> {{
									station.status === 'Not working' ? ' Not working' : ' Open Now'
								}}</span>
							</p>
						</div>
					</GmapCustomMarker>
				</GoogleMap>
			</div>
			<Transition>
				<base-loader
					v-if="loader_active"
					class="loader-on-block bg-white"
				/>
			</Transition>
		</div>
	</div>
</template>
<script>
import { API_get_stations_list } from '@/API/API-station';
import { usePatientProfilesStore } from '@/modules/patient-profiles/store/patient-profiles';
import { defineComponent, ref, onBeforeMount, inject, watchEffect } from 'vue';
import { useRouter } from "vue-router";
import { GoogleMap } from 'vue3-google-map'
import map_options from '@/services/mapOptions'
import GmapCustomMarker from 'vue3-gmap-custom-marker';
import { spaceBetweenMarkers } from "@/modules/stations/utils/spaceBetweenMarkers";
import stationOffSvg from '@/assets/img/icons/station-off.svg'
import stationOnSvg from '@/assets/img/icons/station-on.svg'
import redMarkerSvg from '@/assets/img/icons/red-geomarker.svg'

export default defineComponent({
	components: { GoogleMap, GmapCustomMarker },

	setup() {
		// Google Map
		const mapRef = ref({});

		const isMapMode = ref(false)
		const isMarkerInfoOn = ref(false)
		const loader_active = ref(true);
		const isOnlyActiveStations = ref(true)
		const stationsAll = ref([])
		const stations = ref([])
		const patientProfilesStore = usePatientProfilesStore()
		const alertMessage = inject('alertMessage');
		const router = useRouter()
		const markerCode = ref('')

		const mapOptions = ref(map_options)

		const onMarkerClick = (code) => {
			if (code === markerCode.value) {
				isMarkerInfoOn.value = false
				markerCode.value = ''
			} else {
				markerCode.value = code
				isMarkerInfoOn.value = true
			}
		}

		onBeforeMount(() => {
			API_get_stations_list(patientProfilesStore.getStationsCityId)
				.then((res) => {
					if (!res.data.error) {
						for (var key in res.data.list) {
							res.data.list[key]['isCollapsed'] = false
						}
						stationsAll.value = res.data.list
						// stations.value = res.data.list

						stations.value = stationsAll.value.filter((station) => {
							return station.status !== 'Not working'
						})

						// TODO
						setTimeout(() => {
							markersCluster()
						}, 1500)
					} else {
						alertMessage.value.show('Sorry no workstations available right now', 'danger', 'small')
						router.push({ name: "Cities" })
					}
					loader_active.value = false;
				})
				.catch((err) => {
					alertMessage.value.show(err.response.data.title, 'danger', 'small')
				});

			console.log(stations.value);
		})

		watchEffect(() => {
			if (isOnlyActiveStations.value) {
				stations.value = stationsAll.value.filter((station) => {
					return station.status !== 'Not working'
				})
			} else {
				stations.value = stationsAll.value
			}
		})

		function markersCluster() {
			stations.value.forEach((marker) => {
				let conflict = false;
				stations.value.forEach((comparableMarker) => {
					if (marker !== comparableMarker) {
						let distance = spaceBetweenMarkers(
							mapRef.value.map,
							{ position: { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) } },
							{ position: { lat: parseFloat(comparableMarker.lat), lng: parseFloat(comparableMarker.lng) } }
						);

						if (distance < 90) {
							conflict = true;

							comparableMarker.isCollapsed = true;
						} else {
							if (!conflict) {
								marker.isCollapsed = false;
							}
						}
					}
				});
			});
		}

		return {
			mapRef,
			loader_active,
			stations,
			isOnlyActiveStations,
			patientProfilesStore,
			isMapMode,
			mapOptions,
			stationOffSvg,
			stationOnSvg,
			isMarkerInfoOn,
			redMarkerSvg,
			onMarkerClick,
			markerCode,
			markersCluster
		}
	}
})
</script>
<style scoped>
.top-fixed {
	position: fixed;
	top: 20px;
	left: 0;
	right: 0;
	padding: 20px;
	z-index: 200;
}


.top-fixed::after {
	position: absolute;
	top: -40px;
	left: 0;
	content: "";
	width: 100%;
	height: calc(100% + 50px);
	background-color: #fff;
	border-radius: 10px;
	filter: blur(17px);
	z-index: -1;
}

.img-wrapper {
	width: 140px;
	height: 140px;
}

.time-text {
	font-size: 12px;
	color: #00A5E9;
}

.status-off-text {
	font-size: 12px;
	color: #FF5151;
}

.status-on-text {
	font-size: 12px;
	color: #85B744;
}

.marker-title {
	font-size: 16px;
	font-weight: 500;
	color: #333
}

.marker-text {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	color: #666;
	margin: 15px 0;
}

.marker-info-container {
	position: relative;
	z-index: 3;
	background: #FFFFFF;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	border-radius: 8px 8px 16px 16px;
	padding: 20px;
}

.mode-toggle-btns-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	border-radius: 8px 8px 1px 1px;
	margin-bottom: 15px;
}

.mode-toggle-btn {
	width: 50%;
	padding: 20px;
	border-bottom: 2px solid #C4DEEC;
}

.mode-toggle-btn.active {
	border-bottom: 2px solid var(--color-primary);
}

.list-title {
	margin-bottom: 20px;
	font-size: 20px;
}

.list-title span {
	display: block;
}

.station {
	position: relative;
	padding-top: 50px;
	min-height: 100vh;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.item-animation {
	opacity: 0;
	animation: show 0.3s forwards;
}

.select_city {
	justify-self: center;
}

.map,
.stations {
	margin-top: 198px;
}

.map {
	margin-top: 180px;
	height: calc(100vh - 300px);
}

.stations {
	overflow-y: auto;
	padding-bottom: 50px;
	animation: fadeIn 1s;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.stations::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.stations {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.station li {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
}

.station a.location_item li {
	display: flex;
	justify-content: flex-start;
	font-size: 12px;
}

.location_item {
	width: 98%;
	margin-top: 5px;
	margin-left: auto;
	margin-right: auto;
}

.station .title {
	max-width: 80%;
}

.no-click {
	pointer-events: none;
}

@keyframes show {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

div.custom-marker.active {
	z-index: 100 !important;
}

.custom-marker>img {
	position: absolute;
	z-index: 1;
	height: 80px;
	width: 80px;
	min-height: 80px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.custom-marker.marker-collapsed {
	opacity: 0.4;
}

.custom-marker.marker-collapsed>img {
	height: 35px;
	width: 35px;
	min-height: 35px;
	max-height: 35px;
}

.canClick {
	pointer-events: none;
	color: #d6d3d3;
}
</style>