export default {
    autoDefaultCountry: true,
    // defaultCountry: 'US',
    ignoredCountries: ['RU'],
    inputOptions: {
        autofocus: true,
        showDialCode: false
    },
    dropdownOptions: {
        showDialCodeInSelection: false,
        showSearchBox: true
    },
    preferredCountries: ['US'],
    validCharactersOnly: true
}