<template>
  <div id="jitsi-meet-container"></div>
</template>

<script>
import { defineComponent, watchEffect } from "vue";

import createRoom from "../utils/create-room.js";
import { useMeetStore } from "@/store/meet";

export default defineComponent({
  setup() {
    const meetStore = useMeetStore();


    watchEffect(() => {
      if (meetStore.isMeetInited) {
        /**
         * check if patient call to nurse and create call room between them
         */
        createRoom();

        /* eslint-disable */
        if (process.env.VUE_APP_BUILD_MODE_CORDOVA === "true") {
          
          if (cordova.platformId === "android") {
            const { permissions } = cordova.plugins;

            permissions.checkPermission(permissions.RECORD_AUDIO, function (status) {
              if (status.hasPermission !== true) {
                permissions.requestPermissions(
                    [permissions.RECORD_AUDIO]
                );
              }
            });

            permissions.checkPermission(permissions.CAMERA, function (status) {
              if (status.hasPermission !== true) {
                permissions.requestPermissions(
                    [permissions.CAMERA]
                );
              }
            });
          }
        }
      }
    });
  },
});
</script>