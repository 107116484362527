<template>
  <div class="col-item text-center inner-padd h100 d-flex column h-center">
    <div class="container">
      <div class="p-relative">
        <div class="go-back">
          <a @click="askLogout()"></a>
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            />
          </svg>
        </div>
        <div class="self-center mb-15">
          <div class="h3" style="padding-left: 30px; padding-right: 30px">
            Confirm Your Contacts
          </div>
        </div>
        <div class="text mb-50">
          We need your contacts to send test results, checks and other
          notifications
        </div>
        <form class="mt-120 mb-120">
          <div class="validation-block">
            <div class="input-group text-left mb-30">
              <label class="mb-8 d-flex h-between">
                <span>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"
                    />
                  </svg>
                  <span>Email:</span>
                </span>
                <span class="text text-md color-primary">{{
                  user_data?.email
                }}</span>
              </label>
              <div class="text text-md color-primary text-center mt-15">
                <span
                  v-if="user_data?.confirmed == 3 || user_data?.confirmed == 8"
                  class="color-green text-md"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    width="24"
                    height="24"
                    viewBox="-2 -2 28 28"
                    fill="currentColor"
                  >
                    <path
                      d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                    />
                  </svg>
                  Confirmed</span
                >

                <div
                  v-else
                  @click="email_confirm_modal = true"
                  class="btn btn-primary size1 mt-15"
                  data-to="confirm_email_modal"
                  data-key="email"
                >
                  Confirm email
                </div>
              </div>
            </div>

            <hr />

            <div class="input-group text-left mb-30">
              <label class="mb-8 d-flex h-between">
                <span>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20 15.5C18.8 15.5 17.5 15.3 16.4 14.9H16.1C15.8 14.9 15.6 15 15.4 15.2L13.2 17.4C10.4 15.9 8 13.6 6.6 10.8L8.8 8.6C9.1 8.3 9.2 7.9 9 7.6C8.7 6.5 8.5 5.2 8.5 4C8.5 3.5 8 3 7.5 3H4C3.5 3 3 3.5 3 4C3 13.4 10.6 21 20 21C20.5 21 21 20.5 21 20V16.5C21 16 20.5 15.5 20 15.5M5 5H6.5C6.6 5.9 6.8 6.8 7 7.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5M19 19C17.7 18.9 16.4 18.6 15.2 18.2L16.4 17C17.2 17.2 18.1 17.4 19 17.4V19M15 12H17A5 5 0 0 0 12 7V9A3 3 0 0 1 15 12M19 12H21C21 7 16.97 3 12 3V5C15.86 5 19 8.13 19 12Z"
                    />
                  </svg>
                  <span>Phone Number:</span>
                </span>
                <span class="text text-md color-primary">{{
                  user_data?.phone
                }}</span>
              </label>
              <div class="text text-md color-primary text-center mt-15">
                <span
                  v-if="user_data?.confirmed == 5 || user_data?.confirmed == 8"
                  class="color-green text-md"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    width="24"
                    height="24"
                    viewBox="-2 -2 28 28"
                    style="vertical-align: middle"
                    fill="currentColor"
                  >
                    <path
                      d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                    />
                  </svg>
                  Confirmed</span
                >

                <span
                  v-else
                  @click="phone_confirm_modal = true"
                  class="btn btn-primary size1"
                  >Confirm phone</span
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <ModalConfirmContacts
    v-if="phone_confirm_modal"
    :active="phone_confirm_modal"
    :redirect="true"
    :mode="'phone'"
    @close="phone_confirm_modal = false"
  />

  <ModalConfirmContacts
    v-if="email_confirm_modal"
    :active="email_confirm_modal"
    :redirect="true"
    :mode="'email'"
    @close="email_confirm_modal = false"
  />

  <!--  <div v-if="email_confirm_modal" :class="email_confirm_modal ? 'active' : ''" class="modal-confirm">-->
  <!--    <div class="overlay"></div>-->
  <!--    <div class="inner-wrapper text-center">-->
  <!--      <h3 class="mb-30">Enter code from Email</h3>-->

  <!--      <div class="input-group mb-20 w100">-->
  <!--        <label class="mb-8">-->
  <!--          <svg viewBox="0 0 24 24">-->
  <!--            <path fill="currentColor"-->
  <!--                  d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"/>-->
  <!--          </svg>-->
  <!--          Email</label>-->
  <!--        <input id="email_confirm" required name="email" class="input" type="email" placeholder="Enter your email" value="test@test.com">-->
  <!--      </div>-->
  <!--      <div class="input-group text-left w100">-->
  <!--        <label class="mb-8">-->
  <!--          <svg viewBox="0 0 24 24">-->
  <!--            <path fill="currentColor"-->
  <!--                  d="M21.03 6.29L12 .64L2.97 6.29C2.39 6.64 2 7.27 2 8V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 7.27 21.61 6.64 21.03 6.29M20 18H4V10L12 15L20 10V18M12 13L4 8L12 3L20 8L12 13Z"/>-->
  <!--          </svg>-->
  <!--          Enter code</label>-->
  <!--        <input required data-mask="####" class="input masked code_confirm" type="number" autocomplete="off"-->
  <!--               name="code" placeholder="Enter code">-->
  <!--      </div>-->
  <!--      <div class="btn btn-primary w100 h-45px lh-lg btn-lg submit mt-30">Confirm</div>-->
  <!--    </div>-->

  <!--  </div>-->
</template>

<script>
import { defineComponent, ref } from "vue";
import ModalConfirmContacts from "@/components/ModalConfirmContacts";
import {API_user_logout} from '@/API/API-user'
export default defineComponent({
  components: {
    ModalConfirmContacts,
  },
  setup() {
    const user_data = ref(
      JSON.parse(localStorage.getItem("healeze_patient_user_data"))
    );

    const phone_confirm_modal = ref(false);

    const email_confirm_modal = ref(false);

    function askLogout() {
      if (confirm("You want to log out?")) API_user_logout();
    }

    return { phone_confirm_modal, email_confirm_modal, user_data, askLogout };
  },
});
</script>


<style scoped>
.input-group label svg {
  vertical-align: middle;
}

.confirm-contact-text {
  display: flex;
  align-items: center;
}

.confirm-contact-text > span {
  display: inline-flex;
  align-items: center;
  margin-left: 25px;
  font-weight: 600;
}
</style>