<template>
  <div class="content-container">
    <div class="header mb-30 mt-10 pt-25">
      <div class="go-back">
        <router-link to="/center-list"></router-link>
        <svg viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
          />
        </svg>
      </div>
      <div class="title self-center d-flex h-center">
        <h3>{{ title }}</h3>
      </div>
    </div>
    <div class="test-center-wrap">
      <header>
        <div class="location-wrap">
          <span class="center-address">
            <img
              class="location-icon"
              :src="locationSVG"
              alt=""
            >
            {{ address }}
            <button
              class="show-on-map"
              @click.prevent="goToMap"
            >
              Show on map
            </button>
          </span>
          <span class="center-work-time">{{ description }}</span>
        </div>
        <img
          class="center-photo"
          :src="photo"
          alt="photo"
        >
      </header>
      <div class="calendar-wrap">
        <Datepicker
          @changed-month="changeMonth()"
          @input="scrollToTime"
          v-model="date"
          :inline="true"
          wrapper-class="calendar"
          @selected="selectDate"
          :disabled-dates="disabledDates"
        />
        <div>
          <p class="time-title">Time</p>
          <div
            ref="targetElement"
            class="time"
          >
            <span
              :key="time.time"
              v-for="time in times"
              :class="{
                selected: selectedTime === time.key,
                disabled: checkTime(time.key),
              }"
              @click="setTime(time.key)"
            >
              {{ time.time }}
            </span>
          </div>
          <div
            class="send-date-btn"
            :class="selectedTime ? '' : 'disabled'"
            @click="bookAppointment"
          >
            Book Appointment
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="modalBcg"
      class="confirm-modal-bcg"
    ></div>
    <div
      v-if="toConfirm"
      class="confirm-modal confirm-position"
    >
      <p class="confirm-modal-title">You want to book a doctor's appointment</p>
      <p class="confirm-modal-date">
        {{ infoMonth(state.month) }} / {{ day }} / {{ state.year }} /
        {{ infoTime(selectedTime) }}
      </p>
      <input
        v-model="scheduleText"
        class="schedule-reason"
        placeholder="Reasons for your visit (optional)"
      >
      <div class="confirm-modal-btn-wrap">
        <button
          @click="sendBookAppointment"
          class="confirm-btn"
        >Confirm</button>
        <button
          @click="bookAppointment"
          class="cancel-btn"
        >Cancel</button>
      </div>
    </div>
    <div
      v-if="tnxConfirm"
      class="tnx-modal confirm-position"
    >
      <p class="confirm-modal-title">
        Thank you for filling out the form, our specialist will contact you later
      </p>
      <div class="confirm-modal-btn-wrap">
        <button
          @click="closeTnxModal"
          class="confirm-btn"
        >Confirm</button>
      </div>
    </div>
    <Transition>
      <base-loader
        v-if="loader_active"
        class="loader-on-block bg-white"
      />
    </Transition>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, computed } from "vue";
import Datepicker from "vuejs3-datepicker";
import axios from "axios";
import router from "@/router";
import locationSVG from '@/assets/img/icons/center_location.svg';


import qs from "qs";
// import { API_get_stations_list } from '@/API/API-station';
export default defineComponent({
  components: {
    Datepicker,
  },
  props: {
    code: String,
    drchrono_id: String,
    title: String,
    address: String,
    photo: String,
    lat: String,
    lng: String,
    description: String
  },

  setup(props) {
    const targetElement = ref(null);
    let loader_active = ref(true);
    const date = ref(new Date());
    const currentPatientProfileId = JSON.parse(
      localStorage.getItem("patientProfile")
    ).currentPatientProfileId;
    const selectedTime = ref('');
    const scheduleText = ref('');
    const times = [
      {
        key: "09:00",
        time: "09:00 a.m",
      },
      {
        key: "09:30",
        time: "09:30 a.m",
      },
      {
        key: "10:00",
        time: "10:00 a.m.",
      },
      {
        key: "10:30",
        time: "10:30 a.m.",
      },
      {
        key: "11:00",
        time: "11:00 a.m.",
      },
      {
        key: "11:30",
        time: "11:30 a.m.",
      },
      {
        key: "12:00",
        time: "12:00 p.m.",
      },
      {
        key: "12:30",
        time: "12:30 p.m.",
      },
      {
        key: "13:00",
        time: "1:00 p.m.",
      },
      {
        key: "13:30",
        time: "1:30 p.m.",
      },
      {
        key: "14:00",
        time: "2:00 p.m.",
      },
      {
        key: "14:30",
        time: "2:30 p.m.",
      },
      {
        key: "15:00",
        time: "3:00 p.m.",
      },
      {
        key: "15:30",
        time: "3:30 p.m.",
      },
      {
        key: "16:00",
        time: "4:00 p.m.",
      },
      {
        key: "16:30",
        time: "4:30 p.m.",
      },
      {
        key: "17:00",
        time: "5:00 p.m.",
      },
      {
        key: "17:30",
        time: "5:30 p.m.",
      },
      {
        key: "18:00",
        time: "6:00 p.m.",
      },
    ];
    const monthObj = [
      {
        name: "Jan",
        order: 1,
      },
      {
        name: "Feb",
        order: 2,
      },
      {
        name: "Mar",
        order: 3,
      },
      {
        name: "Apr",
        order: 4,
      },
      {
        name: "May",
        order: 5,
      },
      {
        name: "Jun",
        order: 6,
      },
      {
        name: "Jul",
        order: 7,
      },
      {
        name: "Aug",
        order: 8,
      },
      {
        name: "Sep",
        order: 9,
      },
      {
        name: "Oct",
        order: 10,
      },
      {
        name: "Nov",
        order: 11,
      },
      {
        name: "Dec",
        order: 12,
      },
    ];

    function goToMap() {
      window.open(`https://maps.google.com/?q=${props.lat},${props.lng}`);
    }

    let schedule = ref({});
    let day = ref(date.value.getDate());
    const toConfirm = ref(false);
    const modalBcg = ref(false);
    const tnxConfirm = ref(false);
    const state = ref({
      year: date.value.getFullYear().toString(),
      month: (date.value.getMonth() + 1).toString(),
      clinic_id: props.drchrono_id,
    });

    const disabledDates = {
      //   /  to: new Date(Date.now() - 3600 * 1000 * 24), // Disable all dates up to specific date
      to: new Date(Date.now()), // Disable all dates up to specific date

      preventDisableDateSelection: true,
    };

    function closeTnxModal() {
      tnxConfirm.value = false;
      modalBcg.value = false;
    }

    function infoMonth(order) {
      let month = monthObj.find((item) => item.order == order);

      return month.name;
    }

    function infoTime(selectedTime) {
      let time = times.find((item) => item.key == selectedTime);

      return time.time;
    }

    function selectDate() {
      setTimeout(() => {
        state.value.year = date.value.getFullYear();
        state.value.month = date.value.getMonth() + 1;
        day.value =
          date.value.getDate().toString().length === 1
            ? "0" + date.value.getDate()
            : date.value.getDate();
      }, 500);
    }

    function scrollToTime() {
      targetElement.value.scrollIntoView({ behavior: 'smooth' });
    }

    const checkTime = computed(() => {
      return (time) => {
        const currentDate = new Date();
        const checkDate = date.value;
        const createDate = time.split(":");
        checkDate.setHours(createDate[0], createDate[1], 0);

        if (typeof schedule.value[day.value] === "object") {
          let timeArr = Object.keys(schedule.value[day.value]);

          if (timeArr.includes(time) || checkDate.getTime() < currentDate.getTime()) {
            return "disabled";
          }

        } else {

          if (checkDate.getTime() < currentDate.getTime()) {
            return "disabled";
          }
        }
      }
    })

    function changeMonth() {
      setTimeout(() => {
        const month = document
          .querySelector(".day__month_btn")
          .innerHTML.split(" ")[0];

        monthObj.forEach((item) => {
          if (item.name === month) {
            state.value.month = item.order;
          }
        });

        getSchedule(state.value);
      }, 500);
    }

    function bookAppointment() {
      if (selectedTime.value.length) {
        toConfirm.value = !toConfirm.value;
        modalBcg.value = !modalBcg.value;
      }
    }

    function sendBookAppointment() {
      const url = process.env.VUE_APP_API_URL;

      bookAppointment();
      loader_active.value = true;

      axios
        .post(
          url + "schedule/add",
          qs.stringify({
            patient_id: currentPatientProfileId,
            clinic_id: props.drchrono_id,
            clinic_code: props.code,
            clinic_title: props.title,
            time: selectedTime.value,
            day: day.value,
            month: state.value.month,
            year: state.value.year,
            reason: scheduleText.value,
          })
        )
        .then(() => {
          router.push({ path: 'center-list' });
        });
    }

    function setTime(time) {
      selectedTime.value = time;
    }

    onBeforeMount(() => {
      if (props.drchrono_id == undefined) {
        router.push({ path: '/center-list' });
      }
      getSchedule(state.value);
    });

    function getSchedule(state) {
      const url = process.env.VUE_APP_API_URL;

      axios.post(url + "schedule/get", qs.stringify(state)).then((response) => {
        schedule.value = response.data.response;
        loader_active.value = false;
      });
    }

    return {
      date,
      selectDate,
      disabledDates,
      times,
      checkTime,
      selectedTime,
      closeTnxModal,
      setTime,
      bookAppointment,
      loader_active,
      tnxConfirm,
      sendBookAppointment,
      changeMonth,
      infoMonth,
      state,
      day,
      toConfirm,
      modalBcg,
      infoTime,
      scheduleText,
      scrollToTime,
      targetElement,
      goToMap,
      locationSVG
    };
  },
});
</script>

<style>
.confirm-modal-bcg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10001;
}

.header {
  padding-left: 16px;
  padding-right: 16px;
}

.confirm-position {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto auto;
  padding: 20px;
  width: 100%;
  z-index: 10002;
  background-color: white;
  height: max-content;
  max-width: 600px;
  border-radius: 16px;
}

.confirm-modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  color: #333333;
  text-align: center;
}

.confirm-modal-date {
  text-align: center;
  color: #00a5e9;
  font-size: 16px;
  padding: 16px 0;
  font-weight: 700;
}

.modal-info {
  padding: 20px;
}

.confirm-modal-btn-wrap {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.confirm-btn,
.cancel-btn {
  padding: 12px 20px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.confirm-btn {
  background-color: #85b744;
  border: 1px solid transparent;
}

.confirm-btn:hover {
  background-color: white;
  border-color: #85b744;
  color: #85b744;
}

.cancel-btn {
  border: 1px solid #000000;
  color: black;
}


.search {
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 4px;
  padding: 14px 18px;
  border: 1px solid #000000;
  border-radius: 8px;
}

.center-doc-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333333;
}

.center-best {
  display: block;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 24px;
}

.send-date-btn {
  width: max-content;
  padding: 14px 54px;
  margin: 0 auto;
  background: #00a5e9;
  border-radius: 8px;
  color: white;
}

.send-date-btn.disabled {
  background-color: gray;
  pointer-events: none;
}

.test-center-wrap {
  padding: 0 0 150px;
}

header {
  display: grid;
  gap: 14px;
  padding-bottom: 42px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
}

.calendar .vuejs3-datepicker__calendar {
  width: 100%;
  border-radius: 40px;
}

.calendar {}

.vuejs3-datepicker__calendar-actionarea {
  padding: 20px;
}

.calendar .cell:not(.month, .year) {
  border-radius: 6px;
  width: 52px;
  height: 52px;
  line-height: 52px;
}

.vuejs3-datepicker__calendar header {
  width: 50%;
  margin: 0 auto;
  border-bottom: none;
}

.calendar .cell.disabled {
  background-color: #b6b6b6;
}

/* .calendar .cell.month {
  width: 33.3%;
} */

.vuejs3-datepicker__calendar-actionarea {
  padding: 24px 10px;
}

.time {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
  padding-bottom: 16px;
  margin: 16px;
}

.time-title {
  /* padding: 16px 0; */
  text-align: center;
  font-weight: 500;
  line-height: 24px;
  color: #333333;
}

.time span {
  padding: 10px 16px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  border: 1px solid #57b6dd;
  border-radius: 8px;
  text-align: center;
  flex-shrink: 0;
}

.calendar .cell.selected,
.time span.selected {
  background: #b9dea9;
}

.time span.disabled {
  background: rgb(235, 235, 235);
  border-color: #fbfbfb;
  color: rgb(180, 180, 180);
  pointer-events: none;
}

.calendar .vuejs3-datepicker__calendar-actionarea>div {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 14px;
}

.vuejs3-datepicker__calendar-topbar {
  display: none;
  background: #b9dea9;
}

.vuejs3-datepicker__calendar-topbar-day {
  font-size: 16px;
}

.vuejs3-datepicker__calendar-topbar-year {
  font-size: 20px;
}

.vuejs3-datepicker__calendar .cell.selected:hover {
  background: #b9dea9;
}

.vuejs3-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border-color: #b9dea9;
}

.center-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.center-photo {
  border-radius: 6px;
  width: 100%;
  max-width: 500px;
  max-height: 250px;
  object-fit: cover;
}

.schedule-reason {
  padding: 8px 16px;
  background: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  resize: none;
  height: 61px;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.schedule-reason:focus::placeholder {
  color: transparent;
}

.center-address {
  display: flex;
}

.center-address img {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.calendar-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.location-wrap {}

header {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1056px) {
  .time {
    grid-template-columns: repeat(3, 1fr);
  }

  .calendar .vuejs3-datepicker__calendar-actionarea>div {
    gap: 8px;
  }

  .calendar .cell:not(.month, .year) {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}

@media (max-width: 800px) {
  .calendar-wrap {
    grid-template-columns: 1fr;
  }

  .time {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: calc(100vw - 32px);
    margin: 0;
    padding: 0 0 16px 0;
    overflow: scroll;
    margin-bottom: 16px;
  }

  .calendar .cell:not(.month, .year) {
    height: 30px;
    width: 30px;
    line-height: 30px;

  }

  header {
    display: flex;
    flex-direction: column;
  }
}

.center-address {
  display: grid;
  grid-template-columns: max-content max-content;
  row-gap: 16px;
}

.show-on-map {
  display: block;
  grid-column: 1/3;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 25px;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}
</style>
