import axios from "axios";
import qs from "qs";
import router from "@/router";
import { useUserStore } from "@/modules/user/store/user";
// import {usePatientProfilesStore} from "@/modules/patient-profiles/store/patient-profiles";

const url = process.env.VUE_APP_API_URL;

/**
 *  set headers to axios interceptor
 */
axios.interceptors.request.use(config => {
	const userStore = useUserStore()
	config.headers['Authorization'] = "Bearer " + userStore.token
	config.headers["content-type"] = "application/x-www-form-urlencoded;charset=utf-8"
	return config
})

/**
 * Logout user from app
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_user_logout() {
	// const userStore = useUserStore()
	// const patientProfileStore = usePatientProfilesStore()
	localStorage.clear()
	sessionStorage.clear()
	return axios
		.post(
			url + "users/logout",
			{}
		)
		.then((res) => {
			if (res.data.status === "success") {
				// userStore.$reset();
				// patientProfileStore.$reset();
				// router.push({ path: "/login" });
				router.push({ name: "Login" });
			}
		});
}

/**
 * Get code from sms
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_user_getCode(data = {}) {
	return axios
		.post(
			url + "patient/get_code",
			qs.stringify(data),
			{ headers: { Authorization: "" } }
		)
		.then((r) => r);
}

/**
 * Check code from sms
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_user_checkCode(data = {}) {
	return axios
		.post(
			url + "patient/check_code",
			qs.stringify(data)
		)
		.then((r) => r);
}

export function API_user_checkCode_noToken(data = {}) {
	return axios
		.post(
			url + "patient/check_code",
			qs.stringify(data),
			{ headers: { Authorization: "" } }
		)
		.then((r) => r);
}

export function API_user_terms(data = {}, url) {
	return axios
		.post(
			url,
			qs.stringify(data)
		)
		.then((r) => r);
}



export function API_user_save(data = {}) {
	return axios
		.post(
			url + "patient/save",
			qs.stringify(data)
		)
		.then((r) => r);
}

/**
 * Delete user account
 * @param user_id
 * @param code
 * @param doctor
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_user_delete(user_id, code = '') {
	return axios
		.post(url + "users/delete/" + user_id, qs.stringify({ user_id, code }))
		.then((r) => r)
		.catch((err) => {
			if (err.response.status === 401) API_user_logout()
			if (err.response.status === 403) {
				console.log("can't delete doctor")
				return err
			}
		});
}

export function API_get_user_data() {
	return axios
		.post(
			url + "patient",
			{}
		)
		.then((r) => r)
		.catch((err) => {
			if (err.response.status === 401) API_user_logout();
		});
}