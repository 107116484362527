<template>
  <button class="btn">
    <slot></slot>
  </button>
</template>

<script>
</script>

<style scoped>
button {
  transition: all .3s;
  border-radius: 10px;
  font-weight: 500;
  padding: 14px 0;
  cursor: pointer;
  width: 100%;
}

.primary {
  background: var(--color-primary);
  color: #fff;
}

.danger {
  background: var(--color-red);
  color: #fff;
}

.danger-outlined {
  background: transparent;
  color: var(--color-red);
  border: 1px solid var(--color-red);
}
.primary-outlined {
  background: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}


.edit,
.secondary {
  border: 1px solid var(--color-primary);
}

.secondary {
  color: var(--color-primary);
}

.edit {
  padding: 5px 12px;
  font-size: 12px;
}


.btn {
  outline: none;
  height: 50px;
  text-align: center;
  border-radius: 10px;
  font-weight: 500;
  font-size: 0.9em;
  line-height: 2.5em;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.btn-primary {
  background: var(--color-primary);
  color: #fff;
  transition: all .3s;
}
.btn-primary:hover {
  opacity: 0.7;
}
.btn-secondary {
  background: #fff;
  border: 1px solid var(--color-primary);
  color: var(--color-dark);
}
.btn-1 {
  background: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);

  font-size: 12px;
  font-weight: 600;
}
.btn-1 svg {
  height: 20px;
  width: 20px;
  margin-right: 12px;
}
.btn-1 svg path {
  fill: var(--color-primary);
  color: var(--color-primary);
}
.btn.size1 {
  height: 40px;
  padding: 0 28px;
  font-size: 13px;
}

.btn-xs {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
}

.btn.color3 {
  background: var(--color-green);
}

.link-1 {
  color: var(--color-primary);
  font-weight: 600;
}

</style>