<template>
	<div class=" content-container h100 text-center vertical-centered d-flex column h-center inner-padd page-padd">
		<login-header />
		<login-form />
	</div>

</template>

<script>
import { defineComponent } from "vue";
import LoginForm from "@/modules/user/components/LoginForm";
import LoginHeader from "@/modules/user/components/LoginHeader";


export default defineComponent({
	components: { LoginForm, LoginHeader, },
})

</script>


