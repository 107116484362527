import axios from "axios";
import { API_user_logout } from "./API-user";

const url = process.env.VUE_APP_API_URL_LQ + "insurance-companies";

/**
 *  Get company insurance info
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_get_insurance_company_info() {
  return axios
    .get(url)
    .then((r) => r)
    .catch((err) => {
      if (err.response.status === 401) API_user_logout();
    });
}
