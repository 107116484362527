<template>
  <div class="input-group">
    <div class="input-radio-wrap">
      <input
          type="radio"
          :value="value"
          :checked="inputValue === value"
          :id="`${name}_${value}`"
          :name="name"
          @input="$emit('input', $event.target.value)"
      >
      <label class="box" :for="`${name}_${value}`">
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    value: {
      required: true
    },
    inputValue: {
      required: true
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
  },
  emits: ['input']
})
</script>

<style scoped>

/* setting position to fixed for .box element*/

.input-group {
  margin-bottom: 8px;
  margin-top: 8px;
}

.box {
  position: relative;
}

.input-radio-wrap {
  margin-bottom: 20px;
}

.input-radio-wrap:last-child {
  margin: 0;
}

label {
  display: flex;
  align-content: center;
  align-items: center;
  height: 25px;
  padding-left: 37px;
}

.box:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  content: "";
  width: 25px;
  height: 25px;
  border: 1px solid black;
  border-radius: 4px;
  background: center;
}

input:checked + label.box:before {
  background: url("../../../plugins/form_config/assets/img/ic_radio-active.png") no-repeat center;
}

</style>