<template >
  <div class="bg2">
    <div class="station page-padd pt-25 content-container">
      <div class="list-title self-center d-flex h-center">
        <h3>Choose City</h3>
      </div>
      <div id="select_city">
        <div class="d-flex column h100">
          <ul>
            <li
              @click="onCityListItemClick(city.id, city.name)"
              :key="city.id"
              v-for="city in cities"
            >
              {{ city.name }}
            </li>
          </ul>
        </div>
      </div>
      <Transition>
        <base-loader
          v-if="loader_active"
          class="loader-on-block bg-white"
        />
      </Transition>
    </div>
  </div>
</template>
<script>
import { API_get_cities_list } from '@/API/API-station';
import { defineComponent, ref, onBeforeMount, inject } from 'vue'
import { useRouter } from 'vue-router';
import { usePatientProfilesStore } from '@/modules/patient-profiles/store/patient-profiles';
// import { API_get_stations_list } from '@/API/API-station';
export default defineComponent({
  setup() {
    const patientProfilesStore = usePatientProfilesStore()
    const alertMessage = inject('alertMessage');
    const loader_active = ref(true);
    const cities = ref([]);
    const router = useRouter()

    onBeforeMount(() => {
      API_get_cities_list()
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.list);
            cities.value = res.data.list;
            loader_active.value = false;
          }
        })
        .catch((err) => {
          alertMessage.value.show(err.response.data.title, 'danger', 'small')

        });
    })
    const onCityListItemClick = (city_id, city_name) => {
      patientProfilesStore.setStationsCityId(city_id)
      patientProfilesStore.setStationsCityName(city_name)
      router.push({ name: 'Stations' })
    }
    return { loader_active, cities, onCityListItemClick }
  }
})
</script>

<style scoped>
.list-title {
  margin-bottom: 20px;
  font-size: 20px;
}

.stations {
  padding-bottom: 50px;
}

.station {
  padding-top: 50px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.item-animation {
  opacity: 0;
  animation: show 0.3s forwards;
}

.select_city {
  justify-self: center;
  margin: 0 auto;
}

#select_city {
  max-width: 600px;
  margin: 0 auto;
  min-width: 400px;
}


@media (max-width: 500px) {

  #select_city {
    min-width: 320px;
  }
}


.station li {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.station a.location_item li {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.station .title {
  max-width: 80%;
}

.no-click {
  pointer-events: none;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
