<template>
  <div class="modal-overlay no-internet">
    <div class="content-wrapper">
      <div class="img-container">
<img :src="noInternetSvg">
      </div>
      <div class="text-container">
      <p class="title bold">Something went wrong!</p>
      <p class="text">Make sure wifi or cellular data is turned
        on and than try again.</p>
      </div>
      <!-- <base-button class="try-again-btn primary btn">Try again</base-button> -->

    </div>
  </div>
</template>
<script>
import {defineComponent} from "vue";
import noInternetSvg from '@/assets/img/icons/no-internet.svg'
export default defineComponent({
  setup() {

    return {noInternetSvg}
  }
})
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 120%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}
.no-internet {
  display: none;
}
.no-internet.active {
  display: block;
}
.content-wrapper {
  margin: 100px 20px;
  padding: 25px;
  background-color: #FFFFFF;
  line-height: 26px;
  border-radius: 12px;
  color: #000;
}

.img-container {
display: flex;
justify-content: center;
  align-items: center;
}

.text-container {
  margin: 25px 0 35px 0;
}

.title {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
}

.text {
  font-size: 14px;
  line-height: 26px;
}

.try-again-btn {
  width: 70%;
  margin: 0 auto;
}

</style>