import ui_config from "./ui_config";
import { useMeetStore } from "@/store/meet";

const optionsCreator = (onload) => {
  const meetStore = useMeetStore();
  const parentNode = "jitsi-meet-container";
  const newId = () => {
    return Date.now().toString(36) + Math.random().toString(36); // .toUpperCase()
  };
  return {
    jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImhlYWxlemVfY2hhdF9hZG1pbiIsInN1YiI6Im1lZXQuaGVhbGV6ZS5jb20iLCJyb29tIjoiKiJ9.8d2zrZXz5FsdNa4BuETrgncsGAZe3jV7qzb5kr_cI5I",
    roomName: meetStore.getRoomName || "vjitsi_" + newId(),
    width: "100%",
    height: "100%",
    parentNode: document.getElementById(parentNode),
    userInfo: {
      nickname: meetStore.nickname,
    },
    configOverwrite: {
      // tokenAuthUrl: 'https://jvillanueva-d1bc55af.localhost.run/station/test/jwt/{room}/{roleUpgrade}',
      enableUserRolesBasedOnToken: true, // role
      defaultLanguage: "en",
      disableDeepLinking: true,
      prejoinPageEnabled: false,
      localRecording: {
        enabled: false,
        format: "flac",
      },
    },
    interfaceConfigOverwrite: ui_config,
    onload: (e) => {
      onload(e);
    },
  };
};

export default optionsCreator;
