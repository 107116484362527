import axios from "axios"
import qs from "qs"
import { API_user_logout } from "./API-user"
import { useUserStore } from "@/modules/user/store/user"

/**
 *  set headers to axios interceptor
 */
axios.interceptors.request.use((config) => {
  const userStore = useUserStore()
  config.headers["Authorization"] = "Bearer " + userStore.token
  config.headers["content-type"] = "application/x-www-form-urlencoded;charset=utf-8"
  return config
})

const url = process.env.VUE_APP_API_URL_LQ

/**
 * Get station info by station id
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_get_station_info_qr(id) {
  return axios
    .post(url + "station/" + id, {})
    .then((r) => r)
    .catch((err) => {
      if (err.response.status === 401) API_user_logout()
    })
}
export function API_get_cities_list() {
  return axios.get(
    "https://labworq.com/api/station",
    qs.stringify({
      lat: 0,
      lng: 0,
    })
  )
}

export function API_get_stations_list(city_id) {
  return axios.get(
    `https://labworq.com/api/station/city/${city_id}`,
    qs.stringify({
      lat: 0,
      lng: 0,
    })
  )
}
