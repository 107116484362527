<template>
    <div class="trainers">
        <div class="content-container">
            <div class="header mb-30 mt-10">
                <div class="go-back"><a
                        href="#"
                        onclick="window.history.back()"
                    ></a><svg viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                        ></path>
                    </svg></div>
                <div class="title self-center d-flex h-center">
                    <h3>Trainers</h3>
                </div>
            </div>
            <div class="trainers__info">
                <div class="trainers__img ">
                    <img
                        :src="Man"
                        alt="man"
                    >
                </div>
                <h2
                    v-if="current_plan == ''"
                    class="trainers__info-title"
                >Elevate Your Life</h2>
                <h2
                    v-else
                    class="trainers__info-title"
                >
                    <span v-if="current_plan.subscribe && current_plan.subscribe_payed">
                        Your active plan
                    </span>
                    <span v-if="current_plan.subscribe && !current_plan.subscribe_payed">
                        You are subscribed to
                    </span>
                    {{ current_plan.title }}

                </h2>
                <p
                    v-if="current_plan.date_end !== ''"
                    class="expiration"
                >Expiration date <span>{{ current_plan.date_end }}</span></p>
                <span
                    v-if="current_plan == ''"
                    class="trainers__info-description"
                >
                    Discover the secrets of technology-powered fitness and nutrition assistance, combined with a deeply
                    compassionate, human touch. Imagine a world where your meals are specifically tailored based on your
                    unique DNA, and the best AI systems ever made help you to become healthy. Our plans offer a
                    transformative blend of technology, human care, and cultural understanding, curated just for you.
                    <span style="font-weight: 800;">It's not just health and fitness; it's a future for good! Join now and
                        bring in the new age of well-being</span>
                </span>
                <!-- 
                <span
                    v-else
                    class="trainers__info-description"
                >
                    Current plan text
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, esse sit aspernatur pariatur eligendi
                    illum. Unde, commodi beatae recusandae quis consequatur quasi, accusamus, iste consequuntur voluptatum
                    eius deserunt ipsum? Neque temporibus modi vitae voluptate veritatis sit eaque minus unde. Aut quasi
                    natus reprehenderit exercitationem fuga! Earum ipsam rem saepe! Fuga porro qui iste impedit numquam
                    maiores ab exercitationem et. Veritatis, quisquam porro error, labore itaque officia hic soluta possimus
                    nemo autem, quasi nihil! Perferendis et, deleniti inventore esse numquam autem dolore similique nobis
                    dicta reiciendis consequatur porro est voluptates id? Modi debitis molestias consequuntur commodi ex
                    cumque maxime, voluptatum possimus!
                </span> -->

                <div
                    v-if="current_plan !== ''"
                    class="trainers__info-list"
                    v-html="current_plan.description"
                ></div>
                <div class="btn__wrap">
                    <button
                        v-if="current_plan.subscribe && !current_plan.subscribe_payed"
                        @click="subscribe(current_plan.id)"
                        class="btn_pay btn card__btn "
                    >Pay {{ current_plan.price }} $</button>
                    <button
                        v-if="show_plans_btn"
                        @click="show_plans = !show_plans"
                        class="btn_pay btn card__btn show_plans-btn"
                    >Show all Plans</button>
                </div>


                <!-- <ul
                    class="trainers__info-list"
                    :style="StyleList"
                >
                    <li
                        :key="item.id"
                        v-for="item in info_list"
                    >
                        {{ item.name }}
                    </li>
                </ul> -->
            </div>

            <div
                v-if="show_plans"
                class="plan plan-wrap"
            >
                <div
                    :key="plan.id"
                    v-for="(plan, index) in plans"
                    class="plan__card card"
                    :class="{ 'card-popular': activeCard.current == index }"
                    @mouseover="activeCard.current = index"
                    @mouseleave="activeCard.current = activeCard.default"
                >
                    <div class="card__header">
                        <span class="card__plan-name">{{ plan.title }}</span>
                        <span class="card__plan-price">
                            <span>{{ plan.price }}</span> /month
                        </span>
                        <img
                            class="card__heade-icon"
                            :src="CardIcon"
                            alt=""
                        >
                    </div>
                    <div v-html="plan.description"></div>

                    <!-- <ul class="card__item-list">
                        <li
                            :key="item.id"
                            v-for="item in plan.planList"
                            class="card__list-item"
                        >
                            <img
                                class="card__list-item-icon"
                                :src="item.status ? CheckIcon : CancelIcon"
                                alt="icon"
                            >
                            <span class="card__list-item-name">{{ item.name }}</span>
                            <span class="card__list-item-value">{{ item.value }}</span>
                        </li>

                    </ul> -->

                    <button
                        v-if="!plan.subscribe"
                        :disabled="plan.block"
                        class="btn btn card__btn"
                        @click="!plan.block && subscribe(plan.id)"
                    >Subscription</button>
                </div>
            </div>
        </div>
    </div>

    <Transition>
        <base-loader
            v-if="loader_status"
            class="loader-on-block bg-white"
        />
    </Transition>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import Man from '@/assets/man.jpeg';
import CardIcon from '@/assets/fit-icon.png';
import CheckIcon from '@/assets/check-icon.png';
import CancelIcon from '@/assets/cancel-icon.png';
import axios from "axios";
import qs from "qs";

export default defineComponent({
    components: {},

    setup() {
        const url = process.env.VUE_APP_API_URL;
        const currentPatientProfileId = JSON.parse(localStorage.getItem('patientProfile')).currentPatientProfileId;
        const description = ref('');
        const loader_status = ref(true);
        const show_plans = ref(true);
        const show_plans_btn = ref(false);
        const plans = ref([]);
        const current_plan = ref('');

        const activeCard = ref({
            current: 1,
            default: 1,
        });

        onMounted(() => {
            axios
                .post(url + "subscribe/get_tariffs", qs.stringify({
                    patient_id: currentPatientProfileId,
                }))
                .then((res) => {
                    plans.value = res.data.response;
                    description.value = res.data.response[0].description;

                    res.data.response.forEach((element, index) => {
                        if (element.default) {
                            activeCard.value.current = index;
                            activeCard.value.default = index;
                        }

                        if (element.subscribe) {
                            current_plan.value = element;
                            show_plans.value = false;
                            show_plans_btn.value = true;
                        }
                    });

                    loader_status.value = false;
                });
        })

        function subscribe(id) {
            axios
                .post(url + "subscribe/add/" + id, qs.stringify({
                    patient_id: currentPatientProfileId,
                }))
                .then((res) => {
                    window.open(res.data.response.invoce_link);
                });
        }


        return {
            show_plans_btn,
            loader_status,
            show_plans,

            current_plan,
            subscribe,
            Man,
            CardIcon,
            CheckIcon,
            CancelIcon,
            plans,
            activeCard,
            description
        }
    }
})
</script>

<style>
.trainers {
    background: linear-gradient(191.57deg, rgba(255, 249, 239, .5) .51%, #87c8ee 99.77%);
    min-height: 100vh;
    padding-top: 40px;
    padding-bottom: 200px;
}

.trainers__info-list .card__item-list {
    position: relative;
    max-width: 760px;
    padding-top: 20px;
    list-style: decimal;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column dense;
    grid-template-rows: repeat(4, 1fr);
    padding-left: 20px;
    z-index: 2;
    font-size: 18px;
}

.trainers__info-list .card__list-item-value {
    color: black;
}

.trainers__img {
    position: absolute;
    right: -100px;
    top: 0;
    height: 100%;
}

.trainers__img img {
    opacity: 0.2;
}

.trainers__img::before {
    position: absolute;
    content: "";
    height: 200%;
    background-color: white;
    width: 400px;
    transform: rotate(21deg) translateX(-315px);
    z-index: 1;
}

.trainers__info {
    position: relative;
    overflow: hidden;
    background: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: 20px;
    padding: 40px;
}

.trainers__info-title {
    position: relative;
    font-size: 40px;
    margin-bottom: 16px;
    z-index: 2;
}

.trainers__info-title span {
    color: #22b573;
}

.trainers__info-description {
    position: relative;
    max-width: 760px;
    display: block;
    font-size: 18px;
    z-index: 2;
}

@media (max-width: 790px) {
    .trainers__img img {
        opacity: 0.1;
        height: 100%;
        object-fit: cover;
    }

    .trainers__info-title {
        font-size: 36px;
    }

    .trainers__info-description {
        font-size: 16px;
    }

    .trainers__info {
        padding: 20px;
    }
}

.plan-wrap {
    padding-top: 100px;
}

.card {
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    padding: 30px;
    background: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: 20px;
    border: 3px solid transparent;
    transition: 0.3s;
}

.plan-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.card__header {
    position: relative;
    display: grid;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #22b573;
}

.card__plan-name {
    font-size: 18px;
    font-weight: 800;
}

.card__plan-price span {
    font-size: 30px;
    font-weight: 800;
    color: #22b573;
}

.card__heade-icon {
    position: absolute;
    right: 0;
    top: 0;
}

.card__item-list {
    display: grid;
    grid-auto-rows: max-content;
    gap: 8px;
    margin-bottom: 16px;
}

.card__list-item {
    display: flex;
    gap: 8px;
    align-items: flex-start;
}

.card__list-item-value {
    font-weight: 700;
    font-size: 16px;
    color: #22b573;
}

.card__list-item-name {
    font-size: 16px;
}

.card__list-item-icon {
    width: 20px;
}

.card__btn {
    background-color: #22b573;
    color: white;
    border: 1px solid transparent;
    cursor: pointer;
}

.card__btn:not(:disabled):hover {
    background-color: transparent;
    color: #22b573;
    border-color: #22b573;
}

.card__btn:disabled {
    background-color: rgb(173, 173, 173);
}

.card-popular {
    border-color: #22b573;
}

.trainers__info .btn_pay {
    position: relative;
    background-color: #22b573;
    padding: 20px 100px;
    color: white;
    z-index: 2;
}

.btn__wrap {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
}

.card__btn.show_plans-btn {
    background-color: var(--color-primary);
}

.card__btn.show_plans-btn:hover {
    border-color: var(--color-primary);
    color: var(--color-primary);
}

.expiration {
    font-weight: 700;
    color: rgb(175, 175, 175);
    padding-left: 20px;
}


.expiration span {
    color: var(--color-primary);
}

@media (max-width: 677px) {
    .btn__wrap {
        flex-direction: column;
    }
}

@media (min-width: 800px) {
    .card-popular {
        transform: scale(1.07);
    }
}

@media (max-width: 1024px) {
    .plan-wrap {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 637px) {
    .plan-wrap {
        grid-template-columns: 1fr;
    }
}</style>
