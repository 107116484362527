import axios from "axios";
import qs from "qs";
import {API_user_logout} from "./API-user";
import {useUserStore} from "@/modules/user/store/user";

const url = process.env.VUE_APP_API_URL;

/**
 *  set headers to axios interceptor
 */
axios.interceptors.request.use(config => {
    const userStore = useUserStore()
    config.headers['Authorization'] = "Bearer " + userStore.token
    config.headers["content-type"] = "application/x-www-form-urlencoded;charset=utf-8"
    return config
})

/**
 * Delete patient profile
 * @param id
 * @param code
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_patient_profile_delete(id, code = '') {
    return axios
        .post(
            url + "patient/delete/" + id,
            qs.stringify({
                code,
            }),
        )
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

/**
 * Save or Update patient profile data
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_patient_profile_save(data) {
    return axios
        .post(url + "patient/save", qs.stringify(data))
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

/**
 * Get patient profile data by id
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_patient_profile_get(id) {
    return axios
        .post(
            url + `patient/${id}`,
            {}
        )
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

/**
 * Get list of profiles of current account
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_get_profiles_list() {
    return axios
        .post(url + "patient/list")
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

export function API_get_patient_insurance(patient_id) {

    console.log('some some some', patient_id);
    return axios
        .post(url + `patient/get_insurance/${patient_id}`)
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

export function API_set_patient_insurance(patient_id, data) {
    return axios
        .post(url + `patient/set_insurance/${patient_id}`, qs.stringify(data))
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}
