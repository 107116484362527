<template>
  <footer>
    <div class="footer-inner content-container">
      <!-- <div class="scanner">
        <router-link to="/scanner"></router-link>
        <img src="img/qr-code.svg" alt="">
      </div> -->
      <nav>
        <div class="inner-wrapper">
          <router-link to="/dashboard" class="item item-nav">
            <!-- <a data-href="dashboard.html"></a> -->
            <div class="img-wrapper">
              <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.0638 3.65957H15.4468V2.7234C15.4468 1.95745 15.1844 1.31206 14.6596 0.787234C14.1348 0.262411 13.4894 0 12.7234 0H9.06383C8.29787 0 7.65248 0.262411 7.12766 0.787234C6.60284 1.31206 6.34043 1.95745 6.34043 2.7234V3.65957H2.7234C1.95745 3.65957 1.31206 3.91489 0.787234 4.42553C0.262411 4.93617 0 5.58865 0 6.38298V10C0 10.5957 0.297872 10.8936 0.893617 10.8936V18.1702C0.893617 18.6525 1.07801 19.078 1.44681 19.4468C1.8156 19.8156 2.24113 20 2.7234 20H19.0638C19.5461 20 19.9716 19.8156 20.3404 19.4468C20.7092 19.078 20.8936 18.6525 20.8936 18.1702V10.8936C21.4894 10.8936 21.7872 10.5957 21.7872 10V6.38298C21.7872 5.58865 21.5248 4.93617 21 4.42553C20.4752 3.91489 19.8298 3.65957 19.0638 3.65957ZM8.17021 2.7234C8.17021 2.46809 8.25532 2.25532 8.42553 2.08511C8.59574 1.91489 8.80851 1.82979 9.06383 1.82979H12.7234C12.9787 1.82979 13.1915 1.91489 13.3617 2.08511C13.5319 2.25532 13.617 2.46809 13.617 2.7234V3.65957H8.17021V2.7234ZM2.7234 18.1702V10.8936H8.17021V13.617C8.17021 14.1277 8.34752 14.5603 8.70213 14.9149C9.05674 15.2695 9.48936 15.4468 10 15.4468H11.7872C12.2979 15.4468 12.7305 15.2695 13.0851 14.9149C13.4397 14.5603 13.617 14.1277 13.617 13.617V10.8936H19.0638V18.1702H2.7234ZM10 13.617V10.8936H11.7872V13.617H10ZM19.9574 9.10638H1.82979V6.38298C1.82979 5.75886 2.12766 5.44681 2.7234 5.44681H19.0638C19.6596 5.44681 19.9574 5.75886 19.9574 6.38298V9.10638Z"
                    fill="currentColor"/>
              </svg>
            </div>
            <p>Home</p>
          </router-link>
          <router-link to="/cities" class="item item-nav">
            <!-- <a data-href="locations.html"></a> -->
            <a id="loc-btn"></a>
            <div class="img-wrapper">
              <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 12C10.206 12 12 10.206 12 8C12 5.794 10.206 4 8 4C5.794 4 4 5.794 4 8C4 10.206 5.794 12 8 12ZM8 6C9.103 6 10 6.897 10 8C10 9.103 9.103 10 8 10C6.897 10 6 9.103 6 8C6 6.897 6.897 6 8 6Z"
                    fill="currentColor"/>
                <path
                    d="M7.42009 19.814C7.58934 19.9349 7.79211 19.9998 8.00009 19.9998C8.20806 19.9998 8.41084 19.9349 8.58009 19.814C8.88409 19.599 16.0291 14.44 16.0001 8C16.0001 3.589 12.4111 0 8.00009 0C3.58909 0 8.80377e-05 3.589 8.80377e-05 7.995C-0.028912 14.44 7.11609 19.599 7.42009 19.814ZM8.00009 2C11.3091 2 14.0001 4.691 14.0001 8.005C14.0211 12.443 9.61209 16.428 8.00009 17.735C6.38909 16.427 1.97909 12.441 2.00009 8C2.00009 4.691 4.69109 2 8.00009 2Z"
                    fill="currentColor"/>
              </svg>
            </div>
            <p>Stations</p>
          </router-link>
        </div>
        <router-link :to="'/center-list'" class="item item-nav">
            <!-- <a data-href="dashboard.html"></a> -->
            <div class="img-wrapper">
              <img :src="scheduleSvg" alt="">
            </div>
            <p>Schedule</p>
          </router-link>
        <div class="inner-wrapper anon-hide">
          <router-link
              :to="{name: 'MyApplications', params: {id: patientProfileStore.getCurrentPatientProfileId}}"
                       class="item item-nav">
            <a data-href="applications-list.html"></a>
            <div class="img-wrapper">
              <svg width="19" height="20" viewBox="0 0 19 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 13C8.41 13 8.81 12.97 9.21 12.94C9.4 12.18 9.72 11.46 10.16 10.83C9.47 10.94 8.74 11 8 11C5.58 11 3.3 10.4 2 9.45V6.64C3.47 7.47 5.61 8 8 8C10.39 8 12.53 7.47 14 6.64V8.19C14.5 8.07 15 8 15.55 8C15.7 8 15.85 8 16 8.03V4C16 1.79 12.42 0 8 0C3.58 0 0 1.79 0 4V14C0 16.21 3.59 18 8 18C8.66 18 9.31 17.96 9.92 17.88C9.57 17.29 9.31 16.64 9.16 15.94C8.79 16 8.41 16 8 16C4.13 16 2 14.5 2 14V11.77C3.61 12.55 5.72 13 8 13ZM8 2C11.87 2 14 3.5 14 4C14 4.5 11.87 6 8 6C4.13 6 2 4.5 2 4C2 3.5 4.13 2 8 2ZM19 14.5C19 15.32 18.75 16.08 18.33 16.71L17.24 15.62C17.41 15.28 17.5 14.9 17.5 14.5C17.5 13.837 17.2366 13.2011 16.7678 12.7322C16.2989 12.2634 15.663 12 15 12V13.5L12.75 11.25L15 9V10.5C17.21 10.5 19 12.29 19 14.5ZM15 15.5L17.25 17.75L15 20V18.5C12.79 18.5 11 16.71 11 14.5C11 13.68 11.25 12.92 11.67 12.29L12.76 13.38C12.59 13.72 12.5 14.1 12.5 14.5C12.5 15.163 12.7634 15.7989 13.2322 16.2678C13.7011 16.7366 14.337 17 15 17V15.5Z"
                    fill="currentColor"/>
              </svg>
            </div>
            <p>My Applications</p>
          </router-link>
          <div class="btn-container">
            <router-link to="/account" class="item item-nav">
              <a data-href="profile.html"></a>
              <div class="img-wrapper">
                <img src="img/avatar-1.png?v=0.01" alt="">
              </div>
              <p>My Account</p>
            </router-link>
          </div>

        </div>

        <!-- show if user anonymous -->
        <div class="inner-wrapper anon-show">
          <a data-href="login.html" class="btn h-100 btn-primary w100">Login</a>
        </div>
      </nav>
    </div>
  </footer>
</template>

<script>
import {defineComponent} from "vue";
import {usePatientProfilesStore} from "@/modules/patient-profiles/store/patient-profiles";
import scheduleSvg from "@/assets/img/icons/dashboard/schedule.svg"


export default defineComponent({
  setup() {
    const patientProfileStore = usePatientProfilesStore()
    return { patientProfileStore, scheduleSvg }
  }
})
</script>

<style scoped>
.btn-container {
  position: relative;

}

.select-current-patient-btn {

  position: absolute;
  bottom: 24px;
  left: 12px;
  width: 30px;
  height: 30px;
}


</style>
