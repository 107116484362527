import axios from "axios";
import {API_user_logout} from "./API-user";
import {useUserStore} from "@/modules/user/store/user";

const url = process.env.VUE_APP_API_URL;

/**
 *  set headers to axios interceptor
 */
axios.interceptors.request.use(config => {
    const userStore = useUserStore()
    config.headers['Authorization'] = "Bearer " + userStore.token
    config.headers["content-type"] = "application/x-www-form-urlencoded;charset=utf-8"
    return config
})

/**
 * Get FAQ data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_get_faq() {
    return axios
        .post(
            url + "faq",
            {}
        )
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}
