<template>
  <div class="welcome-wrapper ">
    <div class="content-container">
      <account-preview class="p-20 pt-80" />
      <HRADescription v-if="!patientProfileStore.getHasCurrentPatientHRA" />
      <HRAThanks v-else />
      <div class="btn-container">
        <base-button
          v-if="resultIsReady"
          @click="readResoult"
          class="padding primary"
          :class="{ active: resultIsReady }"
        >
          AI Health Feedback
        </base-button>
        <base-button
          v-else
          @click="getRecomendation(currentId, 1)"
          :class="{
            valid: !isHraValid
          }"
          class="padding primary"
        >
          {{ aiBtntext }}
        </base-button>
      </div>
      <div
        v-if="redResoult"
        class="adwices-block"
      >
        <div class="adwices_text">
          <div
            @click.prevent="readResoult"
            class="close__adwices"
          >
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
              ></path>
            </svg>
            Back to HRA
          </div>
          <h2 class="ai-form-title">AI Health Feedback</h2>
          <div v-html="resoultText">
          </div>
          <div
            @click.prevent="readResoult"
            class="close__adwices second"
          >
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
              ></path>
            </svg>
            Back to HRA
          </div>
        </div>
      </div>
      <div
        v-if="showConfirmModal"
        class="confirm_to_adwice"
      >
        <div class="adwice-modal">
          <p>You are going to send data from HRA for medical AI</p>
          <div class="adwice-btn-wrap">
            <base-button
              @click="sendToAI(currentId, 1)"
              class="padding primary"
            >
              Send
            </base-button>
            <base-button
              @click="showConfirmModal = false"
              class="padding primary-outlined"
            >
              Cancel
            </base-button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <Transition>
    <base-loader
      v-if="loader_active"
      class="loader-on-block bg-white"
    />
  </Transition>
</template>
<script>
import { defineComponent, onMounted, ref, inject } from "vue";
import AccountPreview from "@/modules/patient-profiles/components/AccountPreview";
import HRADescription from "@/modules/hra/components/HRADescription"
import HRAThanks from "@/modules/hra/components/HRAThanks"
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import { API_hra_recommendation } from "@/API/API-hra"

export default defineComponent({
  components: { AccountPreview, HRADescription, HRAThanks },
  props: {
    test: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    const patientProfileStore = usePatientProfilesStore();
    const isHraValid = ref(false);
    const hraSubmitet = ref(props.test)
    const currentId = JSON.parse(localStorage.getItem('patientProfile')).currentPatientProfileId;
    const patientProfiles = JSON.parse(localStorage.getItem('patientProfile')).patientProfiles;
    const alertMessage = inject("alertMessage");
    const aiBtntext = ref('Get advice from AI');
    const resultIsReady = ref(false)
    const redResoult = ref(false);
    const resoultText = ref('');
    const loader_active = ref(true)
    const showConfirmModal = ref(false);
    const isWAit = ref(false);

    onMounted(() => {
      console.log(props.test, "some props value");
      console.log(hraSubmitet.value === "1", "some props");
      getRecomendation(currentId, 0)
    })

    function readResoult() {
      redResoult.value = !redResoult.value;
    }

    function sendToAI(id, param) {
      API_hra_recommendation(id, param).then((req) => {
        if (req.data.response.status === 'request') {
          alertMessage.value.show('The request was sent successfully, it will take some time to process the request', 'success', 'small')
        } else {
          alertMessage.value.show('An error occurred, try again later', 'danger', 'small')
        }

        showConfirmModal.value = false;
      });
    }

    function getRecomendation(id, param) {

      if (param === 0) {
        isHraValid.value = patientProfiles.find(patient => patient.id === currentId)?.is_hra_valid;
        API_hra_recommendation(id, param).then((req) => {
          console.log(req.data.response.status);

          if (req.data.response.status === 'success') {
            resultIsReady.value = true;
            aiBtntext.value = 'See adwises'
            resoultText.value = req.data.response.recommendation.split('<br />').slice(2, req.data.response.recommendation.split('<br />').length - 1).join('<br /> <br />')

          }

          if (req.data.response.status === "wait") {
            isHraValid.value = false;
            isWAit.value = true;
            aiBtntext.value = 'See adwises'
          }

          if (req.data.response.status === "empty", props.test == "1") {
            isHraValid.value = true;
          }

          setTimeout(() => {
            loader_active.value = false;
          }, 500)
        }).catch(() => {
          setTimeout(() => {
            loader_active.value = false;
          }, 500)
        });
      }

      if (param === 1 && isHraValid.value) {
        showConfirmModal.value = true;
      }

      if (param === 1 && !isHraValid.value && !isWAit.value) {
        alertMessage.value.show('Please fill and submit HRA', 'info', 'small')
      }

      if (param === 1 && isWAit.value) {
        alertMessage.value.show('Please wait, your data is still under analysis', 'info', 'small')
      }
    }

    return { patientProfileStore, getRecomendation, isHraValid, hraSubmitet, currentId, aiBtntext, resultIsReady, readResoult, redResoult, resoultText, loader_active, sendToAI, showConfirmModal }
  }
})
</script>
<style scoped>
.welcome-wrapper {
  height: 100%;
  background: linear-gradient(191.57deg, #fff9ef80 0.51%, var(--color-blue) 99.77%);

}

.pt-80 {
  padding-top: 80px !important;
}

.btn-container {
  padding: 0 30px;
  margin: 0 auto;
}

.btn-container button {
  width: 50%;
  margin: 0 auto;
}

.p-20 {
  padding: 20px;
}

.valid.primary {
  background: grey;
}

.adwices-block,
.confirm_to_adwice {
  padding: 20px 20px 80px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.adwices-block .adwices_text {
  background-color: white;
  padding: 16px;
  max-width: 1300px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: white;
}

.close__adwices {
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content;
  padding-bottom: 24px;
  gap: 20px;
}

.close__adwices.second {
  padding-top: 24px;
  padding-bottom: 0;
}


.close__adwices svg {
  width: 24px;
  height: 24px;
}

.confirm_to_adwice {
  display: grid;
  align-items: center;
  justify-content: center;
}

.adwice-modal>p {
  padding-bottom: 20px;
  font-size: 20px;
}

.adwice-btn-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.adwice-modal {
  display: grid;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

.primary.active {
  background: var(--color-green);
}

.ai-form-title {
  text-align: center;
  font-weight: 600;
  margin-bottom: 24px;
  font-size: 18px;
  color: var(--color-primary);
}</style>