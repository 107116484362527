<template>
  <div class="thx-hra-container">
    <h1 class="thx-hra-title">Thank you</h1>
    <span class="thx-hra-text">Fill out the following health risk assessment to receive personalized health feedback from our AI Language Model integration (Powered by OpenAI)</span>
    <base-button class="btn primary mt-30" @click="onBackToDashboardBtnClick">Back to Dashboard</base-button>
    <base-button class="btn primary mt-30" @click="onBackToHRAFormBtnClick">Edit HRA</base-button>
  </div>
</template>
<script>
import {defineComponent} from "vue";
import {useRouter} from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter()

    /**
     * Go back to fill HRA form
     */
    const onBackToHRAFormBtnClick = () => {
      router.push({path: '/fill-hra'})
    }

    /**
     * Go to dashboard view
     */
    const onBackToDashboardBtnClick = () => {
      router.push({name: 'Dashboard'})
    }
    return {onBackToDashboardBtnClick, onBackToHRAFormBtnClick}
  }
})
</script>
<style scoped>
.thx-hra-container {
  text-align: center;
  margin: 0 auto 30px;
  padding: 0 30px ;
}


.thx-hra-title {
  font-weight: 700;
  display: block;
  margin-bottom: 30px;
  font-size: 22px;
}

.thx-hra-text {
  font-size: 18px;
}

button.primary {
  width: 50%;
  margin: 30px auto;
}
</style>