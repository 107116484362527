import LoginPage from "@/views/Login"
import SignupPage from "@/views/SignupPage"
import ConfirmContactsPage from "@/views/ConfirmContacts"
import DashboardPage from "@/views/Dashboard"
import AccountPage from "@/views/Account"
import MyProfilesPage from "@/views/MyProfiles"
import CitiesPage from "@/modules/stations/components/CitiesList.vue"
import StationsPage from "@/modules/stations/components/StationsList.vue"
import PaymentPage from "@/views/Payment"
// import QrScannerPage from "@/views/QrScannerPage"
import FaqPage from "@/views/Faq"
import MyApplications from "@/views/MyApplications"
import ApplicationDetails from "@/views/Application"
import InsurancePage from "@/views/Insurance"
import TheInsurance from "@/modules/insurance/views/TheInsurance"
import EditInsurance from "@/modules/insurance/views/EditInsurance"
import HraFormPage from "@/views/HRAFormPage"
import HRAWelcome from "@/modules/hra/components/HRAWelcome"
import HraForm from "@/modules/hra/components/HRAForm"
import TestCenterDetail from "@/modules/test-centers/TestCenterDetail"
import CentersList from "@/views/CentersList";
// import HRAThanks from "@/modules/hra/components/HRAThanks"
import { createWebHistory, createRouter } from "vue-router"
import routerGuard from "@/router/middleware/router-guard"
import Trainers from '@/views/Trainers'

// function redirect() {
//   let search = document.location.search.replace("?", "")
//
//   if (search !== "" && document.location.pathname !== search) {
//     return "/" + search
//   } else {
//     return "/dashboard"
//   }
// }

const routes = [
  { path: "/:catchAll(.*)", redirect: '/dashboard' },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/signup-:id-:edit",
    name: "Signup",
    component: SignupPage,
    props: true,
    meta: { authorizedOnly: true },
  },
  {
    path: "/confirm-contacts",
    name: "ConfirmContacts",
    component: ConfirmContactsPage,
    meta: { authorizedOnly: true },
  },

  {
    path: "/trainers",
    name: "Trainers",
    component: Trainers,
    meta: { authorizedOnly: true },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardPage,
    meta: { authorizedOnly: true },
  },
  {
    path: "/account",
    name: "Account",
    component: AccountPage,
    meta: { authorizedOnly: true },
  },

  {
    path: "/my-applications",
    name: "MyApplications",
    component: MyApplications,
    meta: { authorizedOnly: true },
  },

  {
    path: "/application-:application_id",
    name: "ApplicationDetails",
    component: ApplicationDetails,
    props: true,
    meta: { authorizedOnly: true },
  },
  {
    path: "/my-profiles",
    name: "MyProfiles",
    component: MyProfilesPage,
    meta: { authorizedOnly: true },
  },

  // TODO stvoryty page
  {
    path: "/payment",
    name: "Payment",
    component: PaymentPage,
    meta: { authorizedOnly: true },
  },
  {
    path: "/center-list",
    name: "CentersList",
    component: CentersList,
    meta: { authorizedOnly: true },
  },
  // {
  //   path: "/scanner",
  //   name: "Scanner",
  //   component: QrScannerPage,
  //   meta: { authorizedOnly: true },
  // },
  {
    path: "/faq",
    name: "Faq",
    component: FaqPage,
    meta: { authorizedOnly: true },
  },
  {
    path: "/hra-form",
    name: "HraFormPage",
    component: HraFormPage,
    meta: { authorizedOnly: true },
    children: [
      {
        path: "/welcome-hra",
        name: "HRAWelcome",
        props: true,
        component: HRAWelcome,
        meta: {
          authorizedOnly: true,
        },
      },
      {
        path: "/fill-hra",
        name: "HRAFrom",
        component: HraForm,
        meta: {
          authorizedOnly: true,
        },
      }
      /*,
      {
        path: "/thanks-hra",
        name: "HRAThanks",
        component: HRAThanks,
        meta: {
          authorizedOnly: true,
        },
      },*/
    ],
  },
  {
    path: "/cities",
    name: "Cities",
    component: CitiesPage,
    meta: { authorizedOnly: true },
  },
  {
    path: "/stations",
    name: "Stations",
    component: StationsPage,
    meta: { authorizedOnly: true },
  },
  {
    path: "/test-center",
    name: "TestCenterDetail",
    component: TestCenterDetail,
    props: true,
    meta: { authorizedOnly: true },
  },
  {
    path: "/insurance",
    name: "TheInsurance",
    component: InsurancePage,
    meta: { authorizedOnly: true },
    children: [
      {
        path: "/my-insurance",
        name: "Insurance",
        component: TheInsurance,
        meta: {
          authorizedOnly: true,
        },
      },
      {
        path: "/edit-insurance-:profile_id",
        name: "EditInsurance",
        component: EditInsurance,
        props: true,
        meta: {
          authorizedOnly: true,
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const sessionStorageSetHandler = function (event) {
  setTimeout(() => {
    // alert('event.key = ' + event.key + ' event.value = ' + event.value);
    if (event.key != 'handleOpen') return;

    router.push({ path: event.value })

    // router.replace('/' + event.value)
  }, 110)

};

document.addEventListener("handleOpen", sessionStorageSetHandler, false);

routerGuard(router)

export default router
