<template>
  <div class="container">
    <div class="self-center mb-20">
      <div class="logo self-center">
        <img src="assets/img/logoapp.png" alt="Healeze">
      </div>
      <span class="color-primary text-sm" style="white-space: nowrap">Labworq LLC company</span>
    </div>
  </div>
</template>
<script>
import {defineComponent} from "vue";


export default defineComponent({})
</script>
<style scoped>
.logo {
  width: 40%;
}
.logo img {
  display: block;
  margin: 0 auto;
  max-height: 60px;
}
</style>