import {defineStore} from "pinia";

export const useUserStore = defineStore("user", {
    state: () => ({
        name: "",
        email: "",
        day: "",
        month: "",
        year: "",
        id: "",
        phone: "",
        photo: "",
        sex: "",
        status_id: "",
        type_id: "",
        type_name: "",
        token: "",
    }),
    persist: true,
    getters: {
        isLoggedIn: (state) => (state.token && state.token !== ""),
        getUserDOB: (state) => state.day + "/" + state.month + "/" + state.year,
        getUserPhone: (state) => state.phone,
        getUserEmail: (state) => state.email,
        getUserSex: (state) => state.sex,
        getUserId: (state) => state.id
    },
    actions: {
    // methods for change state
        setUserData(data) {
            for (const [key, value] of Object.entries(data)) {
                this[key] = value
            }
        },
        setClearUserToken() {
            this.token = null
        },
    },
});
