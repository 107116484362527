<template>
  <div :class="active ? 'active' : ''" class="modal-confirm">
    <div @click="$emit('close')" class="overlay"></div>
    <div class="inner-wrapper text-center">
      <div class="mb-30 h3">
        {{ mode === "phone" ? "Enter new Phone" : "Enter new Email" }}
      </div>

      <div v-if="mode === 'phone'" class="input-group mb-20 w100">
        <label class="mb-8">
          <svg viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M20 15.5C18.8 15.5 17.5 15.3 16.4 14.9H16.1C15.8 14.9 15.6 15 15.4 15.2L13.2 17.4C10.4 15.9 8 13.6 6.6 10.8L8.8 8.6C9.1 8.3 9.2 7.9 9 7.6C8.7 6.5 8.5 5.2 8.5 4C8.5 3.5 8 3 7.5 3H4C3.5 3 3 3.5 3 4C3 13.4 10.6 21 20 21C20.5 21 21 20.5 21 20V16.5C21 16 20.5 15.5 20 15.5M5 5H6.5C6.6 5.9 6.8 6.8 7 7.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5M19 19C17.7 18.9 16.4 18.6 15.2 18.2L16.4 17C17.2 17.2 18.1 17.4 19 17.4V19M15 12H17A5 5 0 0 0 12 7V9A3 3 0 0 1 15 12M19 12H21C21 7 16.97 3 12 3V5C15.86 5 19 8.13 19 12Z"
            />
          </svg>
          Phone Number</label
        >
        <vue-tel-input
            :class="v$.phone.$error ? 'danger' : ''"
            @input="onInput"
            :props="'US'"
            class="input"
            :value="newData.data"
        ></vue-tel-input>
        <div v-if="v$.phone.$error" class="mt-5 color-red">
          {{ v$.phone.$errors[0].$message }}
        </div>
      </div>
      <div v-else class="input-group mb-20 w100">
        <label class="mb-8">
          <svg viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"
            />
          </svg>
          Email</label
        >
        <input
            v-model="newData.email"
            @input="codeSent = false"
            :class="v$.email.$error ? 'danger' : ''"
            class="input"
        />
        <div v-if="v$.email.$error" class="mt-5 color-red">
          {{ v$.email.$errors[0].$message }}
        </div>
      </div>

      <div v-if="codeSent" class="input-group text-left w100">
        <label class="mb-8">
          <svg viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M21.03 6.29L12 .64L2.97 6.29C2.39 6.64 2 7.27 2 8V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 7.27 21.61 6.64 21.03 6.29M20 18H4V10L12 15L20 10V18M12 13L4 8L12 3L20 8L12 13Z"
            />
          </svg>
          Enter code</label
        >
        <input
            v-model="newData.code"
            class="input"
            type="number"
            autocomplete="off"
            placeholder="Enter code"
        />
      </div>
      <div
          @click="submitForm"
          class="btn btn-primary w100 h-45px lh-lg btn-lg mt-30"
      >
        {{ !codeSent ? "Send Code" : "Confirm" }}
      </div>
    </div>
  </div>

  <base-loader v-if="loader_active" class="loader-on-block bg-white"></base-loader>
</template>

<script>
import {computed, defineComponent, inject, ref} from "vue";
import "vue3-tel-input/dist/vue3-tel-input.css";
import useValidate from "@vuelidate/core";
import {
  required,
  minLength,
  helpers,
  maxLength,
} from "@vuelidate/validators";
import {
  API_user_checkCode,
  API_user_getCode,
} from "@/API/API-user";
import {useUserStore} from "@/modules/user/store/user";

export default defineComponent({
  props: {
    mode: String,
    active: Boolean,
    redirect: Boolean,
  },
  emits: ["close"],
  setup(
      props,
      { emit }
  ) {
    const alertMessage = inject("alertMessage");
    const loader_active = ref(false);
    const codeSent = ref(false);
    let newData = ref({ phone: null, code: null })
    const userStore = useUserStore()

    const rules = computed(() => {
      return {
        phone: {
          required: helpers.withMessage(
              "Please enter your phone number",
              required
          ),
          minLength: minLength(10),
        },
        code: {
          required: helpers.withMessage(
              "Enter code from SMS you received",
              required
          ),
          minLength: minLength(4),
          maxLength: maxLength(4),
        },
        $validationGroups: {
          phone: ["phone", "code"],
        },
      };
    });

    const v$ = useValidate(rules, newData);

    /**
     * Send code to user
     * @param data
     */
    const sendCode = (data) => {
      loader_active.value = true;
      API_user_getCode(data)
          .then((res) => {
            if (res.data.status === "success") {
              alertMessage.value.show(res.data.title, "success");
              codeSent.value = true;
            } else {
              alertMessage.value.show(res.data.title, "success");
            }

            loader_active.value = false;
          })
          .catch(() => {
            loader_active.value = false;

            alertMessage.value.show("Something went wrong", "danger");
          });
    }


    /**
     * Submit confirm contacts form
     */
    const submitForm = () => {
      v$.value.$validate().then(() => {
        if (!codeSent.value) {
          if (!v$.value.phone.$error) {
            sendCode(newData.value)
          }
        } else {
          if (!v$.value.$validationGroups["phone"].$error) {
            loader_active.value = true
            API_user_checkCode(newData.value)
                .then(res => {
                  if (res.data.status === 'success') {
                    userStore.setUserData(res.data.response.user)
                    emit('close')
                  } else {
                    alertMessage.value.show(res.data.title, "danger");
                  }

                })
                .catch(e => {
                  console.log(e.message)
                })

            loader_active.value = false
          } else {
            alertMessage.value.show(
                "Enter the code you received in SMS",
                "danger"
            );
          }
        }
      })

    }

    function onInput(tel, phoneObject) {
      if (phoneObject?.number) newData.value.phone = phoneObject.number;
      codeSent.value = false;
    }

    return { loader_active, v$, onInput, submitForm, newData, codeSent, props };
  },
});
</script>

<style>
.modal-confirm {
  display: block;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-confirm .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.modal-confirm .inner-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  max-width: 100%;
  padding: 60px;
  border-radius: 30px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>