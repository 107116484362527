<template>
	<div class="modal-user-terms">
		<div
			class="content-wrap content-container"
			:class="{ noBtn: !btn }"
		>
			<h2 class="modal-user-terms__title">
				{{ title }}
				<div 	@click="close()" class="modal-user-close">
					<!-- <img
						@click="close()"
						:src="closeModalSvg"
						alt="close"
					> -->
				</div>
			</h2>
			<div
				v-html="modalText"
				class="modal-user-terms__text"
			>
			</div>

			<base-button
				v-if="btn"
				@click="close(title)"
				class="btn btn-primary mb-30 modal-btn"
			>
				I agree
			</base-button>
		</div>
	</div>
	<base-loader
		v-if="loader_active"
		class="fixed loader-on-block bg-white"
	></base-loader>
</template>

<script>
import closeModalSvg from '@/assets/img/icons/close-modal.svg';
import { API_user_terms } from '@/API/API-user';
import { ref, watchEffect } from 'vue';

export default {
	emits: ['close'],
	props: ['title', 'btn'],


	setup(props, context) {
		const modalText = ref('');
		const loader_active = ref(true);

		watchEffect(() => {
			let url = '';

			if (props.title === 'Privacy policy') {
				url = 'https://labworq.com/api/privacy';
			}

			if (props.title === 'Terms of use') {
				url = 'https://labworq.com/api/terms';

			}
			API_user_terms({}, url).then(response => {
				modalText.value = response.data;
				loader_active.value = false;
			}).catch((err) => {
				console.log(err);
				loader_active.value = false;
				close();
			})
		})

		function close(param) {
			if (param === 'Privacy policy') {
				context.emit('close', 'policy');
			} else if (param === 'Terms of use') {
				context.emit('close', 'terms');

			} else {
				context.emit('close', '');
			}
		}

		return {
			close,
			closeModalSvg,
			modalText,
			loader_active
		}
	}
}

</script>

<style>
.checkbox_wrap {
	display: flex;
	margin-bottom: 16px;
	flex-direction: column;
}

.check_box {
	display: flex;
	align-items: center;
	gap: 6px;
	margin-bottom: 6px;
	cursor: pointer;
}

.check_box div {
	padding: 10px;
	max-width: max-content;
	border-radius: 2px;
	border: 1px solid var(--color-primary);
}

.check_box.error div {
	border-color: red;
}

.check_box.active div {
	background-color: var(--color-primary);
	background-image: url('../modules/user/assets/check.svg');

	background-position: -50px;
}

.modal-user-terms {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1001;
	overflow: auto;
}

.content-wrap {
	background: white;
	margin: 0 auto;
	padding-bottom: 12px;
}

.btn-primary.modal-btn {
	width: 40%;
	margin: 0 auto;
}

.modal-user-close {
	position: absolute;
	right: 10px;
	top: 35px;
	padding: 20px;
}

.modal-user-close::after,
.modal-user-close::before {
	position: absolute;
	left: 0;
	transform-origin: center;
	content: '';
	width: 20px;
	height: 2px;
	background-color: rgb(192, 192, 192);
}

.modal-user-close::after {
	transform: rotate(-45deg);
}

.modal-user-close::before {
	transform: rotate(45deg);
}

.modal-user-terms__title {
	position: fixed;
	top: 0;
	max-width: 1168px;
	width: 100%;
	padding: 40px 0;
	background-color: white;
	text-align: center;
	font-size: 20px;
	font-weight: 700;
}

.modal-user-terms__text {
	padding: 110px 20px 12px;
	z-index: 2;
}

.modal-user-terms__text {}

.noBtn {
	padding-bottom: 50px;
}
</style>