<template>
  <div class="dash-bcg">
    <div class="content-container list-container p-relative overflow-scroll pt-25">
      <div id="dashboard">
        <div class="p-relative">
          <account-preview />
          <!-- <div class="register mb-30 guided-item" id="register-patient">
            <div class="inner-wrapper">
              <h2 class="mb-15">Test Registration</h2>
              <h6 class="mb-25">Fill out an application for covid testing</h6>
              <div @click="checkProfiles()" class="btn color3 w100">Let’s Start</div>
            </div>
          </div> -->
          <div class="links">
            <h4 class="light mb-20">What do you want to do?</h4>
            <div class="inner-wrapper">
              <!-- <div
                @click="checkProfiles()"
                class="item "
              >
                <div class="item-wrapp">
                  <div class="img-wrap covid">
                    <img
                      :src="covidSvg"
                      alt="results"
                    >
                  </div>
                  <h6 class="text-md covid-title">Fill out an application for covid testing</h6>
                  <h6 class="mb-25 text-md start-cd-text">Let’s Start</h6>
                </div>
              </div> -->
              <div class="item acc-img">
                <div class="item-wrapp">
                  <router-link to="/trainers"></router-link>
                  <div class="img-wrapper"><img
                      :src="CardIcon"
                      alt=""
                    ></div>
                  <div class="text-md">Trainers/Nutritionists</div>
                </div>
              </div>

              <div class="item">
                <div class="item-wrapp">
                  <router-link :to="'/center-list'"></router-link>
                  <div class="img-wrapper"><img
                      :src="scheduleSvg"
                      alt="schedule"
                    ></div>
                  <div class="text-md">Schedule</div>
                </div>
              </div>
              <div class="item">
                <div class="item-wrapp">
                  <router-link :to="'/welcome-hra'"></router-link>
                  <div class="img-wrapper"><img
                      src="img/icons/services-icon.png"
                      alt=""
                    ></div>
                  <div class="text-md">AI Health Feedback</div>
                </div>
              </div>
              <div class="item">
                <div
                  class="counter"
                  id="results-counter"
                ></div>
                <div class="item-wrapp">
                  <router-link to="/my-applications"></router-link>
                  <div class="img-wrapper"><img
                      :src="resultsSvg"
                      alt="results"
                    ></div>
                  <div class="text-md">My Results</div>
                </div>
              </div>
              <div class="item">
                <div class="item-wrapp">
                  <!-- <a data-href="locations.html"></a> -->
                  <router-link :to="{ name: 'Insurance' }"></router-link>
                  <div class="img-wrapper"><img
                      :src="insuranceSvg"
                      alt="insurance"
                    >
                  </div>
                  <div
                    class="insurance-text"
                    :class="patientProfileStore.getHasCurrentPatientInsured ? 'insured' : 'not-insured'"
                  >
                    {{ patientProfileStore.getHasCurrentPatientInsured ? 'Insured' : 'Not Insured' }}
                  </div>
                  <div class="text-md">Insurance</div>
                </div>
              </div>
              <div class="item">
                <div class="item-wrapp">
                  <router-link to="/faq"></router-link>
                  <div class="img-wrapper"><img
                      src="img/icons/faq-icon.png"
                      alt=""
                    ></div>
                  <div class="text-md">FAQ</div>
                </div>
              </div>
              <!-- <div class="item acc-img">
                <div class="item-wrapp">
                  <router-link to="/account"></router-link>
                  <div class="img-wrapper"><img
                      src="img/log-u.png"
                      alt=""
                    ></div>
                  <div class="text-md">My account</div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import AccountPreview from "@/modules/patient-profiles/components/AccountPreview";
// import {useStationsStore} from "@/store/stations";
import { useRouter } from "vue-router";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import hraSvg from "@/assets/img/icons/dashboard/hra.svg"
import resultsSvg from "@/assets/img/icons/dashboard/results.svg"
import scheduleSvg from "@/assets/img/icons/dashboard/schedule.svg"
import insuranceSvg from "@/assets/img/icons/dashboard/insurance.svg"
import covidSvg from "@/assets/img/icons/dashboard/covid.svg";
import CardIcon from '@/assets/fit-icon.png';
export default defineComponent({
  components: {
    AccountPreview,
  },
  setup() {
    const router = useRouter()
    // const stationsStore = useStationsStore()
    const patientProfileStore = usePatientProfilesStore()


    /**
     * Check if patient already has station ID push him to payment view or to scanner if it hasn't
     */
    function checkProfiles() {
      // if (stationsStore.getStationId) {
      //   router.push({name: "Payment"})
      // } else {
      router.push({ name: "Scanner" })
      // }
    }
    if (document.location.search == '?scanner') {
      router.push({ name: "Scanner" });
    }

    onMounted(() => {
      console.log('Mounted');
      patientProfileStore.updatePatientProfilesData();
    })



    return { checkProfiles, patientProfileStore, hraSvg, insuranceSvg, resultsSvg, scheduleSvg, covidSvg, CardIcon };
  },
});
</script>


<style scoped>
.insurance-text {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: #fff;
  font-size: 10px;
  padding: 5px 35px;
  width: 100%;
  transform: translateX(-36%) translate(-30px, 30px) rotate(-45deg);
}

@media (max-width: 700px) {
  .insurance-text {
    transform: translateX(-36%) translate(7px, 14px) rotate(-45deg);
  }

}

.text-md.covid-title {
  margin-bottom: 8px;
}

.text-md.covid-title~.text-md {
  margin: 0;
}

.insured {
  background-color: #22B573;
}

.not-insured {
  background-color: #FF5151;
}

.img-wrap.covid {
  width: 40px;
}

.item {
  overflow: hidden;
  position: relative;
}

.bold {
  font-weight: 600;
}

.top-nav .top-bar {
  justify-content: center;
}

.bg2 {
  min-height: 100vh;
  padding-bottom: 50px;
}

.list-container {
  padding-top: 50px;
}

.text h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1em;
}


.inner-wrapper h2,
.inner-wrapper h6 {
  line-height: 1em;
}

.inner-wrapper h2 {
  font-size: 1.4em;
  font-weight: 500;
}

.inner-wrapper h6 {
  font-size: 0.75em;
  font-weight: 400;
  margin-bottom: 25px;
}

.btn {
  padding: 5px 15px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.links .inner-wrapper .item .text-md {
  padding: 0 15px;
  text-align: center;
}

.start-cd-text {
  color: var(--color-green);
  font-weight: 700 !important;
}

.dash-bcg {
  background: linear-gradient(191.57deg,
      rgba(255, 249, 239, 0.5) 0.51%,
      #87c8ee 99.77%);
}

.list-container {
  height: 100vh;
}

.inner-wrapper {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  height: 100%;
  padding-bottom: 60px;
}

#dashboard {
  height: calc(100vh - 110px);
}

#dashboard .p-relative {
  height: 100%;
}

@media (max-width: 700px) {
  .img-wrapper {
    width: 40px;
  }

}


.links {
  height: calc(100% - 110px);
}

.acc-img img {
  height: 60px;
}
</style>