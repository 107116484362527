import { defineStore } from "pinia";

export const useMeetStore = defineStore("meet", {
    state: () => ({
        isRinging: false,
        isMeetInited: false,
        roomName: "",
        nickname: "",
        avatar: "",
    }),
    persist: {
        storage: localStorage,
        paths: ['roomName'],
    },
    getters: {
        // get computed properties from state
    },
    // methods from change state
    actions: {
        setRoomSettings(name) {
            this.roomName = name;
            this.isMeetInited = true;
        },
    },
});
