import {usePatientProfilesStore} from "@/modules/patient-profiles/store/patient-profiles";
import {useUserStore} from "@/modules/user/store/user";

export function useSockets(callbacks) {
    const userStore = useUserStore()
    const serverUrl = process.env.VUE_APP_SOCKET;
    const token = userStore.token;

    const Socket = {
        conn: null,
        isClosed: true,
        connect: () => {
            let connection = new WebSocket(serverUrl)
            Socket.isClosed = false

            connection.onopen = () => {
                Socket.conn = connection;
                console.log(connection.readyState);
                Socket.init();

                connection.onmessage = (e) => {
                    callbacks.onmessage(e)
                };
                connection.onclose = () => {
                    if(!Socket.isClosed)
                        Socket.reconnect()
                }

                setInterval(() => {
                    Socket.sessionUpdate()
                }, 60000)
            }
        },
        reconnect: () => {
            console.log('RECONNECTING');
            Socket.connect()
        },
        disconnect: () => {
            Socket.conn.close()
            Socket.isClosed = true
        },
        init: () => {
            const patientProfileStore = usePatientProfilesStore()
            Socket.conn.send(
                JSON.stringify({
                    action: "init",
                    data: { key: token, patient_id: patientProfileStore.getCurrentPatientProfileId },
                })
            );
        },
        callRequest: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "call_request",
                    data: {},
                })
            );
        },
        callUpdate: (state_val) => {
            Socket.conn.send(
                JSON.stringify({
                    action: "call_update",
                    data: state_val,
                })
            );
        },
        callClose: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "call_close",
                    data: {},
                })
            );
        },
        sessionUpdate: () => {
            Socket.conn.send(
                JSON.stringify({
                    action: "working_time",
                    data: {},
                })
            );
        },
    }

    return Socket
}