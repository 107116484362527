import { createApp } from 'vue'
import router from './router/index.js'
import VueTelInput from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import Vue3TouchEvents from "vue3-touch-events";
import pinia from "@/utils/pinia"
import baseComponentsArray from "@/components/base/base-components-array";
import App from './App.vue'
import telInputOptions from "@/services/tel-input-options";
const vm = createApp(App)





baseComponentsArray.forEach(({ name, component }) => {
    vm.component(name, component);
});
vm.use(VueTelInput, telInputOptions);
vm.use(Vue3TouchEvents);
vm.use(router);
vm.use(pinia);
vm.mount("#app");