<template>
  <div class="hra" v-if="v$.value">
    <section v-for="section in form_config" :key="section">
      <div class="row">
        <template v-for="col in section" :key="col">
          <div :class="col.class">
            <!--html level section-->
            <div v-html="col.html"></div>

            <div class="row">
              <template v-for="group in col.input_groups" :key="group">
                <div :class="group.class">
                  <!--html level input group-->
                  <div v-html="group.html"></div>

                  <div class="row">
                    <template
                        v-for="inputRow in group.inputs"
                        :key="inputRow"
                    >
                      <div :class="inputRow.class">
                        <div class="row">
                          <template v-for="input in inputRow" :key="input">
                            <div :class="input.class">
                              <template v-if="
                                    input.type === 'text' ||
                                    input.type === 'email'">
                                <InputText
                                    @input="(val) => { state[input.name] = val; this.$emit('input', state, input.name) }"
                                    :name="input.name"
                                    :input-value="state[input.name]"
                                    :is-error="v$.value[input.name]?.$error"
                                    :placeholder="input.placeholder"
                                />

                                <div v-if="v$.value[input.name]?.$error" class="color-red">
                                  {{ v$.value[input.name].$errors[0].$message }}
                                </div>
                              </template>

                              <template v-else-if="input.type === 'radio'">
                                <div>
                                  <InputRadio
                                      @input="(val) => { state[input.name] = val; this.$emit('input', state, input.name) }"
                                      :value="input.value"
                                      :input-value="state[input.name]"
                                      :name="input.name"
                                      :label="input.label"
                                  />
                                </div>
                              </template>

                              <template v-else-if="input.type === 'checkbox'">
                                <InputCheckBox
                                    @update:checked="(val) => { state[input.name] = val; this.$emit('input', state, input.name) }"
                                    :checked="state[input.name]"
                                    :name="input.name"
                                    :label="input.label"
                                />
                                <div v-if="v$.value[input.name]?.$error" class="color-red">
                                  {{ v$.value[input.name].$errors[0].$message }}
                                </div>
                              </template>

                              <template v-else-if="input.type === 'tel'">
                                <InputText
                                    @input="(val) => { state[input.name] = val; this.$emit('input', state, input.name) }"
                                    :name="input.name"
                                    :input-value="state[input.name]"
                                    :is-error="v$.value[input.name]?.$error"
                                    :placeholder="input.placeholder"
                                />
                                <div v-if="v$.value[input.name]?.$error" class="color-red">
                                  {{ v$.value[input.name].$errors[0].$message }}
                                </div>
                              </template>

                              <template v-else>
                                <div class="input-group">
                                  <h5 style="color: red">ERROR</h5>
                                </div>
                              </template>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <!-- TODO -->
                <div v-if="group['inputs'][0][0]?.type === 'radio'">
                  <div v-if="v$.value[group['inputs'][0][0]?.name]?.$error" class="color-red">
                    {{ v$.value[group["inputs"][0][0]?.name].$errors[0].$message }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </section>
    <div class="btn btn-primary mt-30 mb-90" @click="submitForm()">Submit</div>
  </div>
</template>

<script>
import {defineComponent, ref, watchEffect} from "vue";
import useValidate from "@vuelidate/core";
import InputText from "@/plugins/form_config/components/InputText";
import InputRadio from "@/plugins/form_config/components/InputRadio";
import InputCheckBox from "@/plugins/form_config/components/InputCheckBox";
import parseValidationRules from '@/plugins/form_config/utils/JSONFormParseValidationRules'

export default defineComponent({
  components: { InputText, InputRadio, InputCheckBox },
  props: {
    config: Object,
    state_updated: Object
  },
  emits: ['input', 'submit'],
  setup(props, { emit }) {
    const state = ref({
      // 'first-name': '',
      // 'last-name': '',
      //  ...
      //  ...
    })
    const rules = ref({
      // 'first-name': { required },
      // 'last-name': { required, minLength: minLength(2) },
      //  ...
      //  ...
    })

    const form_config = ref({})

    const v$ = ref({});

    watchEffect(() => {
      if (props.state_updated) {
        state.value = props.state_updated
        rules.value = parseValidationRules(props.config.validators)
        form_config.value = props.config.config

        setTimeout(() => {
          v$.value = useValidate(rules, state)
        }, 500)

      }
    })

    function submitForm() {
      v$.value.value.$validate().then(() => {
        emit('submit', v$.value);

        if (v$.value.value.$error && v$.value.value.$errors[0]) {
            document.querySelector(`input[name=${v$.value.value.$errors[0].$property}]`).scrollIntoView({
              block: "center",
              behavior: "smooth"
            })
        }
      });
    }

    return { state, form_config, v$, submitForm }
  }
})

</script>

<style>

</style>