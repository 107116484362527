<template>
	<div class=" bg2">
		<div class="content-container page-padd pt-25">
			<div class="p-relative">
				<div class="form">

					<div class="header mb-30">
						<router-link
							:to="{ name: 'Account' }"
							class="go-back"
						>
							<svg viewBox="0 0 24 24">
								<path
									fill="currentColor"
									d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
								/>
							</svg>
						</router-link>
						<div class="createText self-center d-flex h-center">
							<h4>
								<span v-if="!isEmptyProfile">Edit</span><span v-if="isEmptyProfile">Create</span> Patient
								Profile
							</h4>
						</div>
						<img
							v-if="!isEmptyProfile"
							@click="isDeletePatientProfileConfirmModal = true"
							class="delete-icon"
							title="Delete patient"
							src="img/trash.png"
							alt="delete patient"
						>
					</div>

					<h5>Patient name</h5>
					<div class="row-container gx-2 mb-15">
						<div
							class="m-r-10"
							style="width: calc(33% - 5px);"
						>
							<!-- <label class="mb-8">
                <svg viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z"/>
                </svg>
                First Name</label> -->
							<!-- <label for="firstname" class="mb-8">First Name</label> -->
							<input
								v-model="state['first_name']"
								name="first_name"
								:class="v$['first_name'].$error ? 'danger' : ''"
								id="firstname"
								class="input"
								type="text"
								autocomplete="off"
								placeholder="First Name"
							/>
						</div>
						<div
							class="input-group text-left m-r-10"
							style="width: calc(33% - 5px);"
						>
							<!-- <label for="miname" class="mb-8">Mid</label> -->
							<input
								v-model="state['mid_name']"
								name="mid_name"
								:class="v$['mid_name'].$error ? 'danger' : ''"
								class="input"
								type="text"
								autocomplete="off"
								placeholder="Mid"
							/>
						</div>
						<div
							class="input-group text-left"
							style="width: calc(33% - 5px);"
						>
							<!-- <label for="lastname" class="mb-8">Last Name</label> -->
							<input
								v-model="state['last_name']"
								name="last_name"
								:class="v$['last_name'].$error ? 'danger' : ''"
								id="lastname"
								class="input"
								type="text"
								autocomplete="off"
								placeholder="Last Name"
							/>
						</div>
					</div>

					<h5>Date of Birth</h5>
					<div class="row-container  gx-2 mb-20">
						<div
							class="input-group text-left m-r-10"
							style="width: calc(33% - 5px);"
						>
							<Select2
								class="select2"
								name="dob_month"
								:class="v$['dob_month'].$error ? 'danger' : ''"
								v-model="state['dob_month']"
								:options="months"
								:settings="{
									minimumResultsForSearch: 20,
									width: '100%',
									placeholder: 'Months',
								}"
							/>
						</div>
						<div
							class="input-group text-left m-r-10"
							style="width: calc(33% - 5px);"
						>
							<!-- <label for="day" class="mb-8">
                      day</label> -->
							<input
								v-model="state['dob_day']"
								name="dob_day"
								:class="v$['dob_day'].$error ? 'danger' : ''"
								required
								data-mask="##"
								class="masked input profile_dob_day"
								id="day"
								type="number"
								autocomplete="off"
								placeholder="day"
							/>
						</div>
						<div
							class="input-group text-left"
							style="width: calc(33% - 5px);"
						>
							<!-- <label for="year" class="mb-8">
                      year</label> -->
							<input
								v-model="state['dob_year']"
								name="dob_year"
								:class="v$['dob_year'].$error ? 'danger' : ''"
								required
								data-mask="####"
								class="masked input profile_dob_year"
								id="year"
								type="number"
								autocomplete="off"
								placeholder="year"
							/>
						</div>
					</div>

					<h5>Sex (based on anatomy at birth)</h5>
					<div
						class="row-container h-start mb-20"
						style="padding: 0 10px; flex-direction: column; align-items: flex-start"
					>
						<div class="input-group radio">
							<input
								v-model="state['sex']"
								name="sex"
								:class="v$['sex'].$error ? 'danger' : ''"
								data-required="required"
								id="Male"
								type="radio"
								value="Male"
							/>
							<label for="Male">Male</label>
						</div>
						<div class="input-group radio">
							<input
								v-model="state['sex']"
								name="sex"
								:class="v$['sex'].$error ? 'danger' : ''"
								data-required="required"
								id="Female"
								type="radio"
								value="Female"
							/>
							<label for="Female">Female</label>
						</div>
					</div>

					<div class="mb-15">
						<h5>Address (domicile)</h5>
						<div class="input-group text-left street-block">
							<Select2
								class="select2"
								name="address_country"
								:class="v$['address_country'].$error ? 'danger' : ''"
								v-model="state['address_country']"
								:options="countries"
								:settings="{
									minimumResultsForSearch: 20,
									width: '100%',
									placeholder: 'Countries',
								}"
							/>
						</div>
					</div>

					<div class="gx-2 mb-15 row-container str-container">
						<div class="input-group text-left w100">
							<vue-google-autocomplete
								ref="autocomplete"
								id="autocomplete"
								classname="input"
								placeholder="Address Street"
								:country="['us']"
								name="address_street"
								:value="state['address_street']"
								:class="v$['address_street'].$error ? 'danger' : ''"
								v-on:placechanged="onAutocomplete"
								v-model="state['address_street']"
								:enable-geolocation="true"
								@keydown.enter.prevent
							>
							</vue-google-autocomplete>
						</div>
					</div>

					<div class="gx-2 mb-15 row-container">

						<div
							class="input-group text-left m-r-10"
							style="width: calc(33% - 5px);"
						>
							<!-- <label for="addressCity" class="mb-8">
                      City</label> -->
							<input
								v-model="state['address_city']"
								name="address_city"
								:class="v$['address_city'].$error ?
									'danger' : ''"
								required
								id="addressCity"
								class="input required profile_address_city"
								type="text"
								autocomplete="off"
								placeholder="City"
							/>
						</div>
						<div
							class="input-group text-left m-r-10"
							style="width: calc(33% - 5px);"
						>
							<!-- <label for="state" class="mb-8">
                      State</label> -->
							<input
								v-if="state['address_country'] !== 'US'"
								v-model="state['address_state']"
								name="address_state"
								:class="v$['address_state'].$error ? 'danger' : ''"
								required
								id="state"
								class="input required profile_address_state"
								type="text"
								autocomplete="off"
								placeholder="State"
							/>
							<div
								v-else
								class="input-group text-left"
							>
								<Select2
									class="select2"
									name="dob_month"
									:class="v$['dob_month'].$error ? 'danger' : ''"
									v-model="state['address_state']"
									:options="stateList"
									:settings="{
										minimumResultsForSearch: 20,
										width: '100%',
										placeholder: 'State',
									}"
								/>
							</div>
						</div>
						<div
							class="input-group col-4 text-left"
							style="width: calc(33% - 5px);"
						>
							<!-- <label for="zip" class="mb-8">
                      Zip Code</label> -->
							<input
								v-model="state['address_zip']"
								name="address_zip"
								:class="v$['address_zip'].$error ? 'danger' : ''"
								required
								id="zip"
								class="input required profile_address_zip"
								type="number"
								autocomplete="off"
								placeholder="Zip Code"
							/>
						</div>
					</div>

					<!--        <div class="mb-15">-->
					<!--          <div class="input-group text-left ">-->
					<!--            &lt;!&ndash; <label for="addressStreet" class="mb-8">-->
					<!--                      Address Street</label> &ndash;&gt;-->
					<!--            <input-->
					<!--                v-model="state['address_street']"-->
					<!--                :class="v$['address_street'].$error ? 'danger' : ''"-->
					<!--                required-->
					<!--                id="addressStreet"-->
					<!--                class="input required profile_address_street"-->
					<!--                type="text"-->
					<!--                placeholder="Address Street"-->
					<!--            />-->
					<!--          </div>-->
					<!--        </div>-->

					<div class="row-container gx-2 mb-15">
						<div
							class="input-group text-left m-r-10"
							style="width: calc(50% - 5px);"
						>
							<!-- <label for="building" class="mb-8">
                      Building</label> -->
							<input
								v-model="state['address_building']"
								name="address_building"
								:class="v$['address_building'].$error ? 'danger' : ''"
								required
								id="building"
								class="input required profile_address_building"
								type="text"
								autocomplete="off"
								placeholder="Building"
							/>
						</div>
						<div
							class="input-group text-left "
							style="width: calc(50% - 5px);"
						>
							<!-- <label for="apt" class="mb-8">
                      Apt</label> -->
							<input
								v-model="state['address_apt']"
								name="address_apt"
								:class="v$['address_apt'].$error ? 'danger' : ''"
								id="apt"
								class="input"
								type="text"
								autocomplete="off"
								placeholder="Apt"
							/>
						</div>
					</div>

					<hr />

					<h3 class="patientText mb-20 mt-30 text-center">Patient Insurance</h3>
					<div class="alert alert-info">
						<h4><img
								src="img/icons/alert-icon.svg"
								alt=""
							/>Disclaimer!</h4>
						<p>
							If client has valid insurance, insurance number must be provided to
							recieve results.
						</p>
					</div>

					<h5>Do you have active US medical insurance?</h5>

					<div class="d-flex mb-20">
						<div class="input-group radio">
							<input
								v-model="state['insured']"
								name="insured"
								:class="v$['insured']?.$error ? 'danger' : ''"
								id="Insured"
								type="radio"
								:value="true"
							/>
							<label
								for="Insured"
								data-tab="tab_Insured"
							>Yes</label>
						</div>
						<div class="input-group radio">
							<input
								v-model="state['insured']"
								name="insured"
								:class="v$['insured']?.$error ? 'danger' : ''"
								id="UnInsured"
								type="radio"
								:value="false"
							/>
							<label
								for="UnInsured"
								data-tab="tab_UnInsured"
							>No</label>
						</div>
					</div>

					<div class="mb-60">
						<div
							v-if="state['insured'] === true"
							class="tab"
							id="tab_Insured"
						>
							<div class="mb-15 tab-insured">
								<div
									class="btn w-180 btn-xs btn-warning text-sm"
									style="display: none"
									id="showList"
								>
									Show list of companies
								</div>
								<div
									@click="[
										(enterManually = !enterManually),
										(state['insurance_company_id'] = ''),
										(state['insurance_company_manually'] = ''),
									]
										"
									class="btn btn-xs w-180 btn-warning text-sm"
									id="showManually"
								>
									<template v-if="enterManually === false">
										Enter manually
									</template>
									<template v-if="enterManually === true">
										Show list of companies
									</template>
								</div>
							</div>
							<div class="row-container gx-2 mb-30 company-wrap">
								<div class="input-group text-left">
									<div
										id="insuranceCompanySelect"
										class="insuranceCompanySelect"
									>
										<template v-if="enterManually === false">
											<Select2
												class="select2"
												name="insurance_company_id"
												:class="v$['insurance_company_id'].$error ? 'danger' : ''"
												v-model="state['insurance_company_id']"
												:options="companiesData"
												:settings="{
													minimumResultsForSearch: 20,
													width: '100%',
													placeholder: 'Select company',
													dropdownCssClass: 'test',
													dropdownAutoWidth: false,
												}"
											/>
										</template>

										<template v-if="enterManually === true">
											<input
												type="text"
												name="insurance_company_manually"
												v-model="state['insurance_company_manually']"
												:class="v$['insurance_company_id'].$error ? 'danger' : ''"
												class="input profile_insurance_company_manually"
												id="insuranceCompany"
												placeholder="Insurance Company"
											/>
										</template>
									</div>
								</div>
								<div class="input-group text-left">
									<!-- <label for="insured-mn" class="mb-8">
                            Member Number</label> -->
									<input
										v-model="state['insurance_member_number']"
										name="insurance_member_number"
										:class="v$['insurance_member_number'].$error ? 'danger' : ''"
										required
										id="insured-mn"
										class="input"
										type="text"
										placeholder="Member Number"
									/>
								</div>
							</div>
							<h5>Please provide Insurance & ID Card Photo</h5>
							<div class="row-container mb-30">
								<div
									class="input-group photo w50 m-r-10"
									:class="[
										state['insurance_front'].length ? 'success' : '',
										v$['insurance_front'].$error ? 'danger' : '',
									]"
								>
									<input
										type="file"
										accept="image/*"
										@change="uploadPhoto($event, 'insurance_front'); state.insurance_front_change = true; testFunc()"
										name="insurance_front"
										id="insurance_front"
										style="display: none"
									/>
									<label
										class="mb-8 centred"
										for="insurance_front"
									>
										<span class="add-photo-text">
											Front photo
										</span>
										<img
											:src="state['insurance_front']"
											alt=""
										/>
									</label>
								</div>

								<div
									class="input-group photo w50"
									:class="[
										state['insurance_back'].length ? 'success' : '',
										v$['insurance_back'].$error ? 'danger' : '',
									]
										"
								>
									<input
										type="file"
										accept="image/*"
										@change="uploadPhoto($event, 'insurance_back'); state.insurance_back_change = true; testFunc()"
										id="insurance_back"
										name="insurance_back"
										style="display: none"
									/>
									<label
										class="mb-8"
										for="insurance_back"
									>
										<span class="add-photo-text">
											Back photo
										</span>
										<img
											:src="state['insurance_back']"
											alt=""
										/>
									</label>
								</div>
							</div>
						</div>

						<div v-if="state['insured'] === false">
							<h5>How would you like to pay?</h5>
							<div class="mb-20">
								<div
									v-if="state['address_country'] === 'US'"
									class="input-group radio"
								>
									<input
										v-model="state['documents']"
										name="documents"
										:class="v$['documents'].$error ? 'danger' : ''"
										id="documents_yes"
										:value="true"
										type="radio"
									/>
									<label
										for="documents_yes"
										data-tab="tab_documents_yes"
									>
										Free (with USA passport or valid USA Government issued ID)
									</label>
								</div>
								<div class="input-group radio">
									<input
										v-model="state['documents']"
										name="documents"
										:class="v$['documents'].$error ? 'danger' : ''"
										id="documents_no"
										:value="false"
										type="radio"
									/>
									<label for="documents_no">
										Pay by credit card
									</label>
								</div>

								<div
									v-if="v$['documents'].$error"
									class="mt-1 color-red"
								>{{ v$['documents'].$errors[0].$message }}</div>
							</div>

							<div
								v-if="state['documents'] === true && state['address_country'] === 'US'"
								class="tabs-container"
							>
								<div
									class="tab"
									id="tab_documents_yes"
								>
									<div class="validation-or">
										<h5>Driver License</h5>
										<div class="row gx-2 mb-30">
											<div class="input-group text-left col-5">
												<!-- <label for="license-s" class="mb-8">State</label> -->
												<input
													v-model="state['documents_dl_state']"
													name="documents_dl_state"
													:class="v$['documents_dl_state'].$error ? 'danger' : ''"
													placeholder="State"
													id="license-s"
													class="input"
													type="text"
												/>
											</div>
											<div class="input-group text-left col-7">
												<!-- <label for="license-n" class="mb-8">Number</label> -->
												<input
													v-model="state['documents_dl_number']"
													name="documents_dl_number"
													:class="v$['documents_dl_number'].$error ? 'danger' : ''"
													placeholder="Number"
													id="license-n"
													class="input"
													type="text"
												/>
											</div>
										</div>
									</div>
									<div class="validation-or">
										<h5>or</h5>
										<div class="row mb-15">
											<div class="input-group text-left val w100">
												<label
													for="uninsured-country"
													class="mb-8"
												>
													Country</label>
												<Select2
													class="select2"
													:class="v$['documents_country'].$error ? 'danger' : ''"
													v-model="state['documents_country']"
													name="documents_country"
													:options="countries"
													:settings="{
														minimumResultsForSearch: 20,
														width: '100%',
														placeholder: 'Countries',
													}
														"
												/>
											</div>
										</div>
										<div class="mb-30">
											<div class="input-group text-left w100">
												<!-- <label for="uninsured-passport" class="mb-8">Passport</label> -->
												<input
													v-model="state['documents_passport']"
													:class="v$['documents_passport'].$error ? 'danger' : ''"
													name="documents_passport"
													required
													placeholder="Passport"
													id="uninsured-passport"
													class="input required profile_passport"
													type="text"
												/>
											</div>
										</div>
									</div>
									<h5>Please provide document photo</h5>
									<div class="row-container gx-2 mb-30">
										<div
											class="input-group photo"
											style="width: calc(50% - 10px)"
											:class="[
												state['documents_front'].length ? 'success' : '',
												v$['documents_front'].$error ? 'danger' : '',
											]
												"
										>
											<input
												type="file"
												accept="image/*"
												@change="uploadPhoto($event, 'documents_front'); state.documents_front_change = true"
												name="documents_front"
												id="documents_front"
												style="display: none"
											/>
											<label
												class="mb-8"
												for="documents_front"
											>
												Front photo
												<img
													:src="state['documents_front']"
													alt=""
												/>
											</label>
										</div>
										<div
											class="input-group photo"
											style="width: calc(50% - 10px)"
											:class="[
												state['documents_back'].length ? 'success' : '',
												v$['documents_back'].$error ? 'danger' : '',
											]
												"
										>
											<input
												type="file"
												accept="image/*"
												@change="uploadPhoto($event, 'documents_back'); state.documents_back_change = true"
												id="documents_back"
												name="documents_back"
												style="display: none"
											/>
											<label
												class="mb-8"
												for="documents_back"
											>
												Back photo
												<img
													:src="state['documents_back']"
													alt=""
												/>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- <div
					v-if="isEmptyProfile"
					class="input-group checkbox mb-30"
				>
					<div class="d-flex align-content-center">
						<input
							v-model="state['terms']"
							:class="v$.accept?.$error ? 'danger' : ''"
							id="accept_terms"
							name="terms"
							class="input"
							type="checkbox"
						>
						<label
							for="accept_terms"
							class="for_checkbox"
						>I agree to all
							<a
								onclick=" cordova.InAppBrowser.open('https://labworq.com/terms', '_blank', 'location=yes') "
								class="link-1"
								target="_blank"
							> Terms &
								Condition</a></label>
					</div>

					<div
						v-if="v$['terms'].$error"
						class="mt-1 color-red"
					>{{ v$['terms'].$errors[0].$message }}</div>
				</div> -->
					<div
						v-if="edit === ' '"
						class="checkbox_wrap"
					>
						<div
							@click="showModal('Terms of use')"
							class="check_box"
							:class="{ active: termsStatus }"
						>
							<div></div>
							<span>Agree with terms of use</span>
						</div>
						<div
							:class="{ active: privacyStatus }"
							@click="showModal('Privacy policy')"
							class="check_box"
						>
							<div></div>
							<span>Agree with privacy policy</span>
						</div>
					</div>
					<base-button
						@click="submitForm()"
						class="btn btn-primary mb-30"
						:class="v$.$error ? 'disabled' : ''"
					>Save
					</base-button>

					<hr>

					<!-- <base-button
						v-if="!isEmptyProfile"
						@click="isDeletePatientProfileConfirmModal = true"
						class="btn danger-outlined mt-30 w100 mt-10"
						:class="v$.$error ? 'disabled' : ''"
					>Delete Patient 1
					</base-button> -->
				</div>
			</div>
		</div>
	</div>
	<div
		class="modal-confirm"
		v-if="isDeletePatientProfileConfirmModal"
	>
		<div class="overlay">
			<div class="delete-patient-modal z-index">
				<div class="confirm-delete-patient-profile ">
					<p class="delete-patient-title">Delete patient </p>
					<p class="delete-patient-title">{{ patientProfilesStore.getCurrentPatientProfileFullName }}?</p>
					<p class="delete-patient-text with-icon">
						<img
							class="svg-icon"
							:src="blankSvg"
							alt="icon"
						>
						{{ patientProfilesStore.getHasCurrentPatientInsured ? 'Insured' : 'Not insured' }}
					</p>
					<p class="delete-patient-text with-icon">
						<img
							class="svg-icon"
							:src="locationSvg"
							alt="icon"
						>
						{{ patientProfilesStore.getCurrentPatientAddress }}
					</p>
					<p class="delete-patient-text red">Please confirm your action.</p>
					<p class="delete-patient-text">This action can’t be canceled. Do you really want to be deleted?</p>
					<input
						v-if="isCodeSend"
						v-model="code"
						placeholder="Code"
						class="code-input input required profile_dl_number mt-10"
						type="text"
					/>
					<div class="btns-container mt-20">
						<base-button
							:disabled="code.length !== 4"
							v-if="isCodeSend"
							class="btn mr-10 danger"
							@click="onDeletePatientProfileClick(code)"
						>
							{{ isCodeSend ? 'Delete' : 'Get code' }}
						</base-button>

						<base-button
							v-else
							class="btn mr-10 primary-outlined"
							@click="onDeletePatientProfileClick(code)"
						>
							{{ isCodeSend ? 'Delete' : 'Get code' }}
						</base-button>
						<base-button
							class="btn primary"
							@click="onCancelDeletePatientBtnClick"
						>Cancel</base-button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<Transition>
		<base-loader
			v-if="loader_active"
			class="fixed loader-on-block bg-white"
		></base-loader>
	</Transition>
	<TermsModal
		:btn="true"
		@close="showModal"
		v-if="termsModalStatus"
		:title="modalTitle"
	/>
</template>

<script>
import {
	computed,
	defineComponent,
	ref,
	onMounted,
	inject,
	watchEffect,
	onBeforeMount
} from "vue";
import "vue3-tel-input/dist/vue3-tel-input.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import "@/assets/reset.css";
import useValidate from "@vuelidate/core";
import { API_user_logout } from "@/API/API-user";
import TermsModal from '@/components/TermsModal.vue';
import {
	required,
	minLength,
	minValue,
	maxValue,
	helpers,
} from "@vuelidate/validators";
import { countries } from "@/services/countriesList.js";
import { months } from "@/modules/user/services/monthsList.js";
import { stateList } from "@/modules/user/services/stateList.js";
import Select2 from "vue3-select2-component";
import {
	API_get_profiles_list,
	API_patient_profile_delete,
	API_patient_profile_get,
	API_patient_profile_save
} from "@/API/API-profiles";
import { API_get_insurance_company_info } from "@/API/API-insurance-company";
import { useRouter } from "vue-router";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import blankSvg from '@/assets/img/icons/blank.svg'
import locationSvg from '@/assets/img/icons/location.svg'

export default defineComponent({
	components: { Select2, VueGoogleAutocomplete, TermsModal },

	props: {
		id: String,
		edit: {
			type: String,
			default: ''
		}
	},

	setup(props) {
		console.log('props signup 1:', props)

		const code = ref('')
		const loader_active = ref(false);
		const alertMessage = inject("alertMessage");
		const patientProfilesStore = usePatientProfilesStore()
		const router = useRouter();
		const selectedProfilePatient = ref({});
		const enterManually = ref(false);
		const isEmptyProfile = ref(true);
		console.log('patientProfilesStore:', patientProfilesStore.currentPatientProfileId);
		console.log('props id:', props.id)

		if (props.id > 0 && props.id !== patientProfilesStore.currentPatientProfileId) {
			router.push({ path: '/dashboard' })
		}

		if (props.id > 0) {
			isEmptyProfile.value = false;
		} else {
			isEmptyProfile.value = true;
		}
		const companiesData = ref([
			{
				id: String,
				text: String,
			},
		]);
		const state = ref({
			id: props.id,
			first_name: "",
			mid_name: "",
			last_name: "",

			dob_day: "",
			dob_month: "01",
			dob_year: "",
			sex: "",

			address_country: "US",
			address_city: "",
			address_state: "",
			address_zip: "",
			address_street: "",
			address_building: "",
			address_apt: "",
			insured: null,

			insurance_company_id: "",
			insurance_company_manually: "",
			insurance_company_name: "",
			insurance_member_number: "",
			insurance_front: "",
			insurance_back: "",
			insurance_front_change: false,
			insurance_back_change: false,

			documents: null,
			documents_dl_state: "",
			documents_dl_number: "",
			documents_passport: "",
			documents_country: "US",
			documents_front: "",
			documents_back: "",
			documents_front_change: false,
			documents_back_change: false,
			terms: false
		});
		const rules = computed(() => {
			return {
				first_name: {
					required: helpers.withMessage("Please enter your name", required),
					minLength: minLength(2),
				},
				mid_name: {},
				last_name: { required, minLength: minLength(2) },
				address_country: { required },
				address_city: { required, minLength: minLength(2) },
				address_state: {},
				address_zip: { required, minLength: minLength(3) },
				address_street: { required, minLength: minLength(3) },
				address_building: { minLength: minLength(0) },
				address_apt: { minLength: minLength(0) },
				dob_day: {
					required,
					minLength: minLength(1),
					minValue: minValue(1),
					maxValue: maxValue(31),
				},
				dob_month: { required },
				dob_year: {
					required,
					minLength: minLength(4),
					minValue: minValue(1920),
					maxValue: maxValue(new Date().getFullYear()),
				},
				sex: { required },
				insured: { required },
				documents: { required },
				insurance_company_id: {},
				insurance_company_manually: {},
				insurance_member_number: { minLength: minLength(3) },
				insurance_front: { minLength: minLength(3) },
				insurance_back: { minLength: minLength(3) },
				documents_dl_state: { minLength: minLength(3) },
				documents_dl_number: { minLength: minLength(3) },
				documents_country: { minLength: minLength(2) },
				documents_passport: { minLength: minLength(2) },
				documents_front: { minLength: minLength(3) },
				documents_back: { minLength: minLength(3) },
				terms: { required },
				$validationGroups: {
					baseData: [
						"first_name",
						"last_name",
						"address_country",
						"address_city",
						"address_state",
						"address_zip",
						"address_street",
						"address_building",
						"address_apt",
						"dob_day",
						"dob_month",
						"dob_year",
						"sex",
						"insured",
					],
					insured: [
						"insured",
						"insurance_member_number",
						"insurance_front",
						"insurance_back",
					],
					driverLicense: [
						"documents",
						"documents_dl_state",
						"documents_dl_number",
						"documents_front",
						"documents_back",
					],
					passport: [
						"documents",
						"documents_country",
						"documents_passport",
						"documents_documents_front",
						"documents_documents_back",
					],
				},
			};
		});
		const v$ = useValidate(rules, state);
		const isDeletePatientProfileConfirmModal = ref(false)
		const isDeletePatientProfileModalOpen = ref(false)
		const isCodeSend = ref(false);

		const autocomplete = ref({});

		// const modalText = ['', ''];
		const termsStatus = ref(false);
		const privacyStatus = ref(false);

		const modalTitle = ref('');
		const termsModalStatus = ref(false);

		function showModal(params) {
			modalTitle.value = params;

			if (params === 'terms') {
				console.log(params);
				termsStatus.value = true;
			}
			if (params === 'policy') {
				console.log(params);
				privacyStatus.value = true;
			}

			if (termsStatus.value && privacyStatus.value) {
				state.value['terms'] = true;
			}

			termsModalStatus.value = !termsModalStatus.value;

		}


		/**
		 * Delete patient profile
		 * @param id
		 * @param code
		 */
		const onDeletePatientProfileClick = (code) => {
			loader_active.value = true
			if (code.length > 0) {
				API_patient_profile_delete(patientProfilesStore.getCurrentPatientProfileId, code)
					.then((res) => {
						if (res.data.status === "success") {
							API_get_profiles_list().then(res => {
								patientProfilesStore.updatePatientProfilesData(res.data.patients)
								if (res.data.patients.length > 0) {
									patientProfilesStore.setCurrentPatientProfileId(res.data.patients[0].id)
									router.replace({ path: "/account" });
								} else {
									API_user_logout()
								}

								alertMessage.value.show('Patient profile deleted', "success");
							})
						} else {
							loader_active.value = false
							alertMessage.value.show(res.data.title, "danger");
						}

					})
			} else {
				API_patient_profile_delete(patientProfilesStore.getCurrentPatientProfileId)
					.then((res) => {
						if (res.data.status === "success") {
							alertMessage.value.show(res.data.title, "success");
							isCodeSend.value = true
						} else {
							alertMessage.value.show(res.data.title, "danger");
						}

						loader_active.value = false
					})
			}
		}

		function testFunc() {
			console.log('state = = = = ', state.value);
		}

		function sendState() {
			if (state.value['terms'] === false) {
				console.log(document.querySelectorAll('.check_box'));
				if (!document.querySelectorAll('.check_box')[0].classList.contains('active')) {
					document.querySelectorAll('.check_box')[0].classList.add('error');
					console.log(document.querySelectorAll('.check_box')[0].classList);
				}

				if (!document.querySelectorAll('.check_box')[1].classList.contains('active')) {
					console.log(document.querySelectorAll('.check_box')[1].classList);

					document.querySelectorAll('.check_box')[1].classList.add('error');
				}

				return
			}

			loader_active.value = true;

			API_patient_profile_save(state.value).then((res) => {
				console.log(res);
				if (res.data.status === "success") {
					patientProfilesStore.updatePatientProfilesData(res.data.response.patients)
					patientProfilesStore.setCurrentPatientProfileId(res.data.response.currentPatientProfileId)

					if (props.id === "0") {
						router.replace({ path: "Dashboard" });
						loader_active.value = false;
						// Опції маршрутизатора...




					} else {
						router.push({ path: "Account" });
						loader_active.value = false;

					}
				} else {
					alertMessage.value.show(res.data.title, "success");
					loader_active.value = false;

				}
			});
		}

		watchEffect(() => {
			if (state.value["address_country"]) {
				state.value["dl_state"] = "";
				state.value["dl_number"] = "";
				state.value["documents_front"] = "";
				state.value["documents_back"] = "";
				state.value["country"] = "";
				state.value["passport"] = "";
			}

			if (state.value["address_country"] !== "US") {
				state.value["documents"] = false;
			}

			if (enterManually.value) {
				state.value.insurance_company_name = '';
			} else {
				state.value.insurance_company_name = companiesData.value.find(item => item.id == state.value.insurance_company_id)?.text;
			}

		});

		onMounted(() => {
			setTimeout(() => {
				let pacContainer = document.querySelector('.pac-container')
				let streetBlock = document.querySelector('.str-container')
				streetBlock.appendChild(pacContainer);
				loader_active.value = false;
			}, 2000)
		})



		function submitForm() {
			// TODO: don't send all state, just needed info

			// checks all inputs
			v$.value.$validate().then(() => {
				if (!v$.value.$validationGroups["baseData"].$error && (!isEmptyProfile.value || !v$.value["terms"].$error)) {
					if (state.value.insured) {
						if (!v$.value.$validationGroups["insured"].$error && (!v$.value["insurance_company_id"].$error || !v$.value["insurance_company_manually"].$error)) {
							sendState();
						} else {
							alertMessage.value.show(`Validation failed. Some of fields are invalid`, "danger");
						}
					} else {
						if (state.value.documents === true) {
							console.log(state.value.documents);

							if (!v$.value.$validationGroups["driverLicense"].$error) {
								sendState();
							} else if (!v$.value.$validationGroups["passport"].$error) {
								sendState();
							} else {
								alertMessage.value.show(`Validation failed. Some of fields are invalid`, "danger");
							}
						} else {
							sendState();
						}
					}
				}
				console.log(
					'ERROR',
					// v$.value.$errors[0],
					// v$.value,
					v$.value.$errors[0].$property
				);
				if (v$.value.$error && v$.value.$errors[0] && document.querySelector(`input[name=${v$.value.$errors[0].$property}]`) !== null) {
					document.querySelector(`input[name=${v$.value.$errors[0].$property}]`).scrollIntoView({
						block: "center",
						behavior: "smooth"
					})
				}
			});
		}

		function getCompanyInfo() {
			API_get_insurance_company_info()
				.then((res) => {
					companiesData.value = res.data.map((item) => {
						return {
							id: item.id,
							text: item.name,
						};
					});
				})
				.catch((err) => {
					console.log("err", err);
				});
		}

		function getPatientProfileData(id) {
			API_patient_profile_get(id)
				.then((res) => {
					if (res.data.response.insurance_company_manually !== '') {
						enterManually.value = true;
					}
					if (id > 0) {
						selectedProfilePatient.value = res.data.response;

						Object.entries(res.data.response).forEach(([key, value]) => {
							if (state.value[key] !== undefined) {

								state.value[key] = value
							}

						})
						// for (const key in selectedProfilePatient.value[0]) {
						//   if (key === "country" || key === "address_country") {
						//     state.value[key] =
						//         selectedProfilePatient.value[0][key].toUpperCase();
						//   } else {
						//     state.value[key] = selectedProfilePatient.value[0][key];
						//   }
						// }
					}

				})
				.catch((err) => {
					console.log("error: ", err);
				});
		}

		const onCancelDeletePatientBtnClick = () => {
			code.value = ''
			isDeletePatientProfileConfirmModal.value = false
		}

		onBeforeMount(() => {
			console.log('onBeforeMount', state.value);
			loader_active.value = true;
		})

		onMounted(() => {

			getCompanyInfo();
			if (props.id > 0) {
				getPatientProfileData(props.id);
			} else {
				loader_active.value = false;

			}
		});

		function uploadPhoto(input, state_key) {
			const file = input.target.files[0];
			const reader = new FileReader();

			let rawImg;
			reader.onloadend = () => {
				rawImg = reader.result;

				state.value[state_key] = rawImg;
			};
			reader.readAsDataURL(file);
		}

		function onAutocomplete(event) {
			console.log(event)
			state.value.address_state = event.administrative_area_level_1
			state.value.address_city = event.locality
			state.value.address_building = event.street_number
			state.value.address_street = event.route
			state.value.address_zip = event.postal_code
		}

		function test() {
			console.log('test work');
		}

		return {
			termsStatus,
			privacyStatus,
			modalTitle,
			showModal,
			termsModalStatus,
			test,
			autocomplete,
			isEmptyProfile,
			state,
			v$,
			countries,
			months,
			stateList,
			companiesData,
			submitForm,
			selectedProfilePatient,
			uploadPhoto,
			enterManually,
			loader_active,
			isDeletePatientProfileConfirmModal,
			isDeletePatientProfileModalOpen,
			onDeletePatientProfileClick,
			onAutocomplete,
			code,
			isCodeSend,
			patientProfilesStore,
			blankSvg,
			locationSvg,
			onCancelDeletePatientBtnClick,
			testFunc
		};
	},
});
</script>


<style scoped>
.code-input {
	margin-top: 10px;
}

.with-icon {
	display: flex;
	margin-top: 15px;
}

.svg-icon {
	margin-right: 10px;
}

.red {
	color: #FF5151;
	display: block;
	margin-top: 30px;
	line-height: 28px;
}

.mr-10 {
	margin-right: 10px;
}

.btns-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
}

.mt-20 {
	margin-top: 20px;
}

.confirm-delete-patient-profile {
	margin: 80px auto;
	padding: 20px;
	background-color: #fff;
	/* height: 65vh; */
	border-radius: 12px;
	max-width: 500px;
}

.delete-patient-title {
	font-size: 20px;
	font-weight: 500;
}

.delete-patient-text {
	font-size: 14px;
}

.add-photo-text {
	display: inline-block;
}

.centred {
	display: flex;
	align-items: center;
	justify-content: center;
}

.row-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.m-r-10 {
	margin-right: 10px !important;
}


.fixed {
	position: fixed !important;
}

.createText {
	font-size: 24px;
	font-weight: 500;
	padding-left: 20px;
}

.page-padd {
	padding-bottom: 100px;
	min-height: 100%;
	/* background: linear-gradient(191.57deg,
			rgba(255, 249, 239, 0.5) 0.51%,
			#87c8ee 99.77%); */
}

.form input {
	background: #fff;
	border: none;
	padding: 0 10px;
	font-size: 13px;
}


.form input:focus,
.form select:focus {
	outline: none !important;
	border-color: #87c8ee;
	box-shadow: 0 0 10px #87c8ee;
}

.form .input-group:not(.checkbox):not(.radio):not(.photo) label {
	position: absolute;
	top: 16px;
	left: 10px;
	font-size: 14px;
	color: var(--color-lightgray);
	font-weight: 400;
	transition: all 0.3s;
}

.page-padd {
	min-height: 100%;
	/* background: linear-gradient(191.57deg,
			rgba(255, 249, 239, 0.5) 0.51%,
			#87c8ee 99.77%); */
}

.form.container {
	padding-bottom: 15px;
}

.form input,
.form select {
	background: #fff;
	border: none;
	padding: 0 10px;
	font-size: 13px;
}

.form input:focus,
.form select:focus {
	outline: none !important;
	border-color: #87c8ee;
	box-shadow: 0 0 10px #87c8ee;
}

.form .input-group:not(.checkbox):not(.radio):not(.photo) label {
	position: absolute;
	top: 16px;
	left: 10px;
	font-size: 14px;
	color: var(--color-lightgray);
	font-weight: 400;
	transition: all 0.3s;
}

.form input[type="text"].danger,
.form input[type="number"].danger,
.form input[type="tel"].danger,
.form input[type="email"].danger,
.form input[type="date"].danger,
.form input[type="datetime-local"].danger,
input.danger,
.tabs-container input.danger {
	border: 1px solid;
	border-color: #d41e1e;
	animation: danger-border 2.5s forwards;
}

.alert.alert-info {
	background: #555555;
	color: #fff;
}

.alert {
	border-radius: var(--radius);
	padding: 15px;
	margin-bottom: 15px;
	color: #fff;
}

.alert * {
	color: #fff;
	vertical-align: middle;
}

.alert p {
	font-size: 12px;
}

.alert h4 {
	display: grid;
	grid-template-columns: repeat(2, auto);
	justify-content: start;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1em;
}

.alert h4 img {
	height: 18px;
	width: auto;
	margin-right: 10px;
	vertical-align: middle;
}

.patientText {
	font-size: 20px;
	font-weight: 500;
}

.submitForm {
	width: 180px;
	font-size: 14px;
}

.btn {
	cursor: pointer;
	width: 50%;
	margin: 0 auto;
}

.btn.btn-warning {
	background-color: #f59c1a;
	height: 30px;
	line-height: 30px;
	font-size: 12px;
	padding: 5px 15px;
	color: #fff;
	border: none;
	cursor: pointer;
	width: 180px;
}

.btn.btn-warning:hover {
	background-color: #e29018;
}

.pac-container {
	background: red !important;
}

.str-container {
	position: relative;
}

.test .select2-results {
	display: none !important;
	background-color: red !important;
}

.company-wrap {
	display: grid;
	gap: 20px;
	justify-content: inherit;
}

.tab-insured {
	display: flex;
	justify-content: right;
}

.overlay {
	overflow: auto;
}

.btn:disabled.danger {
	opacity: 0.5;
}

.header {
	padding-top: 30px;
}

.delete-icon {
	width: 24px;
	height: 24px;
	cursor: pointer;
}
</style>	