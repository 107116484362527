<template>
  <div class="bg2 page-padd pt-25">
      <div class="p-relative">

          <div class="labworq">
              <div class="logo"><img src="img/logoapp.png" alt="LabWorq"></div>
          </div>

          <div class="stationInfo alert alert-info" id="station_name">
              <a class="card-link" data-href="scanner.html">

                    <div class="card">
                      
                      <h2 class="card-text">{{ station_data.code }} {{ station_data.name }}</h2>
                      <router-link class="a-edit" to="/scanner">
                        <base-button class="edit">Change</base-button>
                      </router-link>

                    </div>

              </a>
              <input required type="hidden" name="station_code" id="station_code">
              <!--// input for station code-->
          </div>

          <div class="patient-profiles-list">
              <div class="inner-wrapper text-center">
                  <h4 class="mb-20 title">Create application as:</h4>

                  <!-- !!!! -->
                  <div v-if="user_data?.profiles.length > 0" class="patient-profiles-list">
                      <div class="inner-wrapper text-center"></div>

                      <template
                          v-for="profile in user_data?.profiles.slice().reverse()"
                          v-bind:key="profile?.id">
                        <base-button
                          @click="[profile_id = profile?.id, modal_confirm = true, setSelectedUser(profile?.id)]"
                          class="profilePatient item btn btn-secondary btn-sm mb-10 color-main">
                          <img class="hoverOff" src="img/icons/account-edit-icon-blue.svg" alt="icon">
                          <img class="hoverOn" src="img/icons/account-edit-icon.svg" alt="icon">
                          {{profile?.name + ' ' + profile?.dob}}
                        </base-button>
                      </template>

                  </div>
                  <!-- !!!! -->

                  <div class="newBut radius-top-2 mb-15">
                    <router-link :to="{ name: 'ProfileEdit', params: { id: '0' } }" class="item btn btn-secondary btn-sm mb-10 color-main">
                      <span class="plus">+</span>New
                    </router-link>
                  </div>

                  <div v-if="modal_confirm" class="modal-confirm">
                    <div @click="modal_confirm = false" class="overlay"></div>
                    <div class="inner-wrapper text-center">
                        <h3 class="mb-15 bold color-main text-xl name">{{selected_user?.name}}</h3>
                        <div class="text mb-20 color-main dob">{{selected_user?.dob + ', ' + selected_user?.sex}}</div>
                        <ul class="type-1 text-left w100">
                            <li><img src="img/icons/location-icon.svg" alt=""><span>{{selected_user?.address_apt + ', ' + selected_user?.address_street + ', ' + selected_user?.address_city + ', ' + selected_user?.address_state + ', ' + selected_user?.address_zip + ', ' + selected_user?.address_country}}</span></li>
                            
                            <template  v-if="selected_user?.insured == 'Insured'">
                              <li>
                                <img src="img/icons/file-document-check-outline.svg" alt="insured">
                                <span class="color-green">By Insurance</span>
                              </li>
                            </template>
                        
                            <!-- TODO: ADD v-if = && $profile->documents_front -->
                            <template  v-if="selected_user?.insured == 'UnInsured'">
                              <li>
                                <img class="byDoc" src="img/icons/file-document-check-outline.svg" alt="">
                                <span class="color-green">By Documents</span>
                              </li>
                            </template>

                            <template v-else>
                              <li>
                                <img src="img/icons/file-document-check-outline-gray.svg" alt="">
                                <span class="color-gray">Not Insured</span>
                              </li>
                            </template>
                            
                        </ul>
                        <div class="d-flex w100 gap20">
                            <router-link :to="{name:'ProfileEdit', params:{id: selected_user?.id}}" class="btn btn-secondary w50 h-45px lh-lg btn-lg submit mt-30 gap20 editButton">Edit</router-link>
                            <router-link :to="{name:'Payment', params:{id: selected_user?.id}}" class="btn btn-secondary w50 h-45px lh-lg btn-lg submit mt-30 gap20 confirmButton">Confirm</router-link>
                        </div>
                    </div>
                </div>


              </div>
          </div>

      </div>
  </div>
</template>

<script>

import {defineComponent, ref} from "vue";

export default defineComponent({
  setup() {

        const user_data = ref(JSON.parse(localStorage.getItem('healeze_patient_user_data')));

        const modal_confirm = ref(false)
        const profile_id = ref()

        let selected_user = ref()


    const station_data = ref({
      code: function () {
        let station_code = localStorage.getItem('my_station_code')
        if(station_code)
          return station_code
      }(),
      name: function () {
        let station_name = localStorage.getItem('my_station_name')
        if(station_name)
          return station_name
      }(),
    })

    function setSelectedUser (id) {
      selected_user.value = user_data.value?.profiles.find(profile => profile.id === id);
    }

    return {
      station_data,
      user_data,
      modal_confirm,
      profile_id,
      selected_user,
      setSelectedUser
    }
  },
})

</script>


<style scoped>

  .hoverOn {
    display: none;
  }

  .deleteButton {
    background-color: #f52323;
    border: 1px solid #f52323;
    color: #FFFFFF;
    font-size: 14px;
  }

  .confirmButton {
     background-color: #00A5E9;
     border: 1px solid #00A5E9;
     color: #FFFFFF;
     font-size: 14px;
  }

  .editButton {
    font-size: 14px;
  }

  .newBut a {
    margin-left: auto;
    margin-right: auto;
    height: 45px;
    color: #00A5E9;
  }

   .gap20 {
    gap: 20px;
  }

  .profilePatient {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

    .profilePatient:hover {
    color: #FFFFFF;
    background: #00A5E9;
  }

  .profilePatient:hover .hoverOff {
    display: none;
  }

  .profilePatient:hover .hoverOn {
    display: unset;
  }

.title {
  font-weight: 500;
  font-size: 16px;
}

.a-edit {
  display: grid;
  justify-content: center;
}

.stationInfo {
  margin: 15px;
  background: #555555;
  color: #fff;
  border-radius: 16px;
  padding: 15px;
  margin-bottom: 20px;
}

.edit {
  background: #fff;
  border: 1px solid #00A5E9;
  color: #000000;
  max-width: 100px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  margin: 5px;
}

.edit:hover {
  cursor: pointer;
}

.card {
  display: grid;
  grid-template-columns: auto;
}

.card-text {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 0 0 10px;
}

.labworq {
  display: grid;
  justify-items: center;
  margin-bottom: 20px;
}

.createText {
  font-size: 24px;
  font-weight: 500;
}

.page-padd {
  min-height: 100%;
  background: linear-gradient(191.57deg, rgba(255, 249, 239, 0.5) 0.51%, #87C8EE 99.77%);
}

.form input {
    background: #fff;
    border: none;
    padding: 0 10px;
    font-size: 13px;
}

.form input:focus, .form select:focus { 
  outline: none!important;
  border-color: #87C8EE;
  box-shadow: 0 0 10px #87C8EE;
}

.form .input-group:not(.checkbox):not(.radio):not(.photo) label {
    position: absolute;
    top: 16px;
    left: 10px;
    font-size: 14px;
    color: var(--color-lightgray);
    font-weight: 400;
    transition: all .3s;
}

@media screen and (min-width: 460px) {

  .card {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px;
    align-items: center;
    justify-content: center;
  }

  .card-text {
    padding: 0;
  }

}


</style>