import {computed} from "vue";
import {helpers, maxLength, minLength, required, sameAs, email} from "@vuelidate/validators";


/**
 * Login form validation rules
 */
export default computed(() => {
    return {
        phone: {
            required: helpers.withMessage(
                "Please enter your phone number",
                required
            ),
            minLength: minLength(10),
        },
        email: { required: helpers.withMessage('Please enter valid email', required), email: helpers.withMessage('Please enter valid email', email) },
        code: {
            required: helpers.withMessage(
                "Enter code from SMS you received",
                required
            ),
            minLength: minLength(4),
            maxLength: maxLength(4),
        },
        agree: {
            required: helpers.withMessage("Agreement is required", sameAs(true)),
        },
        $validationGroups: {
            phone: ["phone", "agree"],
            email: ['email', 'agree'],
            code: ["code"],
        },
    };
});
