<template>
  <div class="bg2">
    <div
      id="results"
      class="page-padd pt-25  content-container"
    >
      <div class="header mb-30 mt-10">
        <div class="go-back">
          <a
            href="#"
            onclick="window.history.back()"
          ></a>
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            />
          </svg>
        </div>
        <div class="title self-center d-flex h-center">
          <h3>Frequently Asked Questions</h3>
        </div>
      </div>
      <div
        id="faq"
        class="accordion"
      >
        <div
          @click="setActive(faq.id)"
          :key="faq.id"
          v-for="faq in faqs"
          class="accordion-item"
          :class="faq.id == status ? 'active-arrow' : ''"
        >
          <div class="accordion-header">
            <svg
              style="width: 18px; height: 18px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
              />
            </svg>
            {{ faq.question }}
          </div>
          <Transition>
            <p
              v-if="faq.id == status"
              class="answer inner"
            >{{ faq.answer }}</p>
          </Transition>
        </div>
      </div>
      <p></p>
      <Transition>
        <base-loader
          v-if="loader_active"
          class="loader-on-block bg-white"
        ></base-loader>
      </Transition>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from "vue";
import { API_get_faq } from "@/API/API-faq";

export default defineComponent({
  setup() {
    const loader_active = ref(true);
    const status = ref(null);
    const faqs = ref([]);

    function setActive(id) {
      if (id == status.value) {
        status.value = null;
      } else {
        status.value = id;
      }
    }


    /**
     * Get FAQ data before view mounted
     */
    onBeforeMount(() => {
      API_get_faq()
        .then((res) => {
          if (res.data.status === "success") {
            faqs.value = res.data.faqs;
          }
        });
      loader_active.value = false;
    });

    return { loader_active, setActive, status, faqs };
  },
});
</script>
  

<style>
.page-padd {
  padding-left: 15px;
  padding-right: 15px;
}

.page-padd {
  padding-bottom: 80px;
}

.active-arrow .accordion-header::before {
  transform: rotate(-90deg);
}

.active-arrow .accordion-header {
  background-color: var(--color-primary);
}

.header .title {
  padding-left: 16px;
  font-size: 12px;
}

.header {
  margin-top: 0;
  padding-top: 20px;
}

.bg2 {
  min-height: 100vh;
}
</style>