<template>
  <div class="bg2">
    <div class="page-padd pt-25 content-container">
      <div class="go-back">
        <router-link to="/dashboard"></router-link>
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
        </svg>
      </div>
      <p class="title self-center d-flex h-center">
        Your Insurance
      </p>
      <div class="insurance-data-container">
        <div class="insurance-data-title">
          <img :src="patientProfileStore.getHasCurrentPatientInsured ? insuredTitleSvg : uninsuredTitleSvg" alt="icon">
          <p>{{ patientProfileStore.getCurrentPatientProfileFullName }}</p>
        </div>
        <p class="insurance-data-text">Insurance Company: <span class="text-primary"> {{
          insuranceData.insurance_company_id == "" ? insuranceData.insurance_company_manually :  insuranceData.insurance_company_name}}</span></p>
        <p class="insurance-data-text">Member Number: <span class="text-red"> {{ insuranceData.insurance_member_number ?
          insuranceData.insurance_member_number : 'not found' }}</span></p>
        <!--      <p class="insurance-data-text">Expire date: <span class="text-thin"> {{ insuranceData.expire_date ? insuranceData.expire_date : 'not found' }}</span></p>-->
        <router-link
          :to="{ name: 'EditInsurance', params: { profile_id: patientProfileStore.getCurrentPatientProfileId } }"
          class="update-insurance-btn"> Update Insurance </router-link>
        <div v-if="insuranceData.insurance_front !== 0" class="insurance-data-photo">
          <div class="insurance-data-photo-item">
            <img :src="insuranceData.insurance_front" alt="">
          </div>
          <div class="insurance-data-photo-item">
            <img :src="insuranceData.insurance_back" alt="">
          </div>
        </div>
      </div>
      <Transition>
        <base-loader v-if="loader_active" class="loader-on-block bg-white" />
      </Transition>
    </div>
  </div>

  <InsuranceConsultation />
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import insuredTitleSvg from '@/assets/img/icons/insured-title.svg'
import uninsuredTitleSvg from '@/assets/img/icons/uninsured-title.svg'
import { API_get_patient_insurance } from '@/API/API-profiles'
import InsuranceConsultation from '@/modules/insurance/components/InsuranceConsultation'
import { API_get_insurance_company_info } from "@/API/API-insurance-company";


export default defineComponent({
  components: { InsuranceConsultation },
  setup() {
    const patientProfileStore = usePatientProfilesStore()
    //TODO
    const insuranceData = ref({
      company_name: 'Loading...',
      member_number: 'Loading...',
      expire_date: 'Loading...'
    })

    const companyName = ref('not found');
    const loader_active = ref(true);

    onMounted(() => {
      API_get_patient_insurance(patientProfileStore.getCurrentPatientProfileId)
        .then((res) => {
          console.log('company', res.data.response);
          insuranceData.value = res.data.response;
          loader_active.value = false;
          getCompanyInfo();
        })
    })

    function getCompanyInfo() {
      API_get_insurance_company_info()
        .then((res) => {
          console.log();
          companyName.value = res.data.find(company => company.id === insuranceData.value.insurance_company_id).name;
          // companiesData.value = res.data.map((item) => {

          //   return {
          //     id: item.id,
          //     text: item.name,
          //   };
          // });
        })
        .catch((err) => {
          console.log("err", err);
        });
    }




    return { insuredTitleSvg, uninsuredTitleSvg, patientProfileStore, insuranceData, loader_active, companyName }
  }
})

</script>
<style scoped>
.insurance-data-text {
  /* display: inline-block; */
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

.text-primary {
  color: var(--color-primary);
}

.text-red {
  color: var(--color-red);
}

.text-thin {
  font-size: 14px;
}

.update-insurance-btn {
  padding: 10px 25px;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  display: block;
  margin: 25px auto 0;
  font-weight: 500;
  width: fit-content;
}


.insurance-data-container {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.insurance-data-title {
  display: flex;
  align-items: center;
}

.insurance-data-title p {
  margin-left: 10px;
  font-size: 22px;
  font-weight: 600;
}

.title {
  display: inline-block;
  margin-bottom: 30px;
  line-height: 22px;
  font-weight: 500;
  font-size: 1.4em;
}

.content-container {
  padding-top: 20px;
}

.bg2 {
  min-height: 100vh;
}

.insurance-data-photo {
  margin-top: 40px;
}

.insurance-data-photo-item {
  margin-top: 20px;
  background: #F1F1F1;
  border-radius: 8px;
  overflow: hidden;
}
.insurance-data-photo-item img {
    width: 100%;
    max-height: 218px;
    object-fit: cover;
}
</style>