import axios from "axios";
import qs from "qs";
import {API_user_logout} from "./API-user";
import {useUserStore} from "@/modules/user/store/user";

const url = process.env.VUE_APP_API_URL

/**
 *  set headers to axios interceptor
 */
axios.interceptors.request.use(config => {
    const userStore = useUserStore()
    config.headers['Authorization'] = "Bearer " + userStore.token
    config.headers["content-type"] = "application/x-www-form-urlencoded;charset=utf-8"
    return config
})


export function API_insurance_consultation_request(patient_id, application_id = 0) {
    return axios
        .post(url + "insurance/consult", qs.stringify({
            patient_id,
            application_id
        }))
        .then((r) => r)
        .catch((err) => {
            if (err.response.status === 401) API_user_logout();
        });
}

