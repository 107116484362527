export default {
  APP_NAME: "Healeze",
  HIDE_INVITE_MORE_HEADER: true,
  DEFAULT_LOGO_URL: "",
  SHOW_WATERMARK_FOR_GUESTS: false,
  SHOW_BRAND_WATERMARK: false,
  JITSI_WATERMARK_LINK: "",
  SHOW_JITSI_WATERMARK: true,
  DEFAULT_BACKGROUND: "#1d1e22",
  TOOLBAR_BUTTONS: ["microphone", "camera", "fullscreen"],
  SHOW_CHROME_EXTENSION_BANNER: false,
  LANG_DETECTION: true,
  DEFAULT_REMOTE_DISPLAY_NAME: "Patient",
  DEFAULT_LOCAL_DISPLAY_NAME: "You",
  AUDIO_LEVEL_PRIMARY_COLOR: 'rgba(255,255,255,0.4)',
  AUDIO_LEVEL_SECONDARY_COLOR: 'rgba(255,255,255,0.2)',

  CLOSE_PAGE_GUEST_HINT: false, // A html text to be shown to guests on the close page, false disables it

  DISABLE_DOMINANT_SPEAKER_INDICATOR: false,

  /**
   * If true, notifications regarding joining/leaving are no longer displayed.
   */
  DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,

  /**
   * If true, presence status: busy, calling, connected etc. is not displayed.
   */
  DISABLE_PRESENCE_STATUS: true,

  /**
   * Whether the speech to text transcription subtitles panel is disabled.
   * If {@code undefined}, defaults to {@code false}.
   *
   * @type {boolean}
   */
  DISABLE_TRANSCRIPTION_SUBTITLES: true,

  /**
   * Whether or not the blurred video background for large video should be
   * displayed on browsers that can support it.
   */
  DISABLE_VIDEO_BACKGROUND: true,

  DISPLAY_WELCOME_FOOTER: true,
  DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: true,
  DISPLAY_WELCOME_PAGE_CONTENT: true,
  DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: true,

  ENABLE_DIAL_OUT: false,

  ENABLE_FEEDBACK_ANIMATION: false, // Enables feedback star animation.

  FILM_STRIP_MAX_HEIGHT: 120,

  GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,

  /**
   * Hide the logo on the deep linking pages.
   */
  HIDE_DEEP_LINKING_LOGO: true,

  LOCAL_THUMBNAIL_RATIO: 16 / 9, // 16:9

  MOBILE_APP_PROMO: false,

  NATIVE_APP_NAME: 'Healeze',

  POLICY_LOGO: null,
  PROVIDER_NAME: 'Healeze',

  /**
   * If true, will display recent list
   *
   * @type {boolean}
   */
  RECENT_LIST_ENABLED: false,
  REMOTE_THUMBNAIL_RATIO: 1, // 1:1

  DISABLE_FOCUS_INDICATOR: false,

  SHOW_DEEP_LINKING_IMAGE: false,
  SHOW_POWERED_BY: false,
  SHOW_PROMOTIONAL_CLOSE_PAGE: false,

  /**
   * Whether to show thumbnails in filmstrip as a column instead of as a row.
   */
  VERTICAL_FILMSTRIP: true,

  // Determines how the video would fit the screen. 'both' would fit the whole
  // screen, 'height' would fit the original video height to the height of the
  // screen, 'width' would fit the original video width to the width of the
  // screen respecting ratio, 'nocrop' would make the video as large as
  // possible and preserve aspect ratio without cropping.
  VIDEO_LAYOUT_FIT: 'both',

  VIDEO_QUALITY_LABEL_DISABLED: true,
};
