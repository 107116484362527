<template>
  
  <div v-if="patientProfileStore.getIsInshured" class="insurance-modal-warapper" :class="isInsuranceModalHidden ? 'hidden' : ''">
    <div class="insurance-modal-overlay" @click="isInsuranceModalHidden = true">

    </div>
    <div class="insurance-modal content-container">
      <div @click="isInsuranceModalHidden = !isInsuranceModalHidden" class="hide-control"></div>
      <p class="insurance-modal-title">Free Insurance Consultation</p>
      <p class="insurance-modal-text">It looks like you didn’t give us any insurance information!</p>
      <p class="insurance-modal-text">Our experts can show you the best plans on the market and help you pick the right
        one for you.</p>
      <div class="checkbox-container">
        <input required v-model="isInterested" type="checkbox" id="accept-insurance" name="accept-insurance" checked>
        <label for="accept-insurance" class="accept-insurance-label">
          <img :src="isInterested ? checkboxCheckedSvg : checkboxUncheckedSvg" alt="" class="checkbox-img">
          <span class="insurance-modal-checkbox-text">I’m interested in being contacted by an insurance
            expert.</span></label>
      </div>
      <p class="insurance-modal-disclaimer">*Your name and contact information will be shared with a broker.</p>
      <button @click="requestConsultation" class="contact-broker-btn">Contact Broker</button>
    </div>
  </div>
  <Transition>
    <base-loader v-if="loader_active" class="loader-on-block bg-dark"></base-loader>
  </Transition>
</template>

<script>
import { defineComponent, inject, ref, onBeforeMount, onMounted } from "vue";
import { API_insurance_consultation_request } from "@/API/API-insurance";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import checkboxCheckedSvg from '@/assets/img/icons/checkbox-checked.svg'
import checkboxUncheckedSvg from '@/assets/img/icons/checkbox-unchecked.svg'
import closeModalSvg from '@/assets/img/icons/close-modal.svg'

export default defineComponent({
  setup() {
    let loader_active = ref(false)
    const alertMessage = inject("alertMessage");
    const patientProfileStore = usePatientProfilesStore()
    const isInsuranceModalOpen = ref(true)
    const isInsuranceModalHidden = ref(false)
    const isInterested = ref(false)
    const modalWindowStatus = ref(false)


    onBeforeMount(() => {
      patientProfileStore.updatePatientProfilesData();
    })

    onMounted(() => {
      modalWindowStatus.value = patientProfileStore.getIsInshured;
    })

    function requestConsultation() {
      patientProfileStore.setIsInshured();
      loader_active.value = true;
      isInsuranceModalOpen.value = false;

      API_insurance_consultation_request(patientProfileStore.getCurrentPatientProfileId).then((res) => {
        // TODO: some bug on prod
        if (res == undefined) {
          alertMessage.value.show("Oops try it later", "danger");
          loader_active.value = false;

          return;
        }

        if (res.data.status === 'success') {
          alertMessage.value.show(res.data.text, "success");
          modalWindowStatus.value = false;
        } else {
          alertMessage.value.show(res.data.text, "danger");
        }
        loader_active.value = false
      }).catch((error) => {
        console.log(error);
      })
    }

    return {
      patientProfileStore,
      alertMessage,
      loader_active,
      requestConsultation,
      isInsuranceModalHidden,
      isInsuranceModalOpen,
      isInterested,
      checkboxUncheckedSvg,
      checkboxCheckedSvg,
      closeModalSvg,
      modalWindowStatus
    };
  },
});
</script>


<style scoped>
.accept-insurance-label {
  position: relative;
}

.checkbox-img {
  position: absolute;
  top: 0;
  left: -7%;
}

#accept-insurance {
  display: none;
}

.contact-broker-btn {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: var(--color-primary);
  color: #fff;
}

.insurance-modal-checkbox-text {
  margin-left: 10px;
}

.checkbox-container {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #999;
}

.insurance-modal-title {
  display: inline-block;
  margin-bottom: 11px;
  font-weight: 600;
  font-size: 20px;
}

.insurance-modal-text {
  font-weight: 400;
  font-size: 14px;
}

.insurance-modal-disclaimer {
  color: #999;
  font-weight: 400;
  font-size: 14px;
}

.insurance-modal-overlay {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  padding: 150px 15px;
  height: 100vh;
  width: 100%;
}

.insurance-modal-warapper.hidden .insurance-modal-overlay {
  display: none;
}

.insurance-modal {

  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 0);
  width: calc(100% - 15px);
  background: #fff;
  padding: 20px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 120px;
  border-radius: 15px 15px 0 0;
  -webkit-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.37);
  transition: all .3s ease-in;
}

.insurance-modal-warapper.hidden .insurance-modal {
  transform: translate(50%, calc(100% - 130px));
  color: #fff;
}

.insurance-modal .hide-control {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  bottom: 0;
}

.insurance-modal .hide-control:before {
  content: '';
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 20px;
  transform: translate(-50%, -50%);
  background: var(--color-primary);
  height: 3px;
  width: 120px;
  border-radius: 5px;
}
</style>