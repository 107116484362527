<template>
  <div class="bg2">
    <div
      id="results"
      class="my-application-container page-padd pt-25 content-container"
    >
      <div class="header mb-30">
        <div class="go-back">
          <router-link to="/dashboard"></router-link>
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            />
          </svg>
        </div>
        <div class="title self-center d-flex h-center">
          <h3>My Applications</h3>
        </div>
      </div>
      <account-preview />
      <div class="results-container">
        <div
          v-if="!applications"
          class="d-flex column h-center m-h100"
        >
          <h4 class="text-center color-main">Patient {{ patientProfilesStore.getCurrentPatientProfileFullName }} haven't
            tested yet</h4>
          <a
            @click="checkProfiles()"
            class="btn color3 btn-primary mt-30"
          >Let's Start</a>
        </div>
        <template v-if="applications">
          <div class="results-item results-title">
            <div class="row w100">
              <div
                class="col-8"
                style="opacity: 0.6"
              >
                <div>ID, Date & Time</div>
                <div style="color: #22B573">Testing name</div>
              </div>
              <div class="col-4 text-right">
                <div style="color: var(--color-primary)">Status</div>
              </div>
            </div>
          </div>
          <div id="user_results">
            <div
              class="application-list-item"
              :key="application.id"
              v-for="application in applications"
            >
              <router-link
                :to="{ name: 'ApplicationDetails', params: { pay_url: pay_url, patient_id: patientProfilesStore.getCurrentPatientProfileId, application_id: application.id, } }"
                class="results-item"
                :class="'color' + application.status"
              >
                <div class="row">
                  <div class="col-8">
                    <div class="row">
                      <div class="d-flex">
                        <div style="font-size: 12px; font-weight: 500; margin-right: 5px;">{{
                          application.laboratory_code
                        }}-{{ application.id }}
                        </div>
                        <div style="font-size: 12px;">{{ application.created_at }}</div>
                      </div>
                    </div>
                    <div
                      v-if="application.panel"
                      class="row"
                      style="margin-top: 5px;"
                    >
                      <div style="color: #22B573">{{ application.panel }}</div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="application-status-text">{{
                      application.status_name
                    }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <!--<a data-href="my-results1.html" class="results-item "><div>112345</div><div>18/9/2021 10:00 am</div><div>New</div></a>-->
          </div>
        </template>
      </div>
    </div>
    <Transition>
      <base-loader
        v-if="loader_active"
        class="loader-on-block bg-white"
      />
    </Transition>
  </div>
</template>

<script>
import { defineComponent, ref, inject, watchEffect } from "vue";
import AccountPreview from "@/modules/patient-profiles/components/AccountPreview";
import { API_get_applications_list } from "@/API/API-applications";
import { usePatientProfilesStore } from "@/modules/patient-profiles/store/patient-profiles";
import { useRouter } from "vue-router";
import { useStationsStore } from "@/store/stations";


export default defineComponent({
  components: {
    AccountPreview,
  },
  setup() {
    const router = useRouter();
    const stationsStore = useStationsStore();
    let applications = ref([]);
    let loader_active = ref(false);
    const pay_url = ref('');
    const alertMessage = inject("alertMessage");
    const patientProfilesStore = usePatientProfilesStore();


    /**
     * Watch if current patient id is changed get new applications list according to new patient
     */
    watchEffect(() => {
      loader_active.value = true;
      API_get_applications_list(patientProfilesStore.getCurrentPatientProfileId)
        .then((res) => {
          if (res.data.status === "success") {
            applications.value = res.data.applications;
            loader_active.value = false;
          } else {
            alertMessage.value.show(res.data.message, "danger", "small");
          }
        })
        .catch((err) => {
          alertMessage.value.show(err.message, "danger", "small");
        });
      loader_active.value = true;
    }
    );

    /**
     * Check if patient already has station ID push him to payment view or to scanner if it hasn't
     */
    function checkProfiles() {
      if (stationsStore.getStationId) {
        router.push({ name: "Payment" });
      } else {
        router.push({ name: "Scanner" });
      }
    }

    return { checkProfiles, applications, loader_active, patientProfilesStore, pay_url };
  },
});
</script>

<style>
.application-status-text {}

.results-title {
  background-color: #FFFFFF !important;
}

.application-list-item {
  position: relative;
}

.results-item .application-status-text {
  text-align: right;
  color: var(--color-orange);
}

.results-item .d-flex {
  text-decoration: underline;
}

.results-item.color3 .application-status-text {
  color: var(--color-green);
}

.results-item.color2 .application-status-text {
  color: var(--color-primary);
}

.results-item.color3 {
  background: #fff9ef;
}

.title h3 {
  font-weight: 500;
}

.title h4 {
  max-width: 80%;
}

.results-container>div {
  align-items: center;
}

.pay-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: 500;
  cursor: pointer;
  padding: 30px;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
}

.my-application-container {
  height: 100vh;
}

.header {
  padding-top: 16px;
}
</style>