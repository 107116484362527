import {useUserStore} from "@/modules/user/store/user";
import {usePatientProfilesStore} from "@/modules/patient-profiles/store/patient-profiles";

const routerGuard = (router) => {
    router.beforeEach((to) => {
        const userStore = useUserStore();
        const patientProfilesStore = usePatientProfilesStore();

        if (to.meta.authorizedOnly && !userStore.isLoggedIn) {
            if (to.name !== "Login")
                return { name: "Login" };
        }
        if(to.meta.authorizedOnly && !patientProfilesStore.getCurrentPatientIsValid) {
            if (to.name !== "Signup")
            return { name: "Signup", params: { id: patientProfilesStore.getCurrentPatientProfileId, edit: ' ' } };
        }
    });
};

export default routerGuard;
