<template>
    <div class="modal-confirm">
        <div class="overlay">
            <div class="wrapper">
                <div
                    v-if="!isCodeSend"
                    class="content-wrapper"
                >

                    <p>do you really want to <span class="bold">DELETE</span> account? </p>
                    <div class="btns-wrapper">
                        <base-button
                            @click="onConfirmDeleteAccountBtnClick"
                            class="btn danger mr-10"
                        >Delete</base-button>
                        <base-button
                            @click="$emit('close')"
                            class="btn primary"
                        >Cancel</base-button>
                    </div>

                </div>
                <div
                    v-if="isCodeSend"
                    class="content-wrapper"
                >

                    <div v-if="isDoctor">
                        <p class="mb-20">{{ doctorText }}</p>
                        <base-button
                            @click="$emit('close')"
                            class="btn primary"
                        >Okay</base-button>
                    </div>

                    <div
                        v-if="!isDoctor"
                        :class="{ visible: isCodeSend }"
                        class="block"
                    >
                        <p class="delete-patient-text">{{ deleteText }}</p>
                        <input
                            v-model="code"
                            placeholder="Code"
                            class="input required profile_dl_number mt-10"
                            type="text"
                        />
                        <div class="btns-wrapper">
                            <base-button
                                :disabled="delBtnStatus"
                                @click="[onDeleteAccountBtnClick()]"
                                class="btn danger mr-10"
                                :class="{ disabled: delBtnStatus }"
                            >
                                Delete
                            </base-button>
                            <base-button
                                @click="$emit('close')"
                                class="btn primary"
                            >Cancel</base-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { defineComponent, inject, ref, watchEffect } from "vue";
import { API_user_delete } from "@/API/API-user";
import { useUserStore } from "@/modules/user/store/user";
import router from "@/router";

export default defineComponent({
    emits: ['close'],
    setup() {
        const loader_active = ref(false)
        const userStore = useUserStore()
        const alertMessage = inject("alertMessage");
        const isCodeSend = ref(false)
        const isDoctor = ref(false)
        const doctorText = ref('')
        const code = ref('')
        const delBtnStatus = ref(true);
        const deleteText = ref('');
        console.log('userStore.getUserId')
        console.log(userStore.getUserId)

        /**
         * Confirm account deletion
         */
        watchEffect(() => {
            if (code.value.length === 4) {
                delBtnStatus.value = false;
            } else {
                delBtnStatus.value = true;
            }
        })
        const onConfirmDeleteAccountBtnClick = () => {
            loader_active.value = true
            API_user_delete(userStore.getUserId).then(res => {
                
                console.log('-----res', res);
                if (res.response && res.response.status === 403) {
                    doctorText.value = res.response.data.title;
                    isDoctor.value = true
                } else {
                    deleteText.value = res.data.title;
                }
            })
            isCodeSend.value = true
            loader_active.value = false
        }

        /**
         * Delete user account
         */
        const onDeleteAccountBtnClick = () => {
            loader_active.value = true

            if (code.value.length === 4) {
                API_user_delete(userStore.getUserId, code.value)
                    .then(res => {
                        if (res.data.status === 'success') {
                            alertMessage.value.show(res.data.title)
                            localStorage.clear()
                            sessionStorage.clear()

                            router.push({ name: "Login" })
                        } else {
                            alertMessage.value.show('Incorect code', 'danger', 'small')

                        }
                    })

                loader_active.value = false
            } else {
                alertMessage.value.show('Incorect code', 'danger', 'small')
            }

        }


        return { loader_active, delBtnStatus, onConfirmDeleteAccountBtnClick, onDeleteAccountBtnClick, code, isCodeSend, isDoctor, doctorText, deleteText }
    }
})
</script>
<style scoped>
.wrapper {
    padding: 50px;
}

.btns-wrapper {
    margin-top: 20px;
    display: flex;
}

.mr-10 {
    margin-right: 10px;
}

.content-wrapper {
    text-align: center;
    padding: 50px;
    margin-top: 10rem;
    background-color: #FFFFFF;
    border-radius: 12px;
    max-width: 450px;
    margin: 0 auto;
}

.block {
    display: none;
}

.block.visible {
    display: block;
    animation: fadeIn 4s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal-confirm {
    padding: 50px;
}
.btn.danger.disabled {
    user-select: none;
    cursor: no-drop;
    background-color: #daaeae;
}</style>