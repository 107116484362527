<template>
  <CitiesCentersList></CitiesCentersList>
</template>

<script>
import CitiesCentersList from '@/modules/test-centers/CitiesCentersList.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CitiesCentersList
  },

  setup() {

  }
})

</script>