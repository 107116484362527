import BaseButton from "@/components/base/BaseButton";
import BaseLoader from "@/components/base/BaseLoader";
import BaseNavbar from "@/components/base/BaseNavbar";
import BaseAlert from "@/components/base/BaseAlert";
export default [
    {
        name: "BaseButton",
        component: BaseButton
    },
    {
        name: "BaseLoader",
        component: BaseLoader
    },
    {
        name: "BaseNavbar",
        component: BaseNavbar
    },
    {
        name: "BaseAlert",
        component: BaseAlert
    },
]