<template>
  <div class="center-list">
    <div class="station page-padd pt-25 content-container">
      <div class="go-back">
        <router-link
          to="/dashboard"
          class=""
        ></router-link>
        <svg viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
          ></path>
        </svg>
      </div>
      <div class="list-title self-center d-flex h-center">
        <h3>Choose your test center</h3>
      </div>
      <div id="select_city">
        <div class="d-flex column">
          <ul v-if="clinics">
            <li
              :key="clinic.id"
              v-for="clinic in clinics"
            >
              <router-link
                class="clinics-card"
                :to="{
                  name: 'TestCenterDetail',
                  params: {
                    drchrono_id: clinic.drchrono_id,
                    title: clinic.title,
                    code: clinic.code,
                    address: clinic.address,
                    photo: clinic.photo,
                    lat: clinic.lat,
                    lng: clinic.lng,
                    description: clinic.description,
                  }
                }"
              >
                <div>
                  <span class="clinic_name">{{ clinic.title }}</span>
                  <span class="clinic_address">
                    <img
                      class="location-icon"
                      :src="locationSVG"
                      alt=""
                    >
                    {{ clinic.address }}
                    <button
                      class="show-on-map"
                      @click.prevent="goToMap(clinic.lat, clinic.lng)"
                    >Show on map</button>
                  </span>
                  <span>
                    {{ clinic.description }}
                  </span>
                </div>
                <img
                  :src="clinic.photo"
                  alt=""
                >
              </router-link>
            </li>
          </ul>
          <span
            class="no-activ-clinics"
            v-else
          >
            Sorry no active clinics, please try again later
          </span>
        </div>
        <p class="my-schedule-title">
          {{ mySchedule.length ?
            'Your active reservations at the clinic' :
            'You do not have any appointment, make an appointment'
          }}
        </p>
        <div class="my-schedule">
          <div
            :key="item.id"
            v-for="item in mySchedule"
            class="my-schedule-card"
          >
            <span class="my-schedule-card-name">{{ item.clinic_title }}</span>
            <span class="my-schedule-card-address">{{ changeFOrmatTIme(item.time) }} {{ changeDateFormat(item.date)
            }}</span>
            <span
              v-if="item.reason !== ''"
              class="my-schedule-card-time"
            ><i>{{ item.reason }}</i></span>
          </div>
        </div>
      </div>
      <Transition>
        <base-loader
          v-if="loader_active"
          class="loader-on-block bg-white"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onBeforeMount,
  ref
} from 'vue';
import axios from "axios";
import qs from "qs";
import locationSVG from '@/assets/img/icons/center_location.svg';
// import { API_get_stations_list } from '@/API/API-station';
export default defineComponent({
  setup() {
    const currentPatientProfileId = JSON.parse(localStorage.getItem('patientProfile')).currentPatientProfileId;
    const clinics = ref([]);
    const loader_active = ref(true);

    onBeforeMount(() => {
      getSchedule();
    });

    const mySchedule = ref([]);

    function getSchedule() {
      const url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "schedule/" + currentPatientProfileId, qs.stringify())
        .then((response) => {
          mySchedule.value = response.data.response;
          console.log('muShcedule', response.data.response);
        });

      axios
        .post(url + "clinic", qs.stringify())
        .then((response) => {
          clinics.value = response.data.list;
          console.log(response.data.list);
          loader_active.value = false;
        });
    }

    function changeDateFormat(date) {
      const newDateFormat = date.split('-');
      return `${newDateFormat[1]}-${newDateFormat[2]}-${newDateFormat[0]}`;
    }

    function changeFOrmatTIme(time) {
      let convertedHour = time.split(':')[0] % 24;
      let dayPart = convertedHour < 12 ? ' am' : ' pm';
      let fullTIme = (convertedHour % 12 || 12) + ':' + time.split(':')[1] + dayPart;


      return `(${fullTIme})`;
    }

    function goToMap(lat, lng) {
      window.open(`https://maps.google.com/?q=${lat},${lng}`);
    }

    return {
      mySchedule,
      clinics,
      loader_active,
      locationSVG,
      changeDateFormat,
      changeFOrmatTIme,
      goToMap
    };
  }
});
</script>

<style scoped>
.my-schedule {
  display: grid;
  gap: 6px;
  padding-bottom: 80px;
}

.my-schedule-card {
  display: grid;
  gap: 6px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--color-primary);
  background: #FFFFFF;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, .12);
}

.clinics-card {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.clinics-card>img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
}

img.location-icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.list-title {
  margin-bottom: 20px;
  font-size: 20px;
}

.my-schedule-card-name {
  font-weight: 800;
  font-size: 18px;
}

.my-schedule-card-address {
  color: var(--color-primary);
  font-weight: 700;
  font-size: 16px;
}

.my-schedule-title {
  font-weight: 700;
  padding: 10px 0;
  text-align: center;
}

.stations {
  padding-bottom: 50px;
}

.station {
  padding-top: 50px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.no-activ-clinics {
  text-align: center;
  color: var(--color-primary);
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 24px;
}

.clinic_name {
  font-size: 16px;
  color: #666666;
  font-weight: 700;
}

.clinic_address {
  display: grid;
  grid-template-columns: max-content max-content;
  row-gap: 16px;
  font-size: 12px;
  color: #666666;
  word-break: break-all;
}

@media (max-width: 400px) {

  .clinics-card {
    flex-direction: column;
  }

  .clinics-card>img {
    width: 100%;
  }
}

.item-animation {
  opacity: 0;
  animation: show 0.3s forwards;
}

.clinics-card div {
  display: grid;
  grid-auto-rows: max-content;
  text-align: left;
  gap: 6px;
  flex-grow: 1;
}

.select_city {
  justify-self: center;
}

#select_city li {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  list-style: none;
  border: none;
}

#select_city a {
  padding: 16px 20px;
}

.station a.location_item li {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.station .title {
  max-width: 80%;
}

.my-schedule-card-time {
  padding: 4px 6px;
  border-radius: 2px;
  background-color: #f5f5f5;
}

.my-schedule-card-time i {
  font-style: italic
}

.no-click {
  pointer-events: none;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.center-list {
  background: linear-gradient(191.57deg, rgba(255, 249, 239, .5) .51%, #87c8ee 99.77%);
}

.show-on-map {
  display: block;
  grid-column: 1/3;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 25px;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}
</style>
