<template>
  <router-view></router-view>
  <OfflinePage></OfflinePage>

  <base-navbar
      v-if="$route.matched.some(({ name }) => name !== 'Welcome' && name !== 'Login' && name !== 'Signup' && name !== 'Scanner')"/>

  <base-alert ref="alertMessage"></base-alert>

<!--  offpage-->

</template>

<script>

import {defineComponent, ref, provide, onUpdated} from "vue";
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import OfflinePage from "@/components/NoInternetConnectionModal";
import { API_user_logout } from './API/API-user'

export default defineComponent({
  setup() {
    const alertMessage = ref({})

    provide('alertMessage', alertMessage)

    onUpdated(() => {
      let test = JSON.parse(localStorage.getItem('patientProfile'));
      if (test?.currentPatientProfileId === undefined) {
        API_user_logout();
      }
    })

    return { alertMessage }


  },
  components: {
    OfflinePage
  }
  
})
</script>