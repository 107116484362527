import axios from "axios";
import qs from "qs";
const url = process.env.VUE_APP_API_URL;

const headersAuthorized = {
    Authorization: "Bearer " + localStorage.getItem("healeze_patient_token"),
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
};

/**
 * Get HRA data from current patient
 * @param patient_id
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_hra_init(patient_id) {
    return axios
        .post(url + "hra/init/" + patient_id)
        .then((r) => r);
}

/**
 *  Set HRA data from current patient
 * @param patient_id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export function API_hra_confirm(patient_id,data) {
    return axios
        .post(url + "hra/confirm", qs.stringify({...data, patient_id }), {
            headers: headersAuthorized,
        })
        .then((r) => r);
}

export function API_hra_recommendation(patientId, params) {
    return axios
    .post(url + `hra/get_recommendation/${patientId}`, qs.stringify(
        {
            recommendation: params
        }
    ), {
        headers: headersAuthorized,
    })
    .then((r) => r);
}